import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { StocklistService } from '../stocklist.service';
@Component({
  selector: 'app-doghnutchart',
  templateUrl: './doghnutchart.component.html',
  styleUrls: ['./doghnutchart.component.css']
})
export class DoghnutchartComponent implements OnInit {
  symbols: any
  id: any = 0
  chart: Chart;
  CHART_COLORS = {
    yellow: 'rgb(255, 205, 86)',
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)',
    greenblue: 'rgb(163, 228, 215)',
    darkblue: 'rgb(51, 125, 225)'
  };
  chart_value: any;
  @Input() Symbol: any
  constructor(private stockservice: StocklistService) { }

  ngOnInit() {
    this.getchartData(this.Symbol)
  }


  getchartData(data) {
    this.symbols = data
    var objtwo = {
      symbol: JSON.stringify(this.symbols)
    }
    this.stockservice.doughNutchart(objtwo).subscribe((res: any) => {
      this.chart_value = res.data
      var labels = this.chart_value.map(({ name, percent }) => name + " " + parseInt(percent) + " %")
      var dataset = this.chart_value.map(({ percent }) => percent)
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart("canvas", {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'test',
              data: dataset,
              backgroundColor: Object.values(this.CHART_COLORS),
            }
          ]
        },
        options: {
          responsive: true,
          title: {
            display: false,
            text: 'Color test'
          },
          legend: {
            position: 'left',
            display: true,
            fullWidth: true,
            labels: {
              fontSize: 18
            }
          },

        }
      });

    })
  }

}
