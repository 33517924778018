import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.services';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder,private apiservice:ApiService,private toastr: ToastrService) {
    localStorage.removeItem('searchExc') 
    localStorage.removeItem('searchSym')
    localStorage.removeItem('searchname')
   }

  ngOnInit() {
    this.feedbackForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description:['', [Validators.required]]
    })
  }
  get f() { return this.feedbackForm.controls; }

  submitFeedback(){
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return
    }
    this.apiservice.sendFeedback(this.feedbackForm.value).subscribe(data =>{
      if(data.error = "fasle"){
        this.submitted = false
        this.feedbackForm.reset()
        this.toastr.success('Feedback sent successfully', 'Success');
        }else{
          Swal.fire({
            text: data.msg,
            icon: 'warning',
            showCancelButton: false, 
          })
        }
    })
  }
}
