export const apiUrls = {
  // Login & Signup
  signup: '/users/sign-up',
  otpverify: '/users/otp-verify',
  login: '/users/sign-in',
  resetpassword: '/users/reset-password',
  forgotpassword: '/users/forgot-password',
  resendOTP: '/users/resend-otp',
  getProfile: '/users/profile',

  // Dashboard
  marketOverview: '/users/market-overview/',
  historicalPerformance: '/users/historical/',
  volatility: '/users/volatility/',
  peratio: '/users/pe-ratio/',
  marketSentiment: '/users/market-sentiment/',
  yield: '/users/inverted-yield/',
  gdp: '/users/gdp/',
  usUnemployment: '/users/us-unemployment/',
  initialUnemployment: '/users/initial-unemployment/',
  industrialProduction: '/users/industrial-production/',
  nationalHomeprice: '/users/national-home-price/',
  consumerPrice: '/users/consumer-price/',
  stockMover: '/users/stock-mover',
  news: '/users/news',
  usMarket: '/users/market',
  future: '/users/future',
  currency: '/users/currency',
  crypto: '/users/crypto-currency',
  sector: '/users/sector-performance',
  peers: '/users/peers',
  stockReport: '/users/stock-report',
  events: '/users/events',
  search: '/users/search',
  follow: '/users/follow',

  // Stock Details
  stockOverview: '/users/stock/nadaq/overview/',
  stockEvents: '/users/events/',
  stockQuote: '/users/stock/',
  stockPerformance: '/users/stock/',
  googleTrends: '/users/google-trends/',
  fundamentalMetrics: '/users/fundamental-metrics/',

  balaceSheet: '/users/balance-sheet/',
  incomeSheet: '/users/income-statement/',
  stockfinancial: '/users/stock-financial/',
  cashflow: '/users/cash-flow/',

  // Peers
  peerAnalysis: '/users/peers-analysis/',
  peerList: '/users/peers-list/',

  // profie
changepassword: '/users/update-password',
updateProfile: '/users/update-profile',

// Portfolio
portfolio : '/users/portfolio/questions-list',
createportfolio : '/users/portfolio/create-new ',
checkPortfolio: '/users/portfolio/check-name',

//portfolio analysis
myportfolio: '/users/portfolio/myPortfolio',

}
