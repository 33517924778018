import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { data } from 'jquery';
import { ApiService } from 'src/services/api.services';
import Swal from 'sweetalert2';
import { apiUrls } from '../../environments/apiUrls';
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  step: any = 1;
  stepOne = true;
  show: any = 1
  stepTwo = false;
  stepThree = false;
  portfoliolist: any;
  radioSelected: string;
  steps: any = 0
  radioSelectedString: string;
  radioSel: any;
  selectedanser: any;
  score: number = 0;
  investment: any;
  buttonstatus: boolean;
  portfolioname: any;
  constructor(private apiservice: ApiService, private router: Router) { }

  ngOnInit() {
    this.getPortfolio()
  }
  // next() {
  //   this.stepper.next();
  // }
  getPortfolio() {
    this.apiservice.get(apiUrls.portfolio).subscribe((res: any) => {
      if (res.error == false) {


        this.portfoliolist = res.data

        this.step = this.portfoliolist[0]
        console.log('portfolios', this.portfoliolist);

      }
    })
  }
  onSubmit() {
    return false;
  }

  submit(id) {
    this.portfoliolist.forEach(data => {
      if (data._id == id) {
        let obj = data.answers.find(datas => { return datas.active == true });
        if (obj) {
          console.log("inside button ststus true")
          this.buttonstatus = true
        } else {
          console.log("inside button ststus false", obj)
          this.buttonstatus = false
        }
      }
    })
    this.steps = this.steps + 1
    this.step = this.portfoliolist[this.steps]
    this.getSelecteditem(id)
  }
  next() {
    if (this.show == 2) {
      this.apiservice.post(apiUrls.checkPortfolio, { "name": this.portfolioname }).subscribe((res: any) => {
        if (res.error == false && res.data.isExists == false) {
          this.show++
        } else {
          Swal.fire({
            title: `Portfolio ${this.portfolioname} Already Exist `,
            icon: 'warning',
            showCancelButton: true,
          })
          return
        }
      })
    } else {
      this.show++
    }
  }

  nextInvestment() {
    if (this.show == 3) {
      if (this.investment >= 100) {
        this.show++
      } else {
        Swal.fire({
          title: `Portfolio Investment Amount Is Very Less, it should be at least $100`,
          icon: 'warning',
          showCancelButton: true,
        })
        return
      }
    } else {
      this.show++
    }
    // this.showloader('portfolioloader')
  }

  getSelecteditem(id) {

    this.radioSel = this.portfoliolist.find(Item => Item._id === id);
    this.radioSel = this.radioSel.answers.find(item => item.answer === this.selectedanser)
    this.radioSel.active = true
    // this.radioSelectedString = JSON.stringify(this.portfoliolist);

    console.log(this.portfoliolist);

  }
  storeselected(ans) {
    this.buttonstatus = true
    this.selectedanser = ans
    // this.step.answer.find(data => data.answer === ans.answer)
  }

  back() {
    console.log("answer", this.selectedanser)
    this.buttonstatus = true
    this.steps = this.steps - 1
    this.step = this.portfoliolist[this.steps]
  }

  onSubmitForm() {
    this.next()
    // this.showloader('portfolioloader')
    let questionAnswers = [];
    var obj = this.portfoliolist.forEach(data => {
      let obj = data.answers.find(datas => { return datas.active == true });
      if (obj) {
        let scor = parseInt(obj.score)
        this.score = this.score + scor
        questionAnswers.push({ question: data.question, answer: obj.answer })
      }

    })
    var scoress = {
      score: this.score,
      investment: this.investment,
      portfolioname: this.portfolioname,
      questionAnswers: questionAnswers,
    }
    console.log('submited form', scoress);
    this.apiservice.post(apiUrls.createportfolio, scoress).subscribe((res: any) => {
      if (res.error == false) {
        setTimeout(() => {
          // this.hideloader('portfolioloader')
          this.router.navigate(['/portfolio'])
        }, 3000)
      }
    })

  }




}


