import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.services'

@Injectable({
  providedIn: 'root'
})
export class AppinterceptorService {

  constructor(private apiservices: ApiService) { }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    console.log(httpRequest)
      return next.handle(httpRequest);
    }
}
