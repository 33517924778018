import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadService } from 'src/services/load.service';
import { ApiService } from 'src/services/api.services';
declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-inverted-yield',
  templateUrl: './inverted-yield.component.html',
  styleUrls: ['./inverted-yield.component.css'],
  // encapsulation: ViewEncapsulation.Emulated
})
export class InvertedYieldComponent implements OnInit {
  activeCls: string;
  range: string;
  activeTab: string;
   res:any
  constructor( private apiService: ApiService, private _router: ActivatedRoute, private loadservice:LoadService) { }

  ngOnInit() {
    this.forYearToEnd();
  }

  invertedYieldView(range) {
    this.range = range;
    this.apiService.get(apiUrls.yield + range).subscribe((response: any) => {
      this.res = response
      if (response.error == false) {
        var width = document.getElementById('inverted-yield-chart').clientWidth;
      var data = response.data
        nv.addGraph(function () {
          this.chart = nv.models.linePlusBarChart()
            .margin({ top: 30, right: 60, bottom: 50, left: 70 })
            .color(d3.scale.category10().range())
            .height(500)
            .width(width);
          this.chart.xAxis.tickFormat(function (d) {
            return d3.time.format('%x')(new Date(d))
          });
          this.chart.x2Axis.tickFormat(function (d) {
            return d3.time.format('%b %Y')(new Date(d))
          });
          this.chart.y1Axis
            .tickFormat(d3.format(',f'));

          this.chart.y2Axis
            .tickFormat(function (d) { return d.toFixed(2) });

          this.chart.bars.forceY([0]);
          this.chart.switchYAxisOrder(true)

          d3.select('#inverted-yield-chart svg')
            .datum(data)
            .transition()
            .duration(300)
            .call(this.chart);

          nv.utils.windowResize(this.chart.update);
        
          return this.chart;
        });
      }else{
        return
      }
    })
  }

  businessDayToString(businessDay) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
  }

  forOneDay() {
    this.activeCls = '1d';
    this.invertedYieldView(this.activeCls)
  }
  forFiveDay() {
    this.activeCls = '5d'
    this.invertedYieldView(this.activeCls)
  }
  forOneMonth() {
    this.activeCls = '1m';
    this.invertedYieldView(this.activeCls)
  }
  forSixMonth() {
    this.activeCls = '6m';
    this.invertedYieldView(this.activeCls)
  }
  forYearToEnd() {
    this.activeCls = 'ytd';
    this.invertedYieldView(this.activeCls)
  }
  forOneYear() {
    this.activeCls = '1y';
    this.invertedYieldView(this.activeCls)
  }
  forTwoYear() {
    this.activeCls = '2y';
    this.invertedYieldView(this.activeCls)
  }
  forFiveYear() {
    this.activeCls = '5y';
    this.invertedYieldView(this.activeCls)
  }
  forAllYear() {
    this.activeCls = 'all';
    this.invertedYieldView(this.activeCls)
  }
}
