import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  accessToken: string;
  baseurl: string;

  constructor( private http: HttpClient,) { }

  getProfile(){
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.get(this.baseurl + '/users/profile',{
      headers: httpHeaders,
    })
  }
}
