import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { ToastrService } from 'ngx-toastr';
import { Router, RouterModule, ActivatedRoute, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
 
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  loginData: any;
  userLoggedin: boolean;

  constructor( private formBuilder: FormBuilder, private apiService: ApiService, public router:Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      // acceptTerms: [false, Validators.requiredTrue]
  }, 
  );
  }
  get f() { return this.loginForm.controls; }

  submitLogin(){
    this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.apiService.post(apiUrls.login, this.loginForm.value).subscribe((response: any) => {
          this.loginData = response;
          if (!this.loginData.error) {
            if(this.loginData.data.isEmailVerified == true){
            this.toastr.success(this.loginData.msg, 'Success');
            this.router.navigate(['dashboard']);
            this.userLoggedin = true;
            if(this.userLoggedin){
              localStorage.setItem('accessToken', this.loginData.data.accessToken);
              localStorage.setItem('name', this.loginData.data.firstname);
            }
            } else {
              let navigationExtras: NavigationExtras = {
                queryParams: {
                    "type": "signupOTP",
                    "email": this.loginForm.value.email
                }
              }
              this.router.navigate(['otp-verify'], navigationExtras);
              this.toastr.error('You have to verify the OTP before log in', 'Error');
            }
      } else {
            this.toastr.error(this.loginData.msg, 'Error');
          }
        })
  }


}
