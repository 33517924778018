import { Component, OnInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;
@Component({
  selector: 'app-national-home-price',
  templateUrl: './national-home-price.component.html',
  styleUrls: ['./national-home-price.component.css']
})
export class NationalHomePriceComponent implements OnInit {
  activeCls: string;
  range: string;

  constructor( private apiService: ApiService, private _router: ActivatedRoute, private loadservice:LoadService) { }

  ngOnInit() {
    this.loadservice.economicGraph().subscribe(data =>{
      if(data == 'nhp'){
        this.forOneYear();
      }
    })
  }
  hideloader(val) { 
    document.getElementById(val) 
        .style.display = 'none'; 
  }
  showloader(val) { 
    document.getElementById(val) 
        .style.display = 'block'; 
  }
  loadnationalHomeprice(range) {
    // d3.selectAll("#chart svg > *").remove();
    this.showloader('nhploader'); 
    this.range = range;
    this.apiService.get(apiUrls.nationalHomeprice + range).subscribe((response: any) => {
      this.hideloader('nhploader'); 
    if (response.error == false) {
      var width = document.getElementById('news').clientWidth;
      var data = response.data
      if (response) { 
    }
      nv.addGraph(function() {
        this.chart = nv.models.linePlusBarChart()
              .margin({top: 30, right: 60, bottom: 50, left: 70})
              .color(d3.scale.category10().range())
              .height(500)
              .width(width);
        this.chart.xAxis.tickFormat(function(d) {
          return d3.time.format('%x')(new Date(d))
        });
        this.chart.x2Axis.tickFormat(function (d) {
          return d3.time.format('%b %Y')(new Date(d))
        });
  
        this.chart.y1Axis
            .tickFormat(d3.format(',f'));
  
        this.chart.y2Axis
            .tickFormat(function(d) { return d.toFixed(2) });
  
        this.chart.bars.forceY([0]);
        this.chart.switchYAxisOrder(true)
  
        d3.select('#nationalHomeprice svg')
          .datum(data)
          .transition()
          .duration(0)
          .call(this.chart);
  
        nv.utils.windowResize(this.chart.update);
        
        return this.chart;
      });
    } else {
      return
    }
    })
  }
  businessDayToString(businessDay) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
  }

  forOneDay(){
    this.activeCls = '1d';
    this.loadnationalHomeprice (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.loadnationalHomeprice (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.loadnationalHomeprice (this.activeCls)
  }
  forSixMonth(){
    this.activeCls = '6m';
    this.loadnationalHomeprice (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.loadnationalHomeprice (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.loadnationalHomeprice (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.loadnationalHomeprice (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.loadnationalHomeprice (this.activeCls)
  }
  forAllYear(){
    this.activeCls = 'all';
    this.loadnationalHomeprice (this.activeCls)
  }
}