import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numericalStd'
})
export class NumericalStdPipe implements PipeTransform {

  transform(data): any {
    if (data >= 1e3) {
      var units = ['k', 'M', 'B', 'T']
      var unit = Math.floor(((data).toFixed(0).length - 1) / 3) * 3
      var num = (data / ( (data ? `1e` : data) + unit )).toFixed(2)
      var unitname = units[Math.floor(unit / 3) - 1]
      return num + unitname
    } else if (data < 0) {
      data = Math.abs(data)
      var units = ['k', 'M', 'B', 'T']
      var unit = Math.floor(((data).toFixed(0).length - 1) / 3) * 3
      var num = (data / ( (data ? `1e` : data) + unit )).toFixed(2)
      var unitname = units[Math.floor(unit / 3) - 1]
      if (unitname === undefined) {
        return  '-' + num
      } else {
        return  '-' + num + unitname
      }
    }else if(data == null){
      return 'N/A'
    }
    return data
  }

}
