import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-historical-performance',
  templateUrl: './historical-performance.component.html',
  styleUrls: ['./historical-performance.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class HistoricalPerformanceComponent implements OnInit {
  historical: any;
  chart: any;
  activeCls: any;
  activeStock: string = 'nasdaq';
  value: any = [];
  constructor(private apiService: ApiService, private loadservice: LoadService) { }

  ngOnInit() {
    this.forTwentyYear();
    this.loadservice.dashboardGraph().subscribe(data => {
      if (data == 'historical') {
        this.forTwentyYear();
      }
    })
  }

  loadHistoricalPerformanceChart(range) {
    this.showloader('historicalloader');
    d3.selectAll("#chart2 svg > *").remove();
    d3.selectAll('.nvtooltip').remove();
    this.apiService.get(apiUrls.historicalPerformance + this.activeStock + '/' + range).subscribe((response: any) => {
      this.hideloader('historicalloader');
      if (response.error == false) {
        var width = document.getElementById('chartDetails').clientWidth;
        this.historical = response.data;
        nv.addGraph(function() {
          this.chart = nv.models.discreteBarChart()
            .x(function(d) { return d.label })
            .y(function(d) { return d.value })
            .margin({ right: 50, left: 20 })
            .rightAlignYAxis(true)
            .staggerLabels(true)
            .showValues(true)
            .valueFormat(function(d) {
              return d3.format(',.1f')(d) + '%';
            })
            .height(450)
            .width(width);
          var datum = response.data
          d3.select('#chart2 svg')
            .datum(datum)
            .call(this.chart);
          $(window).on("scroll", function() {
            d3.selectAll('.nvtooltip').style('opacity', '0');
          })
          nv.utils.windowResize(this.chart.update);
          return this.chart;
        });
      } else {
        return
      }
    })
  }

  hideloader(val) {
    document.getElementById(val)
      .style.display = 'none';
  }
  showloader(val) {
    document.getElementById(val)
      .style.display = 'block';
  }

  forTenYear() {
    this.activeCls = '10y';
    this.loadHistoricalPerformanceChart(this.activeCls)
  }
  forTwentyYear() {
    this.activeCls = '20y';
    this.loadHistoricalPerformanceChart(this.activeCls)
  }
  forThirtyYear() {
    this.activeCls = '30y';
    this.loadHistoricalPerformanceChart(this.activeCls)
  }
  forAllYear() {
    this.activeCls = 'all';
    this.loadHistoricalPerformanceChart(this.activeCls)
  }

  changeInStock(stock) {
    this.activeStock = stock
    this.loadHistoricalPerformanceChart(this.activeCls)
  }
}
