import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { ApiService } from './api.services';


@Injectable()
export class DataResoverService implements Resolve<any> {

  constructor(private router: Router, private apiService: ApiService) {

  }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.apiService.stockMovers();
    }
}