import { Component, OnInit, ViewChild, ElementRef,} from '@angular/core';
import { NgbModal, ModalDismissReasons,NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  selector: 'app-newspopup',
  templateUrl: './newspopup.component.html',
  styleUrls: ['./newspopup.component.css']
})
export class NewspopupComponent implements OnInit {

  @ViewChild('content',{static:true}) public modal: ElementRef;
  source: any;
  date: any;
  headline: any;
  summary: any;
  related: any;
  urlSafe: SafeResourceUrl;
  url: any;
  image: any;
  @ViewChild('open',{ static: true }) open: ElementRef;
  @ViewChild('iframe', { static: true }) iframe: ElementRef;
  constructor(
    private modalService: NgbModal,
    private _router: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private location: Location,
    private config: NgbModalConfig

  ) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  
  ngOnInit() {
    this._router.queryParams.subscribe(params => {
      this.source = params.source;
      this.date = params.date;
      this.image = params.image;
      this.headline = params.headline;
      this.summary = params.summary;
      this.related = params.related;
      this.url = params.url;
    })
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    // this.iframe.nativeElement.src = this.url
    this.openPopup();
   
  }

  openPopup(){
    // let inputElement: HTMLElement = this.open.nativeElement as HTMLElement;
    // inputElement.click();
    this.modalService.open(this.modal, { size: 'lg' });
  }
  // ngOnDestroy() {
  //   this.location.back();
  // }
}
