import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
declare let $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  // isCollapsed = false;
  slideConfig = {
    "slidesToShow": 2,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 1500,
    'responsive': [
      {
        'breakpoint': 767,
        'slidesToShow': 1
      }
    ]
  };
  slides = ['assets/images/logo/banner/partner-logo-1.png', 'assets/images/logo/banner/partner-logo-2.png', 'assets/images/logo/banner/partner-logo-3.png', 'assets/images/logo/banner/partner-logo-4.png', 'assets/images/logo/banner/partner-logo-5.png', 'assets/images/logo/banner/partner-logo-1.png', 'assets/images/logo/banner/partner-logo-3.png', 'assets/images/logo/banner/partner-logo-4.png', 'assets/images/logo/banner/partner-logo-2.png'];
  login: boolean = true
  signup: boolean = false
  forgetpassword: boolean = false
  otp:boolean = false

  constructor(private location: Location,private router:Router) {
    const URL = router.url.split('?')
    if(URL[0] === '/'){
      this.login = true
      this.forgetpassword = false
      this.signup = false
      this.otp = false
    }else if(URL[0] == '/signup'){
      this.login = false
      this.forgetpassword = false
      this.signup = true
      this.otp = false
    }else if(URL[0] == '/forgotpassword'){
      this.login = false
      this.forgetpassword = true
      this.signup = false
      this.otp = false
    }else if(URL[0] == '/otp-verify'){
      this.login = false
      this.forgetpassword = false
      this.signup = false
      this.otp = true
    }else{
      this.login = true
      this.forgetpassword = false
      this.signup = false
      this.otp = false
    }
  }

  ngOnInit() {
    // window.location.reload();
    // $('.owl-carousel').owlCarousel({
    //   loop: true,
    //   margin: 10,
    //   nav: true,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     600: {
    //       items: 3
    //     },
    //     1000: {
    //       items: 5
    //     }
    //   }
    // })

    $(document).ready(function () {
  
      const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]') as HTMLInputElement;
      const currentTheme = localStorage.getItem('theme');
      
      if (currentTheme) {
          document.documentElement.setAttribute('data-theme', currentTheme);
        
          if (currentTheme === 'dark') {
              toggleSwitch.checked = true;
          }
      }
      
      function switchTheme(e) {
          if (e.target.checked) {
              document.documentElement.setAttribute('data-theme', 'dark');
              localStorage.setItem('theme', 'dark');
          }
          else {        document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
          }    
      }
      
      toggleSwitch.addEventListener('change', switchTheme, false);

    });
  }

}
