import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  StripeService,
  StripeCardComponent
} from 'ngx-stripe';
// import { ElementOptions } from 'ngx-stripe/lib/interfaces/element';
import { StripeElementsOptions } from '@stripe/stripe-js';

import { ToastrService } from 'ngx-toastr';
import { PaymentService } from './payment.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  paymentForm: FormGroup;
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent, { static: false }) card: StripeCardComponent;
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
    
  };

  // cardOptions: StripeElementsOptions = {
  //   // hidePostalCode: true,
  //   style: {
  //     base: {
  //       iconColor: '#fff',
  //       color: '#fff',
  //       lineHeight: '40px',
  //       fontWeight: '300',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSize: '18px',
  //       '::placeholder': {
  //         color: '#fff'
  //       },
  //     }
  //   }
  // };

  // elementsOptions: ElementsOptions = {

  // };
  cardlist: any;
  plantype: any;

  get validForm() {
    return this.paymentForm.valid && this.stripeCardValid;
  }



  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private router: Router,

  ) {

    this.stripeService.elements(this.elementsOptions)
      .subscribe((elements: any) => {
        // this.elements = elements;

        // Only mount the element the first time
        if (!this.card) {
          this.card = elements.create('card', {
            style: {
              base: {
                iconColor: '#fff',
                color: '#fff !important',
                lineHeight: '40px',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '18px',
                '::placeholder': {
                  color: '#fff'

                }
              }
            }
          });
          // this.card.mount('#card-element');
        }
      })
  }

  ngOnInit() {
    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.listCard()
    this.getPlandetails()
  }

  getPlandetails() {
    this.plantype = JSON.parse(localStorage.getItem('plantype'))

  }

  onChange({ type, event }) {
    if (type === 'change') {
      this.stripeCardValid = event.complete;
    }
  }

  addcard() {
    this.stripeService
      .createToken(this.card.getCard(), { name: this.paymentForm.value.name })
      .subscribe(result => {
        if (result.token) {
          console.log(result.token);
          var obj = {
            source: result.token.id
          }
          this.paymentService.addStripeCard(obj).subscribe((res: any) => {
            console.log(res)
            window.location.reload()
            // this.listCard()
          })
        } else if (result.error) {
          console.log(result.error.message);
        }
      });
  }

  listCard() {
    this.paymentService.listStripeCard().subscribe((res: any) => {
      console.log('rsp', res)
      if (res.error == false) {
        this.cardlist = res.data
      }
    })
  }

  removecard(card) {
    var cardid = { cardId: card.id }
    this.paymentService.removeCard(cardid).subscribe((res: any) => {
      console.log('rsp', res)
      if (res.error == false) {
        // this.cardlist = res.data
        this.listCard()
      }
    })
  }

  makePayment(card) {

    var plantype = JSON.parse(localStorage.getItem('plantype'))

    var details = {
      amount: plantype.amount,
      source: card.id,
      plan: plantype.plan,
      days: plantype.days,
    }
    this.paymentService.makePayment(details).subscribe((res: any) => {
      console.log(res)
      if (res.error == false) {
        this.toastr.success(res.msg, 'Success');
        this.router.navigate(['dashboard'])
      }
    })
  }
}
