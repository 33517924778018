import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoadService } from '../services/load.service';

@Injectable({
  providedIn: 'root'
})
export class UserguardService implements CanActivate {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userservice: LoadService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     const token = this.userservice.isLogin();
   if(!token){
     return true;
   }else{
     this.router.navigate(['dashboard']);
        return false;
   }
  }
}
