import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  accessToken: any;
  baseurl: any
  constructor(private http: HttpClient) { }

  addStripeCard(data) {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    // this.baseurl = 'http://localhost:3000'
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/add-card',data, {
      headers: httpHeaders,
    })

  }

  listStripeCard() {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.get(this.baseurl + '/users/list-card', {
      headers: httpHeaders,
    })

  }

  removeCard(cardId) {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/remove-card',cardId, {
      headers: httpHeaders,
    })

  }

  makePayment(data) {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/make-payment',data, {
      headers: httpHeaders,
    })

  }
}
