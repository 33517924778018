import { Component, OnInit } from '@angular/core';
;
import { apiUrls } from '../../../environments/apiUrls';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-market-sentiment',
  templateUrl: './market-sentiment.component.html',
  styleUrls: ['./market-sentiment.component.css']
})
export class MarketSentimentComponent implements OnInit {
  marketSentiment: any
  chart: any
  bullish: any
  neutral: any
  bearish: any
  activeCls: any
  bullishChecked: boolean;
  neutralChecked: boolean;
  bearishChecked: boolean;
  range: any;
  bullishData: any;
  neutralData: any;
  bearishData: any;

  constructor( private apiService: ApiService,private loadservice:LoadService) { }

  ngOnInit() {
    this.loadservice.dashboardGraph().subscribe(data =>{
      if(data == 'marketsentiment'){
        this.forYearToEnd();
      }
    })
  }

  loadMarketSentimentChart(range) {
    this.showloader('msloader'); 
    d3.selectAll("#chart5 svg > *").remove();
    d3.selectAll('.nvtooltip').remove();
    this.range = range;
    this.apiService.get(apiUrls.marketSentiment + range).subscribe((response: any) => {
    this.hideloader('msloader'); 
    if (response.error == false) {
      var width = document.getElementById('chartDetails').clientWidth;
      if(response.data){
        nv.addGraph(function() {
        this.chart = nv.models.stackedAreaChart()
                      .margin({right: 100})
                      .x(function(d) { return d[0] })   //We can modify the data accessor functions...
                      .y(function(d) { return d[1] })
                      .color(['#0ED22F','#D3D3D3','#EC1818'])
                      .margin({ right: 50, left: 20 })   //...in case your data is formatted differently.
                      .useInteractiveGuideline(true)    //Tooltips which show all data points. Very nice!
                      .rightAlignYAxis(true)      //Let's move the y-axis to the right side.
                      .showControls(true)       //Allow user to choose 'Stacked', 'Stream', 'Expanded' mode.
                      .clipEdge(true)
                      .height(500)
                      .width(width);

      this.chart.xAxis
          .tickFormat(function(d) { 
            return d3.time.format('%x')(new Date(d)) 
      });

      this.chart.yAxis
          .tickFormat(d3.format(',.2f'));
      var datum = response.data
      d3.select('#chart5 svg')
        .datum(datum)
        .call(this.chart);
        $(window).on("scroll", function(){
          d3.selectAll('.nvtooltip').style('opacity', '0');
        })
      nv.utils.windowResize(this.chart.update);

      return this.chart;
        });
      }
    } else {
      return
    }
  })
}
hideloader(val) { 
  document.getElementById(val) 
      .style.display = 'none'; 
}
showloader(val) { 
  document.getElementById(val) 
      .style.display = 'block'; 
}
  forOneDay(){
    this.activeCls = '1d';
    this.loadMarketSentimentChart (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.loadMarketSentimentChart (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.loadMarketSentimentChart (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.loadMarketSentimentChart (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.loadMarketSentimentChart (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.loadMarketSentimentChart (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.loadMarketSentimentChart (this.activeCls)
  }
  forBullish(){
    if(this.bullishChecked){
      this.loadMarketSentimentChart(this.range);
      this.bullishChecked = false;
    } else {
      this.loadMarketSentimentChart(this.range);
      this.bullishChecked = true;
    }
  }
  forNeutral(){
    if(this.neutralChecked){
      this.loadMarketSentimentChart(this.range);
      this.neutralChecked = false;
    } else {
      this.loadMarketSentimentChart(this.range);
      this.neutralChecked = true;
    }
  }
  forBearish(){
    if(this.bearishChecked){
      this.loadMarketSentimentChart(this.range);
      this.bearishChecked = false;
    } else {
      this.loadMarketSentimentChart(this.range);
      this.bearishChecked = true;
    }
  }
  forTotal(){
    
  }
}
