import { Component, OnInit } from '@angular/core';

import { Router, RouterModule, ActivatedRoute,NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  submitted = false;
  signupData: any =[];
  otpverification: any;
  email: any;

  constructor( public router:Router, private formBuilder: FormBuilder, private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit() {
  localStorage.clear();
    this.signupForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      username: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      // acceptTerms: [false, Validators.requiredTrue]
  }, 
  // {
  //     validator: MustMatch('password', 'confirmPassword')
  // }
  );
  }
  get f() { return this.signupForm.controls; }
  submitSignup(){
    this.submitted = true;
    this.email = this.signupForm.value.email;
        if (this.signupForm.invalid) {
            return;
        }
        this.apiService.post(apiUrls.signup, this.signupForm.value).subscribe((response: any) => {
          this.signupData = response;
          if (!this.signupData.error) {
            this.toastr.success(this.signupData.msg, 'Success');
            localStorage.setItem('OTPsignup', this.signupData.msg);
            let navigationExtras: NavigationExtras = {
              queryParams: {
                  "type": "signup",
                  "email": this.email
              }
            }
            this.router.navigate(['otp-verify'], navigationExtras);
      } else {
            this.toastr.error(this.signupData.msg, 'Error');
          }
        })
  }

}
