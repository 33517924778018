import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, TemplateRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours, parseISO, format } from 'date-fns';
import { from, Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarDateFormatter, CalendarView, } from 'angular-calendar';
import { title } from 'process';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { LoadService } from 'src/services/load.service';
// import { StockdetailsModule } from '../stockdetails/stockdetails.module'
import { fadeInAnimation } from '../../../app/fade-in.animation';
import { CustomDateFormatter } from 'src/app/custom-date-formatter.provider';

import { NumericalStdPipe } from 'src/app/numerical-std.pipe';
import { NegativeNumberPipe } from 'src/app/negative-number.pipe';
import de from 'date-fns/esm/locale/de/index.js';
import { StocklistService } from 'src/app/stocklist/stocklist.service';
import { PortfolioanalystComponent } from 'src/app/portfolioanalyst/portfolioanalyst.component';
declare let $: any;
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  }
};

@Component({
  selector: 'app-dashboard-two',
  templateUrl: './dashboard-two.component.html',
  styleUrls: ['./dashboard-two.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },

  ],
  animations: [fadeInAnimation],
  host: { '[@fadeInAnimation]': '' },
  // host: {
  //   "(window:resize)": "onWindowResize($event)"
  // },
  encapsulation: ViewEncapsulation.None
})
export class DashboardTwoComponent implements OnInit {



  @ViewChild('content', { static: true }) content: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  refresh: Subject<any> = new Subject();

  events: any

  activeDayIsOpen: boolean = false;
  items = [];

  stockMover: any;
  activer: any = [];
  gainer: any = [];
  loser: any = [];
  news: any;
  usmarket: any;
  viewFullnews: any;
  bond: any;
  sector: any;
  reports: any;
  options: any;
  toDay: Date;
  month: number;
  year: () => number;
  thisYear: number;
  currentEvent: any;
  dateEvents: any = [];
  stockDetails: any;
  newsTab: any = [];
  overview: boolean;
  history: boolean;
  peratio: boolean;
  volatility: boolean;
  sentiment: boolean;
  show: boolean;
  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 768;
  points: any;
  graphpoint: any;
  urlSafe: SafeResourceUrl;
  tab: any;
  eventsList: any = [];
  filterList: any = [];
  future: any;
  futureArr: any = [];
  currency: any;
  data: any = [];
  crypto: any;
  range: any;
  percetage: any;
  price: any;
  searchResult: any = [];
  public keyword = 'name';
  iy: boolean;
  gdp: boolean;
  usunemp: boolean;
  unp: boolean;
  ip: boolean;
  nhp: boolean;
  cp: boolean;
  screenWidth: number;
  screenHeight: number;
  newsPop: any = {};
  newImage: any;
  sortDir = 1;
  peerlist: any = [];
  pList: boolean;
  stocks: any;
  stockstable: boolean;
  pageOfItems: Array<any>
  stocktype: any;
  myportfoliolist: any;
  portfolio_analysis: any
  stockList: any;
  constructor(private apiService: ApiService,
    private toastr: ToastrService,
    public router: Router,
    private _router: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private loadservice: LoadService,
    private stockservice: StocklistService
  ) {
    this.events = [];
    // localStorage.removeItem('searchExc')
    // localStorage.removeItem('searchSym')
    // localStorage.removeItem('searchname')

  }

  ngOnInit() {
    this.data = [
      { text: "Split", value: 1 },
      { text: "Earning", value: 2 },
      { text: "Divident", value: 3 }
    ]
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.iy = true;
    if (this.screenWidth <= 768) {
      this.isMobile = true
    }

    this.stockNewDash('mystock')
    this.stockMovers();
    this.getnews();
    this.getSectors();
    this.getstockReports();
    this.getEvent();
    this.getusMarket();
    this.getPortfolioAnalysis();
    this.myStockList()
    $(function() {
      $('.stocks_tabs li').on('click', function() {

        $(this).addClass('active').siblings().removeClass('active');
      });
      $('.dropdown > .caption').on('click', function() {
        $(this).parent().toggleClass('open');
      });

      $('.dropdown > .list > .item').on('click', function() {
        $('.dropdown > .list > .item').removeClass('selected');
        $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').text($(this).text());
      });

      $(document).on('keyup', function(evt) {
        if ((evt.keyCode || evt.which) === 27) {
          $('.dropdown').removeClass('open');
        }
      });

      $(document).on('click', function(evt) {
        if ($(evt.target).closest(".dropdown > .caption").length === 0) {
          $('.dropdown').removeClass('open');
        }
      });

    });
  }



  myStockList() {
    this.stockservice.myStockList().subscribe((res: any) => {
      if (res.error == false) {
        this.stockList = res.data
      

      }
    })
  }

  getPortfolioAnalysis() {
    this.apiService.get(apiUrls.myportfolio).subscribe((res: any) => {
      if (res.error == false) {
        this.myportfoliolist = res.data
        // this.myportfoliolist = ''
        // this.indexChanging(index)

      }
    })
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   alert('hostlistener')
  //   this.screenWidth = window.innerWidth;
  //   this.screenHeight = window.innerHeight;
  //   if (this.screenWidth <= 768) {
  //     this.isMobile = true
  //   }
  //   else {
  //     this.isMobile = false
  //   }
  // }

  // @HostListener('window:scroll', ['$event']) 
  // doSomething(event) {
  //   console.debug("Scroll Event", window.pageYOffset );
  // }

  // onWindowResize(event) {
  //   this.width = event.target.innerWidth;
  //   this.height = event.target.innerHeight;
  //   this.isMobile = this.width < this.mobileWidth;
  // }
  onSortClick(event, colName) {

    console.log("event", event, colName)

    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = -1;
    }
    if ((colName === 'symbol') || (colName === 'companyName')) {
      this.stocks.sort((a, b) => {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      });
    } else if (colName === 'nextEarningsDate') {
      if (this.sortDir === -1) {
        this.stocks.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate)) * (-1);
        })
      } else {
        this.stocks.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate));
        })
      }
    } else {
      this.stocks.sort((a, b) => (a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0) * this.sortDir)
    }

  }
  // showloader(val) { 
  //   document.getElementById(val) 
  //       .style.display = 'block'; 
  // }

  ngAfterViewInit() {

  }
  stockNewDash(data) {

    this.stockstable = false
    // this.cd.detectChanges()
    this.stocktype = data
    this.showloader('stocksloader')
    var type = {
      type: data,
      page: 1
    }
    this.apiService.StockNewdashboard(type).subscribe((res: any) => {

      console.log('responcedash', res);
      this.hideloader('stocksloader');
      this.stockstable = true
      // this.cd.detectChanges()
      this.stocks = this.stockCoverter(res.data.result)
      this.items = Array(res.data.totalPages * 10).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}` }));

    })
  }


  stockCoverter(stocks) {
    var stock = stocks.map(res => {
      var day50MovingAvg = ((res.latestPrice / res.day50MovingAvg) - 1) * 100
      var day200MovingAvg = ((res.latestPrice / res.day200MovingAvg) - 1) * 100
      var extendedChange = ((res.extendedChange / (res.close ? res.close : res.previousClose)) * 100)
      var changePercent = (res.changePercent * 100)
      var ytdChange = (res.ytdChange * 100)
      let data = {
        url: res.url ? res.url : "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/" + res.symbol + ".png",
        symbol: res.symbol,
        companyName: res.companyName,
        close: res.close ? res.close : res.previousClose,
        extendedChange: extendedChange,
        changePercent: changePercent,
        ytdChange: ytdChange,
        day50MovingAvg: day50MovingAvg,
        day200MovingAvg: day200MovingAvg,
        peRatio: res.peRatio,
        marketCap: res.marketCap,
        nextEarningsDate: res.nextEarningsDate,
        priceToSales: res.priceToSales
      }
      return data
    })

    return stock
  }
  onChangePage(data) {

    var realDigits
    var num = data[0].id;
    var digits = num.toString().split('');
    if (digits.length > 1) {
      realDigits = parseInt(digits[0]) + parseInt(digits[1])
    } else {
      realDigits = num
    }

    if (realDigits) {
      var type = {
        type: this.stocktype,
        page: realDigits
      }
      this.apiService.StockNewdashboard(type).subscribe((res: any) => {
        console.log('responcedash', res);
        this.hideloader('stocksloader');
        this.stockstable = true
        // this.cd.detectChanges()
        this.stocks = this.stockCoverter(res.data.result)
        // this.items = Array(res.data.totalPages * 10).fill(0).map((x, i) => ({ id: (i + 1), name: `Item ${i + 1}`}));

      })
    }

  }

  // deltacalcu(latestPrice, day50MovingAvg) {
  //   return ((latestPrice / day50MovingAvg) - 1) * 100
  // }

  getStockquote() {

    // this.showloader('peerstockloader'); 
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.peerList + stock).subscribe((response: any) => {
      if (response) {
        // this.hideloader('peerstockloader');

        this.peerlist = response.data;
      }
    })
  }

  showloader(val) {
    document.getElementById(val).style.display = 'block';
  }


  hideloader(val) {
    document.getElementById(val).style.display = 'none';
  }


  stockMovers() {
    this.apiService.get(apiUrls.stockMover).toPromise().then((response: any) => {
      this.hideloader('activersloader');
      if (response.error == false) {
        this.stockMover = response.data;
        this.activer = this.stockMover.activer;
        this.gainer = this.stockMover.gainer;
        this.loser = this.stockMover.loser;
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getnews() {
    this.apiService.get(apiUrls.news).toPromise().then((response: any) => {
      if (response.error == false) {
        this.news = response.data;
        this.news.forEach(x => {
          let a = new Date(x.datetime)
          this.newsTab.push({ headline: x.headline, source: x.source, image: x.image, summary: x.summary, related: x.related, date: a, url: x.url });
        });
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getusMarket() {
    this.apiService.get(apiUrls.usMarket).toPromise().then((response: any) => {
      this.usmarket = response.data.usMarket;
      this.bond = response.data.bond;
      if (response.data.usMarket) {
        this.hideloader('usmloader');
      }
      if (response.data.bond) {
        this.hideloader('bondsloader');
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getFuture() {
    this.future = [];
    this.futureArr = [];
    this.apiService.get(apiUrls.future).toPromise().then((response: any) => {
      this.hideloader('futureloader');
      if (response.error == false) {
        this.future = response.data;
        this.future.forEach(i => {
          if (i.extendedPriceTime) {
            this.price = new Date(i.extendedPriceTime);
          } else {
            this.price = '';
          }
          this.percetage = (i.extendedChangePercent) * 100
          this.futureArr.push({
            symbol: i.label,
            price: i.extendedPrice,
            change: i.extendedChange,
            date: this.price,
            changePercentage: this.percetage
          })
        });
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getcurrency() {
    this.apiService.get(apiUrls.currency).toPromise().then((response: any) => {
      this.hideloader('currencyloader');
      if (response.error == false) {
        this.currency = response.data;
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getcrypto() {
    this.apiService.get(apiUrls.crypto).toPromise().then((response: any) => {
      this.hideloader('cryptoloader');
      if (response.error == false) {
        this.crypto = response.data;
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getSectors() {
    this.apiService.get(apiUrls.sector).toPromise().then((response: any) => {
      if (response.error == false) {
        this.sector = response.data;
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  getstockReports() {
    this.apiService.get(apiUrls.stockReport).toPromise().then((response: any) => {
      if (response.error == false) {
        this.reports = response.data;
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
  open(content, news) {
    this.viewFullnews = news;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.viewFullnews.url);
    let newsDetails = {
      url: news.url,
      title: news.title,
      image: news.image,
      headline: news.headline,
      source: news.source,
      date: news.date,
      summary: news.summary,
      related: news.related
    }
    this.router.navigate(['/dashboard/newspopup'], { queryParams: newsDetails });
  }

  dayClicked({ date }): void {
    let a = new Date(date)
    let day = a.getDate();
    const eventss = []
    this.dateEvents.filter((n) => {
      if (n.start.getDate() === day) {
        eventss.push({
          title: n.title,
          start: (new Date(n.start)),
          color: colors.red,
          edate: n.edate,
          year: n.year,
          type: n.type,
          date: n.date,
          amount: n.amount,
          frequency: n.frequency,
          symbol: n.symbol
        })
      }
    })
    this.eventsList = eventss;
    this.filterList = eventss;
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  getEvent() {
    this.toDay = new Date();
    this.month = this.toDay.getMonth() + 1;
    this.thisYear = this.toDay.getFullYear();
    let p = {
      year: this.thisYear,
      month: this.month
    }
    this.apiService.post(apiUrls.events, p).subscribe((response: any) => {
      this.currentEvent = response;
      const eventss = []
      if (response.error == false) {
        this.currentEvent.data.forEach(i => {
          let a = new Date(i.date);
          let eDate = a.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
          let year = a.getFullYear();
          let date = a.getDate();
          eventss.push({
            title: i.description,
            start: (a),
            color: colors.red,
            edate: eDate,
            year: year,
            type: i.type,
            amount: i.amount,
            frequency: i.frequency,
            date: date,
            symbol: i.symbol
          })
        });
        this.events = eventss
        this.dateEvents = eventss;
        this.eventsList = eventss;
        this.filterList = eventss;
      }
    })
  }

  viewDetails(viewdNews) {
    this.newsPop = viewdNews;
    this.newImage = this.newsPop.image
    console.log(this.newsPop, 'newsPop');
    console.log(this.newImage, 'newImage');
  }
  nextMonth(date: any) {
    this.eventsList = [];
    this.dateEvents = [];
    let a = new Date(date)
    let p = {
      year: a.getFullYear(),
      month: a.getMonth() + 1
    }
    this.apiService.post(apiUrls.events, p).subscribe((response: any) => {
      if (response.error == false) {
        this.currentEvent = response;
        const eventss = []
        this.currentEvent.data.forEach(i => {
          let a = new Date(i.date + ' 00:00:00');
          let eDate = a.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
          let year = a.getFullYear();
          let date = a.getDate();
          eventss.push({
            title: i.description,
            start: (a),
            color: colors.red,
            edate: eDate,
            year: year,
            date: date,
            type: i.type,
            amount: i.amount,
            frequency: i.frequency,
            symbol: i.symbol
          })
        });
        this.events = eventss
        this.eventsList = eventss;
        this.dateEvents = eventss;
        this.filterList = eventss;
      } else {
        return
      }
    })
  }

  preMonth(date: any) {
    this.eventsList = [];
    this.dateEvents = [];
    let a = new Date(date)
    let p = {
      year: a.getFullYear(),
      month: a.getMonth() + 1
    }
    this.apiService.post(apiUrls.events, p).subscribe((response: any) => {
      if (response.error == false) {
        this.currentEvent = response;
        const eventss = []
        this.currentEvent.data.forEach(i => {
          let a = new Date(i.date + ' 00:00:00');
          let eDate = a.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
          let year = a.getFullYear();
          let date = a.getDate();
          eventss.push({
            title: i.description,
            start: (a),
            color: colors.red,
            edate: eDate,
            year: year,
            type: i.type,
            date: date,
            amount: i.amount,
            frequency: i.frequency,
            symbol: i.symbol
          })
        });
        this.events = eventss
        this.eventsList = eventss;
        this.dateEvents = eventss;
        this.filterList = eventss;
      } else {
        return
      }
    })
  }
  eventFilter(events) {
    if (events == 'all') {
      this.eventsList = this.filterList;
    } else {
      let a = events;
      const eventss = []
      this.filterList.filter((n) => {
        if (n.type === a) {
          eventss.push({
            title: n.title,
            start: (new Date(n.start)),
            color: colors.red,
            edate: n.edate,
            year: n.year,
            type: n.type,
            date: n.date,
            amount: n.amount,
            frequency: n.frequency,
            symbol: n.symbol
          })
        }
      })
      this.eventsList = eventss;
    }
  }
  changeWebsite(events) {
    let a = events;
    if (a === 'overview') {
      this.overview = true;
      this.history = false;
      this.peratio = false;
      this.volatility = false;
      this.sentiment = false;
    } else if (a === 'history') {
      this.overview = false;
      this.history = true;
      this.peratio = false;
      this.volatility = false;
      this.sentiment = false;
      this.isMobile = false
      this.graphTab('historical')
    } else if (a === 'volatility') {
      this.overview = false;
      this.history = false;
      this.peratio = false;
      this.volatility = true;
      this.sentiment = false;
      this.isMobile = false;
      this.graphTab('volatility')
    } else if (a === 'peratio') {
      this.overview = false;
      this.history = false;
      this.peratio = true;
      this.volatility = false;
      this.sentiment = false;
      this.isMobile = false;
      this.graphTab('peratio');
    } else {
      this.overview = false;
      this.history = false;
      this.peratio = false;
      this.volatility = false;
      this.sentiment = true;
      this.isMobile = false;
      this.graphTab('marketsentiment')
    }
  }

  stockDetail(stock) {

    this.searchHead(stock);
    const symbol = {
      sym: stock
    }
    let symbol1 = {
      text: stock
    }
    this.apiService.post(apiUrls.search, symbol1).subscribe((response: any) => {
      if (response.error == false) {
        this.searchResult = response.data[0];
        localStorage.setItem('searchSym', this.searchResult.symbol);
        localStorage.setItem('searchname', this.searchResult.name);
        localStorage.setItem('searchExc', this.searchResult.exchange);
        this.router.navigate(['stockdetail/overview'], { queryParams: symbol });
      } else {
        return
      }
    })

  }
  searchHead(stock) {

  }


  graphTab(data) {
    this.loadservice.setTab(data)
  }

  ecoTab(data) {
    this.loadservice.setEcotab(data)
  }

  changeecoTab(tab) {
    let a = tab;
    if (a === 'iy') {
      this.iy = true;
      this.gdp = false;
      this.usunemp = false;
      this.unp = false;
      this.ip = false;
      this.nhp = false;
      this.cp = false;
    } else if (a === 'gdp') {
      this.iy = false;
      this.gdp = true;
      this.usunemp = false;
      this.unp = false;
      this.ip = false;
      this.nhp = false;
      this.cp = false;
      this.ecoTab('gdp');
    } else if (a === 'usunemp') {
      this.iy = false;
      this.gdp = false;
      this.usunemp = true;
      this.unp = false;
      this.ip = false;
      this.nhp = false;
      this.cp = false;
      this.ecoTab('usunemp');
    } else if (a === 'unp') {
      this.iy = false;
      this.gdp = false;
      this.usunemp = false;
      this.unp = true;
      this.ip = false;
      this.nhp = false;
      this.cp = false;
      this.ecoTab('unp');
    } else if (a === 'ip') {
      this.iy = false;
      this.gdp = false;
      this.usunemp = false;
      this.unp = false;
      this.ip = true;
      this.nhp = false;
      this.cp = false;
      this.ecoTab('ip');
    } else if (a === 'nhp') {
      this.iy = false;
      this.gdp = false;
      this.usunemp = false;
      this.unp = false;
      this.ip = false;
      this.nhp = true;
      this.cp = false;
      this.ecoTab('nhp');
    } else if (a === 'cp') {
      this.iy = false;
      this.gdp = false;
      this.usunemp = false;
      this.unp = false;
      this.ip = false;
      this.nhp = false;
      this.cp = true;
      this.ecoTab('cp');
    }
  }



}
