import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/services/api.services';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrls } from 'src/environments/apiUrls';
import { LoadService } from 'src/services/load.service';
import { NumericalStdPipe } from './../../numerical-std.pipe'
import { ChartOptions, ChartType, ChartDataSets, Chart } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { async } from '@angular/core/testing';

declare let nv: any;
declare let d3: any;
declare let anychart: any;


const colorRange = {
  "color": [{
    "minValue": "0",
    "maxValue": "50",
    "code": "#e44a00"
  }, {
    "minValue": "50",
    "maxValue": "75",
    "code": "#f8bd19"
  }, {
    "minValue": "75",
    "maxValue": "100",
    "code": "#6baa01"
  }]
};

const dials = {
  "dial": [{
    "value": "67"
  }]
};

@Component({
  selector: 'app-keymetrics',
  templateUrl: './keymetrics.component.html',
  styleUrls: ['./keymetrics.component.css'],
  providers: [NumericalStdPipe]
})

export class KeymetricsComponent implements OnInit {
  hgt: number;
  hgt2: number;
  hgt3: number;
  performance: any = [];
  growth: any;
  totalRate: any = [];
  emhgt1: number;
  emhgt2: number;
  revenue: any;
  revenueQ: any;
  eps: any = [];
  trtable: boolean;
  oneMonth: any;
  threeMonth: any;
  ytd: any;
  oneYear: any;
  threeYear: any;
  fiveYear: any;
  tenYear: any;
  dSet: number;
  public canvasWidth: number;
  public needleValue: number;
  public centralLabel = ''
  // public name = 'Gauge chart'
  // public bottomLabel = '65'
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
    arcDelimiters: [33.3, 66.6],
    rangeLabel: ['10', '1'],
    needleStartValue: 50,
  }

  debitEquity: any;
  freeCashFlow: any;
  peRatio: any;
  pegRatio: any;
  priceSales: any;
  priceToBook: any;
  quickRatio: any;
  roeReturn: any;
  rem: number;
  rem1: number;
  rem2: number;
  hgt4: number;
  rem3: number;
  rem4: number;
  hgt5: number;
  rem5: number;
  hgt6: number;
  rem6: number;
  hgt7: number;
  rem7: number;
  hgt8: number;
  fundamental: boolean;

  // barChartOptions: ChartOptions;
  // public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  dataSet: any = [];
  // barChartData: ChartDataSets[];
  public barChartColors: Color[] = [
    { backgroundColor: 'rgb(255, 127, 14)' },
    { backgroundColor: 'rgb(31, 119, 180)' },
  ]
  equityopt: any = [];
  ptsopt: any = [];
  pegopt: any = [];
  roeopt: any = [];
  ptbopt: any = [];
  fcfopt: any = [];
  peropt: any = [];
  qropt: any = [];
  needle1: number = 0;
  needle2: number = 0;
  needle3: number = 0;
  needle4: number = 0;
  needle5: number = 0;
  needle7: number = 0;
  needle8: number = 0;
  symbol: any;
  arrayOfData = new Array(
    [[14, 54, 26], '2011'],
    [[8, 48, 38], '2012'],
    [[4, 36, 57], '2013']
  );
  array1: any = [];
  epsQuater: any = [];
  googleTrends: any;
  revenueearningquart: any;
  growthrate: void;

  constructor(private apiService: ApiService,
    public router: Router,
    public _router: ActivatedRoute,
    private load: LoadService,
    private cd: ChangeDetectorRef,
  ) { }

  async ngOnInit() {

    this._router.queryParams.subscribe(params => {
      this.symbol = params.sym
      // this.getStockquote();
    });


    this.revenuefun();
    localStorage.setItem('symbol', this.symbol);


    this.apiService.getKeymetrics().subscribe(async (res: any) => {
      await this.loadKeymetrics(res[0]);
      await this.getgoogleTrends(res[2]);
      await this.getfundamental(res[3]);
      await this.loadEpsQ(res[4]);
      await this.loadRevenueQ(res[1])
    
    })

    this.apiService.getGrowthandTotal().subscribe(async (res: any) => {
      await this.loadLinechart(res[0]);
      await this.getTotalrate(res[1])

    })
 
    await this.getgauge();
    // await this.loadEps();
    // await this.loadEpsQ();
    this.load.watchStorage().subscribe(data => {
      // await this.loadKeymetrics();
      // await this.loadLinechart();
      // await this.loadRevenue();
      // await this.loadEps();
      // await this.loadEpsQ();
      // await this.getTotalrate();
      // await this.loadRevenueQ();
      // await  this.getgoogleTrends();
      // await this.getfundamental();
      // await this.getgauge();
    })


  }
  getgauge() {
    this.equityopt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.ptsopt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.pegopt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.roeopt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.ptbopt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.fcfopt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.peropt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
    this.qropt = {
      hasNeedle: true,
      needleColor: 'gray',
      needleUpdateSpeed: 1000,
      arcColors: ['#e30a0a', '#f0ff07', '#13c208'],
      arcDelimiters: [33.3, 66.6],
      // rangeLabel: ['10','1'],
      needleStartValue: 50,
    }
  }
  loadKeymetrics(response) {

    d3.selectAll("#keyMatrics svg > *").remove();
    this.showloader('performanceloader');
    let stock = localStorage.getItem('symbol');
    // this.apiService.get(apiUrls.stockPerformance + stock + '/historical/10y').subscribe((response: any) => {
    this.hideloader('performanceloader');
    if (response.error == false) {
      this.performance = response.data;
      nv.addGraph(function () {
        // var width = document.getElementById('chart1').clientWidth;
        this.chart = nv.models.discreteBarChart()
          .x(function (d) { return d.label })
          .y(function (d) { return d.value })
          .margin({ right: 50, left: 20 })
          .rightAlignYAxis(true)      //Let's move the y-axis to the right side.
          .staggerLabels(true)
          .showValues(true)
          .valueFormat(function (d) {
            return d3.format(',.1f')(d) + '%';
          })
          .height(450)
        // .width(width);

        var datum = response.data
        d3.select('#keyMatrics svg')
          .datum(datum)
          .call(this.chart);

        nv.utils.windowResize(this.chart.update);

        return this.chart;
      });
    } else {
      return
    }
    // })
  }

  loadLinechart(response) {

    d3.selectAll("#growth-rate svg > *").remove();
    this.showloader('growthloader');
    var width = document.getElementById('total-return').clientWidth;
    // let stock = localStorage.getItem('symbol');
    // this.apiService.get(apiUrls.stockPerformance + stock + '/growth').subscribe((response: any) => {
    if (response.error == false) {
      this.growthrate = response.data
      var datum = response.data
      console.log('growth-rate',this.growthrate)
      this.hideloader('growthloader');
      nv.addGraph(function () {
        this.chart = nv.models.cumulativeLineChart()
          .useInteractiveGuideline(true)
          .x(function (d) { return d[0] })
          .y(function (d) { return d[1] / 100 })
          .color(d3.scale.category10().range())
          .average(function (d) { return d.mean / 100; })
          .duration(300)
          .clipVoronoi(false)
          .height(350)
          .width(width);
        // this.chart.interactiveLayer.tooltip.fixedBottom(100);
        this.chart.dispatch.on('renderEnd', function () {
        });

        this.chart.xAxis.tickFormat(function (d) {
          return d3.time.format('%m/%d/%y')(new Date(d))
        });

        this.chart.yAxis.tickFormat(d3.format(',.1%'));
       
        d3.select('#growth-rate svg')
          .datum(datum)
          .call(this.chart);
        nv.utils.windowResize(this.chart.update);
        this.chart.dispatch.on('stateChange', function (e) { nv.log('New State:', JSON.stringify(e)); });
        this.chart.state.dispatch.on('change', function (state) {
          nv.log('state', JSON.stringify(state));
        });
        return this.chart;
      });
    } else {
      return
    }
  }
  // })





  loadEps() {
    d3.selectAll("#eps svg > *").remove();
    var numericalStdPipe = new NumericalStdPipe()
    this.showloader('epsloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.stockPerformance + stock + '/eps/annual').subscribe((response: any) => {
      this.hideloader('epsloader');
      if (response.error == false) {
        this.eps = response.data;
        var width = document.getElementById('growth-rate').clientWidth;
        // var height = document.getElementById('retun-bar').clientHeight;
        nv.addGraph({
          generate: function () {
            var chart = nv.models.multiBarChart()
              .height(400)
              .width(width)
              .stacked(false)
            chart.tooltip.enabled(true)
            var data = response.data;
            chart.color(["#1f77b4", "#f97f0f"])
            chart.xAxis.tickFormat(function (d) {
              var dx = d
              var date = new Date(dx)
              var month = date.getMonth()
              var quarter
              if (month < 4) {
                quarter = 'Q1'
              } else if (month < 8) {
                quarter = 'Q2'
              } else if (month < 9) {
                quarter = 'Q3'
              } else if (month < 13) {
                quarter = 'Q4'
              }
              return quarter + ' ' + date.getFullYear()
            });
            chart.yAxis.tickFormat(function (d) {
              return numericalStdPipe.transform(d)
            });
            d3.select('#eps svg')
              .datum(data)
              .call(chart);
            return chart;
          },
        })
      } else {
        return
      }
    })
  }

  loadEpsQ(response) {
    d3.selectAll("#epsq svg > *").remove();
    this.showloader('epsloader');
    let stock = localStorage.getItem('symbol');
    var numericalStdPipe = new NumericalStdPipe()
    // this.apiService.get(apiUrls.stockPerformance + stock + '/eps/quarter').subscribe((response: any) => {
    this.hideloader('epsloader');
    if (response.error == false) {
      this.epsQuater = response.data;
      var epsquater = response.data
      var width = document.getElementById('growth-rate').clientWidth;
      // var height = document.getElementById('retun-bar').clientHeight;
      nv.addGraph({
        generate: function () {
          var chart = nv.models.multiBarChart()
            .height(400)
            .width(width)
            .stacked(false)
          chart.tooltip.enabled(true)
          chart.color(["#1f77b4", "#f97f0f"])
          chart.xAxis.tickFormat(function (d) {
            var dx = d
            var date = new Date(dx)
            var month = date.getMonth()
            var quarter
            if (month < 4) {
              quarter = 'Q1'
            } else if (month < 7) {
              quarter = 'Q2'
            } else if (month < 9) {
              quarter = 'Q3'
            } else if (month < 13) {
              quarter = 'Q4'
            }
            return quarter + ' ' + date.getFullYear()
          });
          chart.yAxis.tickFormat(function (d) {
            return numericalStdPipe.transform(d)
          });
          d3.select('#epsq svg')
            .datum(epsquater)
            .call(chart);
          return chart;
        },
      })
    } else {
      return
    }

    // })
  }


  loadRevenue() {
    d3.selectAll("#revenue svg > *").remove();
    var numericalStdPipe = new NumericalStdPipe()
    this.showloader('revenueloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.stockPerformance + stock + '/revenue/annual')
      .subscribe((response: any) => {
        this.hideloader('revenueloader');
        if (response.error == false) {
          this.revenue = response.data;
          var width = document.getElementById('growth-rate').clientWidth;
          nv.addGraph({
            generate: function () {
              var chart = nv.models.multiBarChart()
                .height(400)
                .width(width)
                // .showValues(true)
                .stacked(false)
              chart.tooltip.enabled(true)
              var data = response.data;
              chart.color(["#1f77b4", "#f97f0f"])
              chart.xAxis.tickFormat(function (d) {
                return d3.time.format('%Y')(new Date(d))
              });
              chart.yAxis
                .tickFormat(function (d) {
                  return numericalStdPipe.transform(d)
                });
              d3.select('#revenue svg')
                .datum(data)
                .call(chart);
              return chart;
            },
          })
        } else {
          return
        }

      })
  }

  loadRevenueQ(response) {

    d3.selectAll("#quarterly svg > *").remove();
    this.showloader('revenueloader');
    let stock = localStorage.getItem('symbol');
    var numericalStdPipe = new NumericalStdPipe()
    // this.apiService.get(apiUrls.stockPerformance + stock + '/revenue/quarter').subscribe((response: any) => {
    this.hideloader('revenueloader');

    var checkforvalue = response.data[0]
    if (response.error == false  && checkforvalue.values ) {
      var datum = response.data
      this.revenueearningquart = response.data;
      var width = document.getElementById('growth-rate').clientWidth;
      nv.addGraph({
        generate: function () {
          var chart = nv.models.multiBarChart()
            .height(400)
            .width(width)
            .stacked(false)
          chart.tooltip.enabled(true)
          var data = response.data;
          chart.color(["#1F77B4", "#F97F0F"])
          chart.xAxis.tickFormat(function (d) {
            var dx = d
            var date = new Date(dx)
            var month = date.getMonth()
            var quarter
            if (month < 3) {
              quarter = 'Q1'
            } else if (month < 6) {
              quarter = 'Q2'
            } else if (month < 9) {
              quarter = 'Q3'
            } else if (month < 12) {
              quarter = 'Q4'
            }
            return quarter + ' ' + date.getFullYear()
          });
          chart.yAxis.tickFormat(function (d) {
            return numericalStdPipe.transform(d)
          });
          d3.select('#quarterly svg')
            .datum(datum)
            .call(chart);
          return chart;
        },
      })
    } else {
      return
    }

    // })
  }
  hideloader(val) {
    document.getElementById(val)
      .style.display = 'none';
  }
  showloader(val) {
    document.getElementById(val)
      .style.display = 'block';
  }
  getTotalrate(response) {
    d3.selectAll("#tl svg > *").remove();
    d3.selectAll("#tl1 svg > *").remove();
    d3.selectAll("#tl2 svg > *").remove();
    d3.selectAll("#tl3 svg > *").remove();
    d3.selectAll("#tl4 svg > *").remove();
    d3.selectAll("#tl5 svg > *").remove();
    d3.selectAll("#tl6 svg > *").remove();
    this.showloader('trloader');
    this.trtable = false;
    this.totalRate = [];
    let stock = localStorage.getItem('symbol');
    // this.apiService.get(apiUrls.stockPerformance + stock + '/total-rate').subscribe((response: any) => {
    if (response.error == false) {
      this.trtable = true;
      this.totalRate = response.data;
      this.hideloader('trloader');
      var color = ['#ffa500', '#F9327A', '#9B55E5']
      this.oneMonth = this.totalRate.map((ele, i) => {
        if (ele.oneMonth !== '-') {
          return { x: 0, y: ele.oneMonth }
        } else {
          return { x: 0, y: 0 }
        }
      })
      this.loadtl(this.oneMonth)
      this.threeMonth = this.totalRate.map((ele, i) => {
        if (ele.threeMonth !== '-') {
          return { x: 0, y: ele.threeMonth }
        } else {
          return { x: 0, y: 0 }
        }
      })
      this.loadtl1(this.threeMonth)
      this.ytd = this.totalRate.map((ele, i) => {
        if (ele.ytdMonth) {
          return { x: 0, y: ele.ytdMonth }
        } else {
          return { x: 0, y: 0 }
        }
      })

      this.loadtl2(this.ytd)
      this.oneYear = this.totalRate.map((ele, i) => {
        if (ele.oneYear) {
          return { x: 0, y: ele.oneYear }
        } else {
          return { x: 0, y: 0 }
        }
      })
      this.loadtl3(this.oneYear)
      this.threeYear = this.totalRate.map((ele, i) => {
        if (ele.threeYear) {
          return { x: 0, y: ele.threeYear }
        } else {
          return { x: 0, y: 0 }
        }
      })
      this.loadtl4(this.threeYear)
      this.fiveYear = this.totalRate.map((ele, i) => {
        if (ele.fiveYear) {
          return { x: 0, y: ele.fiveYear }
        } else {
          return { x: 0, y: 0 }
        }
      })
      this.loadtl5(this.fiveYear)
      this.tenYear = this.totalRate.map((ele, i) => {
        if (ele.tenYear) {
          return { x: 0, y: ele.tenYear }
        } else {
          return { x: 0, y: 0 }
        }
      })
      this.loadtl6(this.tenYear)
    }
    // })
  }



  loadtl(oneMonthChart) {
    var datum = [
      {
        "key": "Stock",
        "values": [oneMonthChart[0]]
      },
      {
        "key": "Index",
        "values": [oneMonthChart[1]]
      },
      {
        "key": "Sector",
        "values": [oneMonthChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showLegend(false)
          .showXAxis(false)
          .showYAxis(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }

  loadtl1(threeMonthChart) {
    var datum = [
      {
        "key": "Stock",
        "values": [threeMonthChart[0]]
      },
      {
        "key": "Index",
        "values": [threeMonthChart[1]]
      },
      {
        "key": "Sector",
        "values": [threeMonthChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showXAxis(false)
          .showYAxis(false)
          .showLegend(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl1 svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }

  loadtl2(ytdMonthChart) {
    var datum = [
      {
        "key": "Stock",
        "values": [ytdMonthChart[0]]
      },
      {
        "key": "Index",
        "values": [ytdMonthChart[1]]
      },
      {
        "key": "Sector",
        "values": [ytdMonthChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showLegend(false)
          .showXAxis(false)
          .showYAxis(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl2 svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }

  loadtl3(oneYearChart) {
    d3.selectAll("#tl3 svg > *").remove();
    var datum = [
      {
        "key": "Stock",
        "values": [oneYearChart[0]]
      },
      {
        "key": "Index",
        "values": [oneYearChart[1]]
      },
      {
        "key": "Sector",
        "values": [oneYearChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showXAxis(false)
          .showYAxis(false)
          .showLegend(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl3 svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }

  loadtl4(threeYearChart) {
    d3.selectAll("#tl4 svg > *").remove();
    var datum = [
      {
        "key": "Stock",
        "values": [threeYearChart[0]]
      },
      {
        "key": "Index",
        "values": [threeYearChart[1]]
      },
      {
        "key": "Sector",
        "values": [threeYearChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showLegend(false)
          .showXAxis(false)
          .showYAxis(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl4 svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }

  loadtl5(fiveYearChart) {
    d3.selectAll("#tl5 svg > *").remove();
    var datum = [
      {
        "key": "Stock",
        "values": [fiveYearChart[0]]
      },
      {
        "key": "Index",
        "values": [fiveYearChart[1]]
      },
      {
        "key": "Sector",
        "values": [fiveYearChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showLegend(false)
          .showXAxis(false)
          .showYAxis(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl5 svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }

  loadtl6(tenYearChart) {
    d3.selectAll("#tl6 svg > *").remove();
    var datum = [
      {
        "key": "Stock",
        "values": [tenYearChart[0]]
      },
      {
        "key": "Index",
        "values": [tenYearChart[1]]
      },
      {
        "key": "Sector",
        "values": [tenYearChart[2]]
      }
    ]

    nv.addGraph({
      generate: function () {
        var chart = nv.models.multiBarChart()
          .margin({ "left": 0, "right": 0, "top": 10, "bottom": 10 })
          .height(100)
          .groupSpacing(0.4)
          .width(75)
          .stacked(false)
          .showControls(false)
          .showLegend(false)
          .showXAxis(false)
          .showYAxis(false)
          .color(['#ffa500', '#9B55E5', '#F9327A'])
        chart.tooltip.enabled(false)

        d3.select('#tl6 svg')
          .datum(datum)
          .call(chart);
        return chart;
      },
    })
  }
  getgoogleTrends(response) {

    d3.selectAll("#trends svg > *").remove();
    // this.showloader('trendsloader');
    this.hideloader('trendsloader');
    let stock = localStorage.getItem('symbol');
    // this.apiService.get(apiUrls.googleTrends + stock).subscribe((response: any) => {
    var width = document.getElementById('trends').clientWidth;
    var data = response.data
    if (response.error == false) {
      this.googleTrends = response.data
      nv.addGraph(function () {
        this.chart = nv.models.linePlusBarChart()
          .margin({ top: 30, right: 60, bottom: 50, left: 70 })
          .color(d3.scale.category10().range())
          .height(400)
          .width(width);

        this.chart.xAxis.tickFormat(function (d) {
          return d3.time.format('%x')(new Date(d))
        });
        this.chart.x2Axis.tickFormat(function (d) {
          return d3.time.format('%b %Y')(new Date(d))
        });

        this.chart.y1Axis
          .tickFormat(d3.format(',f'));

        this.chart.y2Axis
          .tickFormat(function (d) { return d.toFixed(2) });

        this.chart.bars.forceY([0]);
        this.chart.switchYAxisOrder(true)

        d3.select('#trends svg')
          .datum(data)
          .transition()
          .duration(0)
          .call(this.chart);

        nv.utils.windowResize(this.chart.update);

        return this.chart;
      });
    } else {
      this.googleTrends = {}
    }
    // })
  }

  getfundamental(response) {
    this.showloader('fundamentalloader');
    let stock = localStorage.getItem('symbol');
    const normalize = (value) => {
      var nValue = Math.abs(value)
      if (nValue < 1) {
        return nValue * 100
      } else if (nValue < 10) {
        return nValue * 10
      } else if (nValue > 100 && nValue < 999) {
        return nValue / 10
      } else if (nValue > 1000) {
        return nValue / 100
      }
      return nValue
    }
    // this.apiService.get(apiUrls.fundamentalMetrics + stock).subscribe((response: any) => {
    if (response.error == false) {
      this.debitEquity = response.data.debitEquity.stock;
      var gauge1 = (this.debitEquity) * 10;
      this.needle1 = 100 - gauge1;
      this.hgt = normalize(this.debitEquity) * 1.5;
      this.rem = 150 - this.hgt;
      this.freeCashFlow = response.data.freeCashFlow.stock;
      this.hgt2 = normalize(this.freeCashFlow) * 1.5;
      this.rem1 = this.hgt2;
      this.peRatio = response.data.peRatio.stock;
      if ((this.peRatio > 100) || (this.peRatio = 100)) {
        this.needle7 = (this.peRatio) / 100;
      } else {
        this.needle7 = 100 - this.pegRatio;
      }
      this.hgt3 = normalize(this.peRatio) * 1.5;
      this.rem2 = 150 - this.hgt3;
      this.pegRatio = response.data.pegRatio.stock;
      if ((this.pegRatio < 1) || (this.pegRatio = 1)) {
        var gauge3 = Math.abs((this.pegRatio) * 100);
        this.needle3 = Math.abs(100 - gauge3);
      } else {
        this.needle3 = this.pegRatio;
      }
      this.hgt4 = normalize(this.pegRatio) * 1.5;
      this.rem3 = 150 - this.hgt4
      this.priceSales = response.data.priceSales.stock;
      var gauge2 = (this.priceSales) * 10;
      this.needle2 = 100 - gauge2;
      this.hgt5 = normalize(this.priceSales * 1.5);
      this.rem4 = 150 - this.hgt5;
      this.priceToBook = response.data.priceToBook.stock;
      if (this.priceToBook < 3) {
        var gauge5 = (this.priceToBook) * 100;
        this.needle5 = Math.abs((300 - gauge5) / 2);
      } else {
        this.needle5 = this.priceToBook / 3;
      }
      this.hgt8 = normalize(this.priceToBook * 1.5);
      this.rem7 = 150 - this.hgt8
      this.quickRatio = response.data.quickRatio.stock;
      if ((this.quickRatio > 1) || (this.quickRatio = 1)) {
        var gauge8 = Math.abs((this.quickRatio) * 100);
        this.needle8 = Math.abs(100 - gauge8);
      } else {
        this.needle8 = this.quickRatio;
      }
      this.hgt6 = normalize(this.quickRatio * 1.5);
      this.rem5 = 150 - this.hgt6;
      this.roeReturn = response.data.roeReturn.stock;
      var gauge4 = (this.roeReturn) * 100;
      this.needle4 = 100 - gauge4;
      this.hgt7 = normalize(this.roeReturn * 1.5);
      this.rem6 = 150 - this.hgt7;
      this.hideloader('fundamentalloader');
      this.fundamental = true;
      var gaugewidth = document.getElementById('growth-rate').clientWidth;
      this.canvasWidth = gaugewidth / 4.2
    }
    // })
  }

  revenuefun() {
    // this.showloader('revenueloader'); 
    let stock = localStorage.getItem('symbol');
    var numericalStdPipe = new NumericalStdPipe()
    //     this.apiService.get(apiUrls.stockPerformance + stock + '/revenue/quarter').subscribe((response: any) => {
    //     var datum1 = response.data[0];
    //     var datum2 = response.data[1];
    //     if (response) { 
    //       this.hideloader('revenueloader'); 
    //   var data1 = JSON.parse(JSON.stringify(datum1));
    //   var data2 = JSON.parse(JSON.stringify(datum2));
    //   data1.forEach(x => {  
    //       let a = new Date (x.x)
    //       var month = a.getMonth()
    //       var quarter
    //       if (month < 4) {
    //         quarter = 'Q1'
    //       } else if (month < 7) {
    //         quarter = 'Q2'
    //       } else if (month < 9) {
    //         quarter = 'Q3'
    //       } else if (month < 13) {
    //         quarter = 'Q4'
    //       }
    //       var yAxis = quarter + ' ' + a.getFullYear()
    //       this.array1.push({y:yAxis});
    //   });
    //   data1.forEach(x => {  
    //     let a = numericalStdPipe.transform(x.y)
    //     var y1 = a
    //     this.array1.push({y1:y1});
    // });
    // data2.forEach(x => {  
    //   let a = numericalStdPipe.transform(x.y)
    //   var y2 = a
    //   this.array1.push({y2:y2});

    // });
    // } 
    // })

    // console.log(this.array1)
    //     let chart = am4core.create('chartdiv', am4charts.XYChart)
    // chart.colors.step = 2;
    // // chart.legend.fill = am4core.color("white"); 
    // am4core.color("red");
    // chart.legend = new am4charts.Legend()
    // chart.legend.position = 'top'
    // chart.legend.paddingBottom = 20
    // chart.legend.labels.template.maxWidth = 95

    // let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    // xAxis.dataFields.category = 'category'
    // xAxis.renderer.cellStartLocation = 0.1
    // xAxis.renderer.cellEndLocation = 0.9
    // xAxis.renderer.grid.template.location = 0;

    // let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // yAxis.min = 0;

    // function createSeries(value, name) {
    //     let series = chart.series.push(new am4charts.ColumnSeries())
    //     series.dataFields.valueY = value
    //     series.dataFields.categoryX = 'category'
    //     series.name = name

    //     series.events.on("hidden", arrangeColumns);
    //     series.events.on("shown", arrangeColumns);

    //     let bullet = series.bullets.push(new am4charts.LabelBullet())
    //     bullet.interactionsEnabled = false
    //     bullet.dy = 30;
    //     bullet.label.text = '{valueY}'
    //     bullet.label.fill = am4core.color('#ffffff')

    //     return series;
    // }

    // chart.data = [
    //     {
    //         category: 'Place #1',
    //         first: 40,
    //         second: 55,
    //         third: 60
    //     },
    //     {
    //         category: 'Place #2',
    //         first: 30,
    //         second: 78,
    //         third: 69
    //     },
    //     {
    //         category: 'Place #3',
    //         first: 27,
    //         second: 40,
    //         third: 45
    //     },
    //     {
    //         category: 'Place #4',
    //         first: 50,
    //         second: 33,
    //         third: 22
    //     }
    // ]


    // createSeries('first', 'The First');
    // createSeries('second', 'The Second');
    // createSeries('third', 'The Third');

    // function arrangeColumns() {

    //     let series = chart.series.getIndex(0);

    //     let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    //     if (series.dataItems.length > 1) {
    //         let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
    //         let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
    //         let delta = ((x1 - x0) / chart.series.length) * w;
    //         if (am4core.isNumber(delta)) {
    //             let middle = chart.series.length / 2;

    //             let newIndex = 0;
    //             chart.series.each(function(series) {
    //                 if (!series.isHidden && !series.isHiding) {
    //                     series.dummyData = newIndex;
    //                     newIndex++;
    //                 }
    //                 else {
    //                     series.dummyData = chart.series.indexOf(series);
    //                 }
    //             })
    //             let visibleCount = newIndex;
    //             let newMiddle = visibleCount / 2;

    //             chart.series.each(function(series) {
    //                 let trueIndex = chart.series.indexOf(series);
    //                 let newIndex = series.dummyData;

    //                 let dx = (newIndex - trueIndex + middle - newMiddle) * delta

    //                 series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
    //                 series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
    //             })
    //         }
    //     }
    // }

  }
}
