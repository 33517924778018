import { Component, OnInit, AfterViewInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { NumericalStdPipe } from 'src/app/numerical-std.pipe';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;
@Component({
  selector: 'app-similar-companies',
  templateUrl: './similar-companies.component.html',
  styleUrls: ['./similar-companies.component.css']
})
export class SimilarCompaniesComponent implements OnInit, AfterViewInit {
  peer: any;
  sortDir = 1;
  peerlist: any = [];
  pList: boolean;
  searchResult: any;
  borClr = 'f00'
  constructor(private apiService: ApiService,
    public router: Router,
    private load: LoadService,
  ) { }
  ngAfterViewInit() {
    $('#smililerdropdown').on('click', function() {
      this
      var value = $('#smililerdropdown').val();
      var valuess = $('#smililerdropdown :selected').text();
      //  $('#smililerdropdown').html($(this).html() + '<span class="caret"></span>'); 
      // $('.dropdown-toggle').html($(this).html() + '<span class="caret"></span>');    
    })
  }
  ngOnInit() {
    this.loadSimilarcompanies('ytdChangePercent');
    this.getStockquote();
    // this.load.watchStorage().subscribe(data =>{
    //   this.loadSimilarcompanies('ytdChangePercent');
    //   this.getStockquote();
    // })
  }
  hideloader(val) {
    document.getElementById(val)
      .style.display = 'none';
  }
  showloader(val) {
    document.getElementById(val)
      .style.display = 'block';
  }
  loadSimilarcompanies(val) {
    d3.selectAll("#peer svg > *").remove();
    this.showloader('peerloader');
    let stock = localStorage.getItem('symbol');
    if (val.target) {
      var getvalue = val.target.value
    }
    else {
      var getvalue = val
    }
    this.apiService.get(apiUrls.peerAnalysis + stock + '/' + getvalue).subscribe((response: any) => {
      this.hideloader('peerloader');
      if (response.error == false) {
        this.peer = response.data;
        // var width = document.getElementById('financial').clientWidth;
        if (getvalue !== 'epsGrowth') {
          nv.addGraph(function() {
            if (val == 'ytdChangePercent') {
              var chart = nv.models.discreteBarChart()
                .x(function(d) { return d.label })
                .y(function(d) { return d.value * 100 })
                .showValues(true)
                .valueFormat(function(d) {
                  return d3.format(',.2f')(d) + '%';
                })
                // .groupSpacing(0.5)
                ;
            } else if (getvalue == 'marketcap') {
              var numericalStdPipe = new NumericalStdPipe()
              var chart = nv.models.discreteBarChart()
                .x(function(d) { return d.label })
                .y(function(d) { return d.value })
                .showValues(true)
                .valueFormat(function(d) {
                  return numericalStdPipe.transform(d);
                })
              chart.yAxis
                .tickFormat(function(d) {
                  return numericalStdPipe.transform(d);
                });
            } else {
              var chart = nv.models.discreteBarChart()
                .x(function(d) { return d.label })
                .y(function(d) { return d.value })
                .showValues(true)
            }

            chart.height(500)
            var datum = response.data
            d3.select('#peer svg')
              .datum(datum)
              .call(chart)
              ;

            nv.utils.windowResize(datum.update);

            return datum;
          });
        } else {
          nv.addGraph({
            generate: function() {

              var chart = nv.models.multiBarChart()
                .height(500)
                // .showValues(true)
                .stacked(false)
              chart.tooltip.enabled(true)
              var data = response.data;
              // chart.color(["#1f77b4", "#f97f0f"])
              // chart.xAxis.tickFormat(function(d) {
              //   return d3.time.format('%Y')(new Date(d))
              // });
              // chart.yAxis
              d3.select('#peer svg')
                .datum(data)
                .call(chart);
              return chart;
            },
          })
        }
      } else {
        return
      }
    })
  }

  getStockquote() {
    this.pList = false;
    this.showloader('peerstockloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.peerList + stock).subscribe((response: any) => {
      if (response) {
        this.hideloader('peerstockloader');
        this.pList = true;
        this.peerlist = response.data;
      }
    })
  }

  getDateformat(date) {
    if (date) {
      let dateFormat = new Date(date);
      var day = dateFormat.getDate();
      var month = dateFormat.getMonth();
      var year = dateFormat.getFullYear();
      return month + '/' + day + '/' + year;
    } else {
      return '-';
    }
  }

  numberWithCommas(val) {
    return val.toLocaleString();
  }

  onSortClick(event, colName) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = 1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = -1;
    }
    if ((colName === 'symbol') || (colName === 'companyName')) {
      this.peerlist.sort((a, b) => {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      });
    } else if (colName === 'nextEarningsDate') {
      if (this.sortDir === -1) {
        this.peerlist.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate)) * (-1);
        })
      } else {
        this.peerlist.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate));
        })
      }
    }
    // else if (colName === 'enterpriseValue'){
    //   if(this.sortDir === -1){
    //     this.peerlist.sort(function(a, b) {
    //       console.log(a[colName]);
    //       return  (+new Date(a[colName]) - +new Date(b[colName]))*(-1);
    //     })
    //   } else {
    //     // this.peerlist.sort(function(a, b) {
    //     //   console.log(a[colName]);

    //     //   return  (a[colName] - b[colName]);
    //     // })
    //     this.peerlist.sort((a, b) => (a[colName] > b[colName] ? -1 : 1));
    //     return
    //   }
    // }
    else {
      this.peerlist.sort((a, b) => (a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0) * this.sortDir)
    }

  }
  stockDetail(stock) {
    let symbol = {
      sym: stock
    }
    let symbol1 = {
      text: stock
    }
    console.log(localStorage.getItem('searchSym'))
    this.apiService.post(apiUrls.search, symbol1).subscribe((response: any) => {
      this.searchResult = response.data[0];
      localStorage.setItem('searchSym', this.searchResult.symbol);
      localStorage.setItem('searchname', this.searchResult.name);
      localStorage.setItem('searchExc', this.searchResult.exchange);
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate(['stockdetail/overview'], { queryParams: symbol });
      });
    })
  }
  getepsgrowth() {
    d3.selectAll("#peer svg > *").remove();
    this.showloader('peerloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.peerAnalysis + stock + '/epsGrowth').subscribe((response: any) => {
      this.peer = response.data;
      if (response) {
        this.hideloader('peerloader');
      }
      var width = document.getElementById('growth-rate').clientWidth;
      nv.addGraph({
        generate: function() {

          var chart = nv.models.multiBarChart()
            .height(400)
            .width(width)
            // .showValues(true)
            .stacked(false)
          chart.tooltip.enabled(true)
          var data = response.data;
          chart.color(["#1f77b4", "#f97f0f"])
          chart.xAxis.tickFormat(function(d) {
            return d3.time.format('%Y')(new Date(d))
          });
          chart.yAxis
          d3.select('#growthRate svg')
            .datum(data)
            .call(chart);
          return chart;
        },
      })
    })

  }

  schedule(value) {
  }
}