import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  submitted: boolean;
  changepassForm: FormGroup;
  loginData: any;
  changepass: any;
  newpass: boolean;
  passwordObj: any =[];
  changePword: any;
  changepassData: any;
  passwordData: any;
  otp: any;
  email: any;
  mailId: any;

  constructor( public router:Router, private formBuilder: FormBuilder, private apiService: ApiService, private toastr: ToastrService,private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['otp']) {
        this.otp = params['otp'];
      }
      if (params['email']) {
        this.mailId = params['email'];
      }
    });
    if(this.otp){
      this.newpass = true;
    } else{
      this.newpass = false;
    }
  this.changepassForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    otp: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(8)]],
  }, 
);
  }
  get d() { return this.changepassForm.controls; }
  
  passwordotp(){
    this.submitted = true;
    this.email= this.changepassForm.value.email
    if (this.changepassForm.value.email.invalid) {
        return;
    }
    let par = {
      email: this.changepassForm.value.email
    }
    this.apiService.post(apiUrls.forgotpassword, par).subscribe((response: any) => {
      this.passwordData = response;
      if (!this.passwordData.error) {
        this.toastr.success('The OTP has been sent to ' + this.changepassForm.value.email, 'Success');
        let navigationExtras: NavigationExtras = {
          queryParams: {
              "type": "forgotpassword",
              "email": this.email
          }
        }
        this.router.navigate(['otp-verify'], navigationExtras);
        this.newpass = false;
  } else {
        this.toastr.error(this.passwordData.msg, 'Error');
      }
    })
  }

  updatepass(data){
    if(data.newpass === data.confirmpass){
      this.changePword = data.newpass
    } else{
      this.toastr.error('New Password and Confirm password should be same', 'Error');
    }
    let par = {
      email: this.mailId,
      otp: this.otp,
      password: this.changePword
    }
    this.apiService.post(apiUrls.resetpassword, par).subscribe((response: any) => {
      this.changepassData = response;
      if (!this.changepassData.error) {
        this.toastr.success(this.changepassData.msg, 'Success');
        this.router.navigate(['login']);
  } else {
        this.toastr.error(this.changepassData.msg, 'Error');
      }
    })
  }

  goBack(){
    this.location.back()
  }
}
