import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullWithDefault'
})
export class NullWithDefaultPipe implements PipeTransform {

  transform(value): any {
    if (value === null) {
      return 'N/A';
    }
  
    return value;
  }

}