import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-plandetails',
  templateUrl: './plandetails.component.html',
  styleUrls: ['./plandetails.component.css']
})
export class PlandetailsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }

  selectPlan(days,type,amount){
  
    var obj ={
      days:days,
      plan:type,
      amount:amount
    }
     localStorage.setItem('plantype',JSON.stringify(obj))
     this.router.navigate(['payment'])
  }
}
