import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.services';
import { apiUrls } from '../../environments/apiUrls';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from './must-match.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileObj: any = [];
  changePasswordForm: FormGroup;
  profileForm: FormGroup;
  submitted = false;
  constructor(private apiService: ApiService, public router: Router, private formBuilder: FormBuilder, private toastr: ToastrService) {
    localStorage.removeItem('searchExc')
    localStorage.removeItem('searchSym')
    localStorage.removeItem('searchname')
  }

  ngOnInit() {




    this.changePasswordForm = this.formBuilder.group({
      currentpassword: [''],
      newpassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmpassword: ['', Validators.required, Validators.minLength(8)],

    }, {
      validator: MustMatch('newpassword', 'confirmpassword')
    });

    this.profileForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', [Validators.required]],
      email: ['', Validators.required],
      username: [''],

    });

    this.getprofile();
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;


    if (this.changePasswordForm.invalid) {
      return;
    }


  }

  changePassword() {

    this.changePasswordForm.value
    var obj = {
      currentpassword: this.changePasswordForm.value.currentpassword,
      newpassword: this.changePasswordForm.value.newpassword

    }
    this.apiService.post(apiUrls.changepassword, obj).subscribe((response: any) => {
      if (response.error == false) {
        this.toastr.success(response.msg, 'Success');
      } else {
        this.toastr.error(response.msg, 'Error');
      }


    })
  }

  updateProfile() {

    this.profileForm.value
    var obj = {
      firstname:this.profileForm.value.firstname,
       lastname:this.profileForm.value.lastname,
       username:this.profileForm.value.username
    }
    this.apiService.post(apiUrls.updateProfile, obj).subscribe((response: any) => {
      if (response.error == false) {
        this.toastr.success(response.msg, 'Success');
      } else {
        this.toastr.error(response.msg, 'Error');
      }

    })
  }

  getprofile() {
    this.apiService.get(apiUrls.getProfile).toPromise().then((response: any) => {
      this.profileObj = response.data;
      this.profileForm.patchValue(this.profileObj)
      localStorage.setItem('usertype', this.profileObj.plan)
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }
}
