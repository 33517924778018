import { Component, OnInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;
@Component({
  selector: 'app-industrial-production',
  templateUrl: './industrial-production.component.html',
  styleUrls: ['./industrial-production.component.css']
})
export class IndustrialProductionComponent implements OnInit {
  activeCls: string;
  range: string;
  prod: any;
  constructor(private apiService: ApiService, private _router: ActivatedRoute, private loadservice:LoadService) { }

  ngOnInit() {
    this.loadservice.economicGraph().subscribe(data =>{
      if(data == 'ip'){
        this.forFiveYear();
      }
    })
  }

  loadindustrialProduction(range) {
    // d3.selectAll("#chart svg > *").remove();
    this.showloader('iploader'); 
    this.range = range;
    this.apiService.get(apiUrls.industrialProduction + range).subscribe((response: any) => {
      this.hideloader('iploader'); 
    if (response.error == false) {
      var width = document.getElementById('news').clientWidth;
      var data = response.data
      nv.addGraph(function() {
        this.chart = nv.models.linePlusBarChart()
              .margin({top: 30, right: 60, bottom: 50, left: 70})
              .color(d3.scale.category10().range())
              .height(500)
              .width(width);
              this.chart.xAxis.tickFormat(function(d) {
                return d3.time.format('%x')(new Date(d))
              });
              this.chart.x2Axis.tickFormat(function (d) {
                return d3.time.format('%b %Y')(new Date(d))
              });
              this.chart.y1Axis
                  .tickFormat(d3.format(',f'));
        
              this.chart.y2Axis
                  .tickFormat(function(d) { return d.toFixed(2) });
        
              this.chart.bars.forceY([0]);
              this.chart.switchYAxisOrder(true)
        
              d3.select('#productionchart svg')
                .datum(data)
                .transition()
                .duration(0)
                .call(this.chart);
        
              nv.utils.windowResize(this.chart.update);
              
              return this.chart;
      });
    } else {
      return
    }
    })
  }
  hideloader(val) { 
    document.getElementById(val) 
        .style.display = 'none'; 
  }
  showloader(val) { 
    document.getElementById(val) 
        .style.display = 'block'; 
  }
  businessDayToString(businessDay) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
  }

  forOneDay(){
    this.activeCls = '1d';
    this.loadindustrialProduction (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.loadindustrialProduction (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.loadindustrialProduction (this.activeCls)
  }
  forSixMonth(){
    this.activeCls = '6m';
    this.loadindustrialProduction (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.loadindustrialProduction (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.loadindustrialProduction (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.loadindustrialProduction (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.loadindustrialProduction (this.activeCls)
  }
  forAllYear(){
    this.activeCls = 'all';
    this.loadindustrialProduction (this.activeCls)
  }
}
