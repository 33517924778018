import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'apicem';


  ngOnDestroy() {
    // localStorage.removeItem('searchname')
    // localStorage.removeItem('searchSym');
    // localStorage.removeItem('searchExc')
  }
}
