import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-pe-ratio',
  templateUrl: './pe-ratio.component.html',
  styleUrls: ['./pe-ratio.component.css']
})
export class PeRatioComponent implements OnInit {
  peratio: any;
  activeCls: any;
  chart: any;

  constructor( private apiService: ApiService,private loadservice:LoadService) { }

  ngOnInit() {
    this.loadservice.dashboardGraph().subscribe(data =>{
      if(data == 'peratio'){
        this.forYearToEnd();
      }
    })
  }
  loadPEratioChart(range){
    d3.selectAll("#chart4 svg > *").remove();
    this.showloader('perloader'); 
    d3.selectAll('.nvtooltip').remove();
    this.apiService.get(apiUrls.peratio + range).subscribe((response: any) => {
      if(response){
        this.hideloader('perloader'); 
      }
      if (response.error == false) {
        if(response.data){
        var width = document.getElementById('chartDetails').clientWidth;
        nv.addGraph(function() {
        this.chart = nv.models.cumulativeLineChart()
          .useInteractiveGuideline(true)
          .x(function(d) { return d[0] })
          .y(function(d) { return d[1]/100 })
          .color(["#dd2c00","#2ba02b","#ffc107"])
          .average(function(d) { return d.mean/100; })
          .duration(300)
          .clipVoronoi(false)
          .height(500)
          .width(width);
            // this.chart.interactiveLayer.tooltip.fixedBottom(100);
        this.chart.dispatch.on('renderEnd', function() {
        });
  
        this.chart.xAxis.tickFormat(function(d) {
            return d3.time.format('%m/%d/%y')(new Date(d))
        });
  
        this.chart.yAxis.tickFormat(d3.format(',.1%'));
        var datum = response.data
        d3.select('#chart4 svg')
            .datum(datum)
            .call(this.chart);
            $(window).on("scroll", function(){
              d3.selectAll('.nvtooltip').style('opacity', '0');
            })
        nv.utils.windowResize(this.chart.update);
        this.chart.dispatch.on('stateChange', function(e) { nv.log('New State:', JSON.stringify(e)); });
        this.chart.state.dispatch.on('change', function(state){
            nv.log('state', JSON.stringify(state));
        });
        return this.chart;
      });
        }
      } else {
        return
      }
    })
  }

  hideloader(val) { 
    document.getElementById(val) 
        .style.display = 'none'; 
  }
  showloader(val) { 
    document.getElementById(val) 
        .style.display = 'block'; 
  }
  forOneDay(){
    this.activeCls = '1d';
    this.loadPEratioChart (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.loadPEratioChart (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.loadPEratioChart (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.loadPEratioChart (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.loadPEratioChart (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.loadPEratioChart (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.loadPEratioChart (this.activeCls)
  }
  forAllYear(){
    this.activeCls = 'all';
    this.loadPEratioChart (this.activeCls)
  }
}
