import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JsonPipe, Location } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { parse } from 'querystring';
import { HttpClientJsonpModule } from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-common-header-two',
  templateUrl: './common-header-two.component.html',
  styleUrls: ['./common-header-two.component.css']
})
export class CommonHeaderTwoComponent implements OnInit {
  accessTocken: string;
  comingSoon: boolean;
  searchResult: any = [];
  public keyword = 'name';
  selected: any;
  selectedSearch: boolean;
  ipSearch: boolean;
  preLocation: string;
  showip: any;
  selectedSym: any;
  selectedname: any;
  selectedexc: any;
  symDet: any = [];
  storedsym: string;
  inputvalue: any = "name";
  itemTemplate: any;
  popContent: any;
  enterResult: any = [];
  showstocksearch: boolean = true;
  profileObj: any;
  constructor(public router: Router, private toastr: ToastrService,
    private renderer: Renderer2, private modalService: NgbModal,
    private apiService: ApiService, private location: Location,
    private ref: ChangeDetectorRef,) {
    this.router.events
      .subscribe((event: any) => {
        localStorage.setItem('currentlocation', event.url);
        if (event.url == '/payment' || event.url == '/invite') {
          this.showstocksearch = false
        }
        else {
          this.showstocksearch = true
        }
      });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu) {
        if (e.target !== this.menu.nativeElement) {
          this.searchResult = []
        }
      }
    });

    console.log('router url', this.router.url)
  }

  @ViewChild('menu', { static: false }) menu: ElementRef;
  ngOnInit() {
    $(document).ready(function () {
  
      const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]') as HTMLInputElement;
      const currentTheme = localStorage.getItem('theme');
      
      if (currentTheme) {
          document.documentElement.setAttribute('data-theme', currentTheme);
        
          if (currentTheme === 'dark') {
              toggleSwitch.checked = true;
          }
      }
      
      function switchTheme(e) {
          if (e.target.checked) {
              document.documentElement.setAttribute('data-theme', 'dark');
              localStorage.setItem('theme', 'dark');
          }
          else {        document.documentElement.setAttribute('data-theme', 'light');
                localStorage.setItem('theme', 'light');
          }    
      }
      
      toggleSwitch.addEventListener('change', switchTheme, false);


      $("#search_list").focusin(function () {
        $(this).css("background-color", "#FFFFCC");
      });
      $("#search_list").focusout(function () {
        $(this).css("display", "none");
      });
    });
    this.comingSoon = false;
    this.preLocation = localStorage.getItem('currentlocation');
    console.log(this.preLocation)
    this.storedsym = localStorage.getItem('searchSym');
    if (this.storedsym !== '' && this.storedsym !== null && this.preLocation !== "/dashboard" && this.preLocation !== "/dashboard-new") {
      this.selectedSearch = true;
      this.ipSearch = false;
      this.selectedSym = localStorage.getItem('searchSym');
      this.selectedname = localStorage.getItem('searchname')
      this.selectedexc = localStorage.getItem('searchExc')
      console.log(this.selectedSym);
    } else {
      this.selectedSearch = false;
      this.ipSearch = true;
    }

    this.getprofile()
  }



  getprofile() {
    this.apiService.get(apiUrls.getProfile).toPromise().then((response: any) => {
      this.profileObj = response.data;
      localStorage.setItem('usertype', this.profileObj.plan)
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }


  logout() {
    localStorage.clear();
    this.toastr.success('Log out Successfully!', 'Success');
    this.router.navigate(['']);
  }
  goToLandingpg() {
    this.accessTocken = localStorage.getItem('accessTocken');
    if (this.accessTocken) {
      this.router.navigate(['dashboaed']);
    } else {
      this.router.navigate(['']);
    }
  }
  comingsoon() {
    this.comingSoon = true;
  }
  open(commingsoon) {
    this.modalService.open(commingsoon, { centered: true });
  }

  async selectEvent(symArr) {
    const symbol = {
      sym: symArr.symbol,
    }
    var path = this.router.url.split("?")

    // if(path[0] === '/overview'){
    //   this.selSearch(sym, nname, exe);
    //   await this.router.navigate(['overview'],{ queryParams: symbol });
    //    window.location.reload(true);
    // }else{
    this.selSearch(symArr.symbol, symArr.name, symArr.exchange);
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate(['stockdetail/new/overview'], { queryParams: symbol });
    });
    // this.router.navigate(['stockdetail/overview'],{ queryParams: symbol });
    // }
  }
  enterEvent(item) {


  }

  async selSearch(sym, name, exe) {
    console.log(sym)
    localStorage.setItem('searchSym', sym);
    this.selectedSym = localStorage.getItem('searchSym');
    localStorage.setItem('searchname', name)
    this.selectedname = localStorage.getItem('searchname')
    localStorage.setItem('searchExc', exe)
    this.selectedexc = localStorage.getItem('searchExc')
    this.selectedSearch = true;
    this.ipSearch = false;

  }

  inputSearch() {
    this.selectedSearch = false;
    this.ipSearch = true;
  }
  onChangeSearch(search: string) {

  }

  onFocused(e) {

  }

  searchSuggest(event) {
    const x = event.which || event.keyCode;
    if (x !== undefined) {
      const val = event.target.value.toLowerCase();
      if (val.length >= 1) {
        this.search(val);
      }
      else {
        this.searchResult = []
      }
      if (event.code == 'Enter') {
        var enterResult = this.searchResult.filter(function (result) {
          return result.symbol === val.toUpperCase();
        });
        if (enterResult) {
          console.log(enterResult)
          this.selSearch(enterResult[0].symbol, enterResult[0].name, enterResult[0].exchange)
          let param = {
            sym: enterResult[0].symbol
          }
          this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
            this.router.navigate(['stockdetail//new/overview'], { queryParams: param });
          });
        } else {
          let par = {
            text: val
          }
          this.apiService.post(apiUrls.search, par).subscribe((response: any) => {
            this.enterResult = response.data[0];
            const symbol = {
              sym: this.enterResult.symbol,
            }
            console.log(this.enterResult.symbol)
            this.selSearch(this.enterResult.symbol, this.enterResult.name, this.enterResult.exchange);
            this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
              this.router.navigate(['stockdetail/new/overview'], { queryParams: symbol });

            });
          })
        }
      }
    }
  }
  search(value) {
    let par = {
      text: value
    }
    this.apiService.post(apiUrls.search, par).subscribe((response: any) => {

      this.searchResult = response.data;
      this.ref.detectChanges()
    })
  }
  searchInput() {
    var showip = JSON.parse(localStorage.getItem('searchInp'));
    if (showip.search == true) {
      return true
    } else {
      return false
    }
  }

  method() {
  }

  toggleWithGreeting(popover, greeting: string, language: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ greeting, language });
    }
  }

  toggleout(popover) {
    popover.close();
  }
}
