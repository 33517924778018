import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppComponent } from './app.component';


import { AppRoutingModule } from './app-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { from } from 'rxjs';



import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { InviteComponent } from './invite/invite.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FeedbackComponent } from './feedback/feedback.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './home/header/header.component';
import { LoginComponent } from './Authentication/login/login.component';
import { ForgotpasswordComponent } from './Authentication/forgotpassword/forgotpassword.component';
import { OtpVerificationComponent } from './Authentication/otp-verification/otp-verification.component';
import { SignupComponent } from './Authentication/signup/signup.component';
import { AuthGuardService } from '../services/auth.gaurd.services';
import { LoadService } from '../services/load.service';
import { UserguardService } from '../services/userguard.service';
import { DataResoverService } from '../services/dataresover.service';
import { NewspopupComponent } from './dashboard/newspopup/newspopup.component';
import { InvertedYieldComponent } from './dashboard/economic-indicator/inverted-yield/inverted-yield.component';
import { GdpComponent } from './dashboard/economic-indicator/gdp/gdp.component';
import { UsUnemploymentComponent } from './dashboard/economic-indicator/us-unemployment/us-unemployment.component';
import { InitialUnemploymentComponent } from './dashboard/economic-indicator/initial-unemployment/initial-unemployment.component';
import { IndustrialProductionComponent } from './dashboard/economic-indicator/industrial-production/industrial-production.component';
import { NationalHomePriceComponent } from './dashboard/economic-indicator/national-home-price/national-home-price.component';
import { ConsumerPriceComponent } from './dashboard/economic-indicator/consumer-price/consumer-price.component';
import { FullAnalysisComponent } from './stockdetails/full-analysis/full-analysis.component';
import { StockdetailsModule } from './stockdetails/stockdetails.module';
import { NegativeNumberPipe } from './negative-number.pipe';
import { MarketOverviewComponent } from './dashboard/market-overview/market-overview.component';
import { HistoricalPerformanceComponent } from './dashboard/historical-performance/historical-performance.component';
import { ValotilityIndexComponent } from './dashboard/valotility-index/valotility-index.component';
import { PeRatioComponent } from './dashboard/pe-ratio/pe-ratio.component';
import { MarketSentimentComponent } from './dashboard/market-sentiment/market-sentiment.component';

import { StocklistComponent } from './stocklist/stocklist.component';
import { AppinterceptorService } from './appinterceptor.service';
import { PaymentComponent } from './payment/payment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { PlandetailsComponent } from './payment/plandetails/plandetails.component';
import { DashboardTwoComponent } from './dashboard/dashboard-two/dashboard-two.component';
import { NullWithDefaultPipes } from './null-with-default.pipe';
import { JwPaginationComponent } from 'jw-angular-pagination';
import { ExportService } from './stocklist/export.service';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { PortfolioanalystComponent } from './portfolioanalyst/portfolioanalyst.component';
import { ApiService } from 'src/services/api.services';
import { DoghnutchartComponent } from './stocklist/doghnutchart/doghnutchart.component';

export function initWithDependencyFactory(
  quoteOFTheDayService: ApiService
) {
  // return () => {
  //   console.log('initWithDependencyFactory - started');
  //   return quoteOFTheDayService
  //     .stockMovers()
  //     .toPromise()
  //     .then((result) => {
  //       console.log('result', result);
  //       console.log('initWithDependencyFactory - completed');
  //     });
  // };

  return (): Promise<any> => { 
    return quoteOFTheDayService.Init();
  }
}



@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    DashboardComponent,
    LoginComponent,
    HomeComponent,
    NullWithDefaultPipes,
    ProfileComponent,
    ForgotpasswordComponent,
    MarketOverviewComponent,
    HistoricalPerformanceComponent,
    ValotilityIndexComponent,
    PeRatioComponent,
    MarketSentimentComponent,
    OtpVerificationComponent,
    ComingSoonComponent,
    InviteComponent,
    FeedbackComponent,
    HeaderComponent,
    NewspopupComponent,
    InvertedYieldComponent,
    GdpComponent,
    UsUnemploymentComponent,
    InitialUnemploymentComponent,
    IndustrialProductionComponent,
    NationalHomePriceComponent,
    ConsumerPriceComponent,
    FullAnalysisComponent,
    StocklistComponent,
    PaymentComponent,
    PlandetailsComponent,
    DashboardTwoComponent,
    JwPaginationComponent,
    PortfolioComponent,
    PortfolioanalystComponent,
    DoghnutchartComponent,
   

  ],
  imports: [
    AppRoutingModule,
    NgSelectModule,
    BrowserModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    StockdetailsModule,
    NgSelectModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // NgxStripeModule.forRoot("pk_test_nDR7IWEIGLp4a1SBtqKH5eyg"),
    NgxStripeModule.forRoot("pk_test_vOV2cp2iSotxO0XCLlp2muPM00STX4Kv67"),
    ToastrModule.forRoot(),
    SlickCarouselModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgCircleProgressModule.forRoot({
      radius: 30,
      outerStrokeWidth: 8,
      innerStrokeWidth: 0,
      outerStrokeColor: "#78C000",
      // innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    StockdetailsModule,
  ],
  providers: [
    AuthGuardService,
    LoadService,
    UserguardService,
    DataResoverService,
    ThemeService,
    AppinterceptorService,
    ExportService,
    {
      provide: APP_INITIALIZER,
      useFactory: initWithDependencyFactory,
      deps: [ApiService],
      multi:true
    },
  ],
  entryComponents :[DoghnutchartComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
