import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { StockdetailsRoutingModule } from './stockdetails-routing.module';
import { OverviewComponent } from './overview/overview.component';
import { FinancialsComponent } from './financials/financials.component';
import { KeymetricsComponent } from './keymetrics/keymetrics.component';
import { OwnershipComponent } from './ownership/ownership.component';
import { SimilarCompaniesComponent } from './similar-companies/similar-companies.component';
import { StockdetailsComponent } from './stockdetails.component';
import { CommonHeaderComponent } from '../common-header/common-header.component';
import { NumericalStdPipe } from '../numerical-std.pipe';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { GaugeChartModule } from 'angular-gauge-chart';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { CommingsoonComponent } from '../commingsoon/commingsoon.component';
import { SidenaveComponent } from '../sidenave/sidenave.component';
import { NegativeNumberPipe } from '../negative-number.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiinterseptorService } from '../apiinterseptor.service';
import { AnalysisComponent } from './analysis/analysis.component';
import { FormsModule } from '@angular/forms';
import { NgxStarRatingModule } from 'ngx-star-rating';

import { CommonHeaderTwoComponent } from '../common-header/common-header-two/common-header-two.component';
import { NullWithDefaultPipe } from './pipes/null-with-default.pipe';


@NgModule({
  declarations: [
    StockdetailsComponent,
    OverviewComponent,
    FinancialsComponent,
    OwnershipComponent,
    SimilarCompaniesComponent,
    CommonHeaderComponent,
    CommonHeaderTwoComponent,
    KeymetricsComponent,
    NumericalStdPipe,
    CommingsoonComponent,
    SidenaveComponent,
    NegativeNumberPipe,
    AnalysisComponent,
    NullWithDefaultPipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    NgxStarRatingModule,
    StockdetailsRoutingModule,
    AngularFontAwesomeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ChartsModule,
    AutocompleteLibModule,
    GaugeChartModule,
  ],
  exports:[
    CommonHeaderComponent,
    CommonHeaderTwoComponent,
    CommingsoonComponent,
    NumericalStdPipe,
    NegativeNumberPipe,
    SidenaveComponent,
  ],
  providers: [
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiinterseptorService, multi: true }
  ],
})
export class StockdetailsModule { }
