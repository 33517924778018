import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/services/api.services';
import { apiUrls } from '../../environments/apiUrls';
import { DoghnutchartComponent } from '../stocklist/doghnutchart/doghnutchart.component';
import { StocklistService } from '../stocklist/stocklist.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-portfolioanalyst',
  templateUrl: './portfolioanalyst.component.html',
  styleUrls: ['./portfolioanalyst.component.css']
})
export class PortfolioanalystComponent implements OnInit {
  myportfoliolist: any;
  currentPortfolio: any;
  stockListDetailsindexETF: any;
  stockListDetailbondETF: any;
  stockListDetailsbondETF: any;
  stockListDetailsstocks: any;
  selected: any;
  loader: boolean = true;
  sortDir = 1;
  @ViewChild(DoghnutchartComponent, { static: true }) doughtchart: DoghnutchartComponent
  storedIndex: any;
  donughtchartSymbol: any;
  total_costbasis_bond = 0;
  total_GainLossPrice_bond = 0;
  total_GainLossPercent_bond: any;
  total_costbasis_stock = 0;
  total_marketvalue_stock = 0;
  total_GainLossPrice_stock = 0;
  total_GainLossPercent_stock: any;
  total_marketvalue_bond = 0;
  constructor(private apiservice: ApiService, private router: Router, private stockservice: StocklistService, private activateroute: ActivatedRoute) { }

  ngOnInit() {
    setTimeout(() => {
      this.loader = false
    }, 3000)
    if (this.activateroute.snapshot.params.id) {
      this.getPortfolioAnalysis(this.activateroute.snapshot.params.id)
    } else {
      this.getPortfolioAnalysis(0)
    }
    // this.getPortfolioAnalysis(0)

  }

  getPortfolioAnalysis(index) {
    this.apiservice.get(apiUrls.myportfolio).subscribe((res: any) => {
      if (res.error == false) {
        this.myportfoliolist = res.data
        this.indexChanging(index)

      }
    })
  }

  stockDetail(stock) {
    let symbol = {
      sym: stock
    }
    let symbol1 = {
      text: stock
    }
    console.log(localStorage.getItem('searchSym'))
    this.apiservice.post(apiUrls.search, symbol1).subscribe((response: any) => {
      var searchResult = response.data[0];
      localStorage.setItem('searchSym', searchResult.symbol);
      localStorage.setItem('searchname', searchResult.name);
      localStorage.setItem('searchExc', searchResult.exchange);
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate(['stockdetail/overview'], { queryParams: symbol });
      });
    })
  }


  indexChanging(index) {
    this.total_costbasis_stock = 0
    this.total_marketvalue_stock = 0
    this.total_GainLossPrice_stock = 0
    this.total_GainLossPercent_stock = 0
    this.total_costbasis_bond = 0
    this.total_marketvalue_bond = 0
    this.total_GainLossPrice_bond = 0
    this.total_GainLossPercent_bond = 0
    this.select(this.myportfoliolist[index]._id)
    this.isActive(this.myportfoliolist[index])
    this.storeindex(index)
    this.currentPortfolio = this.myportfoliolist[index]
    // this.step = this.portfoliolist[0]

    this.getBulkQuote(this.currentPortfolio.bondETF, 'bondETF')
    // this.getBulkQuote(this.currentPortfolio.indexETF, 'indexETF');
    this.getBulkQuote(this.currentPortfolio.stocks, 'stocks');

    console.log('portfolios', this.myportfoliolist);
  }

  getBulkQuote(symbols, type) {
    var objsymbol = symbols.map((data) => { return { symbol: data } })

    this.stockservice.bulkQuote(objsymbol).subscribe((res: any) => {
      if (res.error == false) {
        // if (type == 'indexETF') {
        //   this.stockListDetailsindexETF = this.stockCoverter(res.data)
        //   var symbols = this.stockListDetailsindexETF.map((data: any) => {
        //     var obj = {
        //       "symbol": data.symbol
        //     }
        //     return obj
        //   })
        //   console.log('indexETF');


        // } else 
        if (type == 'stocks') {
          this.stockListDetailsstocks = this.stockCoverter(res.data, type, res.data.length, this.currentPortfolio.stocksWithPrice).map(element => {
            // debugger
            console.log('elements  logging', element);

            this.total_costbasis_stock += element.costBasis;
            this.total_marketvalue_stock += +(element.marketvalue);
            this.total_GainLossPrice_stock += +(element.totalGainLossPrice);
            this.total_GainLossPercent_stock = (this.total_GainLossPrice_stock / this.total_costbasis_stock) * 100;
            return element
          });

          var symbols = this.stockListDetailsstocks.map((data: any) => {
            var obj = {
              "symbol": data.symbol
            }

            return obj
          })

          this.donughtchartSymbol = symbols
          // this.doughtchart.getchartData(symbols)


        } else if (type == 'bondETF') {
          this.stockListDetailsbondETF = this.stockCoverter(res.data, type, res.data.length, this.currentPortfolio.stocksWithPrice).map(element => {
            this.total_costbasis_bond += element.costBasis;
            this.total_marketvalue_bond += +(element.marketvalue);
            this.total_GainLossPrice_bond += +(element.totalGainLossPrice);
            this.total_GainLossPercent_bond = (this.total_GainLossPrice_bond / this.total_costbasis_bond) * 100
            return element
          });
          this.stockListDetailbondETF
          var symbols = this.stockListDetailsbondETF.map((data: any) => {
            var obj = {
              "symbol": data.symbol
            }
            return obj
          })


        }
        // this.stockListDetails = this.stockCoverter(res.data)
        // var symbols = this.stockListDetails.map((data: any) => {
        //   var obj = {
        //     "symbol": data.symbol
        //   }
        //   return obj
        // })
        // this.doughtchart.getchartData(symbols)
      }
    })
    // var eventsymb = [{"symbol":"WOR"},{"symbol":"EBF"}]

  }

  deletePortfolio() {
    console.log("_id", this.selected)
    Swal.fire({
      title: 'Are you sure want to Delete Portfolio?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.stockservice.deletePortfolio(this.selected).subscribe((res: any) => {
          if (res.error == false) {
            this.getPortfolioAnalysis(0)
            Swal.fire(
              'Deleted!',
              'success'
            )
          }
        })

      }
    })

  }

  stockCoverter(stocks, type, total, stocksPrice) {
    var stock = stocks.map(res => {
      var portfolioCreatedData = stocksPrice.find(function(element) {
        return (element.symbol == res.symbol);
      });
      // console.log("res", res)
      var day50MovingAvg = ((res.latestPrice / res.day50MovingAvg) - 1) * 100
      var day200MovingAvg = ((res.latestPrice / res.day200MovingAvg) - 1) * 100
      var extendedChange = ((res.extendedChange / (res.close ? res.close : res.previousClose)) * 100)
      var changePercent = (res.changePercent * 100)
      var ytdChange = (res.ytdChange * 100)
      if (type == "stocks") {
        var costBasisTemp = (this.currentPortfolio.stockInvestmentAmount / total)
        var qty = Math.floor(costBasisTemp / portfolioCreatedData.open)
        var marketvalue = res.latestPrice * qty
        var costBasis = portfolioCreatedData.open * qty
      }
      if (type == "bondETF") {
        var costBasisTemp = (this.currentPortfolio.bondInvestmentAmount / total)
        var qty = Math.floor(costBasisTemp / portfolioCreatedData.open)
        var marketvalue = res.latestPrice * qty
        var costBasis = portfolioCreatedData.open * qty
      }
      var totalgl1 = marketvalue - costBasis
      var totalgl = ((qty * res.close ? res.close : res.previousClose) - costBasis)
      var totalGainLossPrice = totalgl1
      var totalGainLossPercent = (totalGainLossPrice / costBasis) * 100
      let data = {
        url: res.url ? res.url : "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/" + res.symbol + ".png",
        symbol: res.symbol,
        companyName: res.companyName,
        close: res.iexClose ? res.iexClose : res.previousClose,
        portfolioCreatedClose: portfolioCreatedData.open,
        costBasis: costBasis,
        qty: qty,
        marketvalue: marketvalue.toFixed(2),
        extendedChange: extendedChange,
        changePercent: changePercent,
        ytdChange: ytdChange,
        day50MovingAvg: day50MovingAvg,
        day200MovingAvg: day200MovingAvg,
        peRatio: res.peRatio,
        marketCap: res.marketCap,
        nextEarningsDate: res.nextEarningsDate,
        priceToSales: res.priceToSales
      }
      if (portfolioCreatedData.open == res.iexClose) {
        data['totalGainLossPrice'] = 0
        data['totalGainLossPercent'] = 0
      } else {
        data['totalGainLossPrice'] = totalGainLossPrice.toFixed(2)
        data['totalGainLossPercent'] = totalGainLossPercent.toFixed(2)
      }
      // console.log("data", data)
      return data
    })

    return stock
  }

  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  storeindex(index) {
    this.storedIndex = index
  }

  onSortClick(event, colName) {
    // debugger
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    if ((colName === 'symbol') || (colName === 'companyName')) {
      this.stockListDetailsbondETF.sort((a, b) => {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      });
    } else if (colName === 'nextEarningsDate') {
      if (this.sortDir === -1) {
        this.stockListDetailsbondETF.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate)) * (-1);
        })
      } else {
        this.stockListDetailsbondETF.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate));
        })
      }
    } else {
      this.stockListDetailsbondETF.sort((a, b) => (a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0) * this.sortDir)
    }

  }

  onSortClickInvestment(event, colName) {

    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    if ((colName === 'symbol') || (colName === 'companyName')) {
      this.stockListDetailsstocks.sort((a, b) => {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      });
    } else if (colName === 'nextEarningsDate') {
      if (this.sortDir === -1) {
        this.stockListDetailsstocks.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate)) * (-1);
        })
      } else {
        this.stockListDetailsstocks.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate));
        })
      }
    } else {
      this.stockListDetailsstocks.sort((a, b) => (a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0) * this.sortDir)
    }

  }


}
