import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, ActivatedRoute,NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.services'
import { apiUrls } from '../../../environments/apiUrls';
import { ToastrService } from 'ngx-toastr';
declare let $: any;

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OtpVerificationComponent implements OnInit {
  otpverification: any;
  email: any;
  otpForm: FormGroup;
  forgotPassotp: any;
  type: any;
  passwordData: any;

  constructor( public router:Router, private formBuilder: FormBuilder, private apiService: ApiService, private toastr: ToastrService,  private route: ActivatedRoute,) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['email']) {
        this.email = params['email'];
      }
      if (params['type']) {
        this.type = params['type'];
      }
    });
    if(this.type == 'signupOTP'){
      this.resendOTP();
    }
    this.otpForm = this.formBuilder.group({
      first: ['', Validators.required],
      second: ['', Validators.required],
      third: ['', Validators.required],
      fourth: ['', Validators.required],
    });

    $('.digit-group').find('input').each(function () {
      $(this).attr('maxlength',1);
      $(this).on('keyup', function (e) {
        var parent = $($(this).parent());
        if (e.keyCode === 8 || e.keyCode === 37) {
          var prev = parent.find('input#' + $(this).data('previous'));
          if (prev.length) {
            $(prev).select();
          }
        } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
          var next = parent.find('input#' + $(this).data('next'));
          if (next.length) {
            $(next).select();
          } else {
            if (parent.data('autosubmit')) {
              parent.submit();
            }
          }
        }
      });
    });
  }
 get f() { return this.otpForm.controls; }

  verifyOTP(){
    let otp = (this.otpForm.value).first+(this.otpForm.value).second+(this.otpForm.value).third+(this.otpForm.value).fourth;
    if(this.type === "forgotpassword"){
      let par = {
        email: this.email,
        otp: otp,
        type:'reset'
      }
      this.apiService.post(apiUrls.otpverify, par).subscribe((response: any) => {
        this.otpverification = response;
        if (!this.otpverification.error) {
          this.toastr.success(this.otpverification.msg, 'Success');
          this.forgotPassotp = otp;
          let navigationExtras: NavigationExtras = {
            queryParams: {
                "otp": this.forgotPassotp,
                "email": this.email
            }
        };
          this.router.navigate(['forgotpassword'], navigationExtras);
        } else {
          this.toastr.error(this.otpverification.msg, 'Error');
        }
      })
      
    } else {
    let par = {
      email: this.email,
      otp: otp,
      type:'signup'
    }
    this.apiService.post(apiUrls.otpverify, par).subscribe((response: any) => {
      this.otpverification = response;
      if (!this.otpverification.error) {
        this.toastr.success(this.otpverification.msg, 'Success');
        this.router.navigate(['dashboard']);

      } else {
        this.toastr.error(this.otpverification.msg, 'Error');
      }
    })
  }
  }
  resendOTP(){
    if(this.type === "forgotpassword"){
      let par = {
        email: this.email
      }
      this.apiService.post(apiUrls.forgotpassword, par).subscribe((response: any) => {
        this.passwordData = response;
        if (!this.passwordData.error) {
          this.toastr.success('The OTP has been sent to ' + this.email, 'Success');
        } else {
          this.toastr.error(this.passwordData.msg, 'Error');
        }
      })
    } else {
      let par = {
        email: this.email,
        type: 'signup'
      }
      this.apiService.post(apiUrls.resendOTP, par).subscribe((response: any) => {
        this.passwordData = response;
        if (!this.passwordData.error) {
          this.toastr.success('The OTP has been sent to ' + this.email, 'Success');
        } else {
          this.toastr.error(this.passwordData.msg, 'Error');
        }
      })
    }
  }
}
