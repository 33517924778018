import {
	makeApiRequest,
	generateSymbol,
	parseFullSymbol,
} from './helpers.js';
import {
	subscribeOnStream,
	unsubscribeFromStream,
} from './streaming.js';

// import PolygonWebsockets from './websockets.js'
const lastBarsCache = new Map();
const base_Url = 'https://api.polygon.io/v3'


const configurationData = {
	supported_resolutions: ['1D', '1W', '1M',],
	// exchanges: [{
	// 	value: 'Bitfinex',
	// 	name: 'Bitfinex',
	// 	desc: 'Bitfinex',
	// },
	// {
	// 	// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
	// 	value: 'Kraken',

	// 	// filter name
	// 	name: 'Kraken',

	// 	// full exchange name displayed in the filter popup
	// 	desc: 'Kraken bitcoin exchange',
	// },
	// ],
	// symbols_types: [{
	// 	name: 'crypto',

	// 	// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
	// 	value: 'crypto',
	// },
	// ...
	// ],
};


export default {
	onReady: (callback) => {
		// console.log('[onReady]: Method call');
	    //   wsListeners()
		setTimeout(() => callback(configurationData));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		// console.log('[searchSymbols]: Method call');
		const searchsym = `${base_Url}/reference/tickers?sort=ticker&search=${userInput}&perpage=100&apiKey=eXrcxIhjF1pr1yWMk4kbQ62AI5WQo_S6`
		const symbols = await makeApiRequest(searchsym);
		// const newSymbols = symbols.filter(symbol => {
		// 	const isExchangeValid = exchange === '' || symbol.exchange === exchange;
		// 	const isFullSymbolContainsInput = symbol.full_name
		// 		.toLowerCase()
		// 		.indexOf(userInput.toLowerCase()) !== -1;
		// 	return isExchangeValid && isFullSymbolContainsInput;
		// });

		// console.log("saerch result=>", symbols.tickers)

		var newsymbols = symbols.results.map(item => {
			return {
				symbol: item.ticker,
				ticker: item.ticker,
				full_name: item.name,
				description: `${item.name}`,
				exchange: item.primary_exchange,
				type: item.market,
				locale: item.locale,
			}
		})

		console.log('tradeview chart', newsymbols)
		onResultReadyCallback(newsymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		// console.log('[resolveSymbol]: Method call', symbolName);


		var getsymbol = localStorage.getItem('symbol')
		const symbolquery = `${base_Url}/reference/tickers?sort=ticker&search=${getsymbol}&perpage=100&apiKey=eXrcxIhjF1pr1yWMk4kbQ62AI5WQo_S6`
		const Symboldata = await makeApiRequest(symbolquery)

		const Info = Symboldata.results[0]

		// console.log("resolver symbol data =>", Symboldata.tickers)
		const symbolInfo = {
			name: Info.name,
			description: Info.ticker + '/' + Info.currency_name,
			type: 'Crypt',
			session: '24x7',
			timezone: 'America/New_York',
			exchange: Info.primary_exchange,
			// interval:"W",
			minmov: 1,
			pricescale: 100,
			// has_intraday: false,
			// has_no_volume: true,
			// has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			// volume_precision: 2,
			// data_status: 'streaming',
		};

		// console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);

	},

	getBars: async (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) => {
		console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
		var range = '1/day'
		if (resolution == '1D') {
			range = '1/day'
		} else if (resolution == '1W') {
			range = '1/week'
		} else if (resolution == '1M') {
			range = '1/month'
		}
		var s = new Date(from)
		var st = new Date(to)

		function formatDate(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2)
				month = '0' + month;
			if (day.length < 2)
				day = '0' + day;

			return [year, month, day].join('-');
		}

		var fromDAte = new Date(from * 1000);
		var fromto = formatDate(fromDAte)
		var toDate = new Date(to * 1000)
		const setTo = formatDate(toDate)
		// var dateString = myDate.toGMTString();
		var getsymbol = localStorage.getItem('symbol')
		const query = `https://api.polygon.io/v2/aggs/ticker/${getsymbol}/range/${range}/${fromto}/${setTo}?apikey=eXrcxIhjF1pr1yWMk4kbQ62AI5WQo_S6`
		try {
			const data = await makeApiRequest(query)
			let bars = [];
			data.results.forEach(t => {
				bars = [...bars, {
					time: t.t,
					close: t.c,
					open: t.o,
					high: t.h,
					low: t.l,
					volume: t.v,
				}];
			});

			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			// console.log(`live data${data.results} bar(s)`);
			// console.log(`custamized data${bars} bar(s)`);
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			// console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		// console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		// console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},

	wsListeners() {
		// if (!this.realtimeEnabled) return
		this.ws = new PolygonWebsockets({ apiKey: this.apikey })
		this.ws.on('AM', (aggMin) => {
			Each(this.subscriptions, (sub) => {
				sub.callback({
					open: aggMin.o,
					close: aggMin.c,
					high: aggMin.h,
					low: aggMin.l,
					volume: aggMin.v,
					time: aggMin.s,
				})
			})
		})
	}
};
