import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProfileService } from '../Core/services/profile.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'app-sidenave',
  templateUrl: './sidenave.component.html',
  styleUrls: ['./sidenave.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SidenaveComponent implements OnInit {
  profile: any=[];
  Stockmenu: boolean;
  popContent: any;

  constructor(private profileservice: ProfileService,public router: Router,private toastr: ToastrService) {
    
   }

  ngOnInit() {
    jQuery(function ($) {
      $(".sidebar-dropdown > a").click(function () {
        $(".page-wrapper").removeClass("toggled");
      });
      $(".page-wrapper").removeClass("toggled");
      $("#close-sidebar").click(function () {
        $(".page-wrapper").removeClass("toggled");
      });
      $("#show-sidebar").click(function () {
        $(".page-wrapper").addClass("toggled");
      });

    });
    this.profileservice.getProfile().subscribe(data => {
       this.profile = data;
       this.profile =this.profile.data;
    })
  }
  logout() {
    localStorage.clear();
    this.toastr.success('Log out Successfully!', 'Success');
    this.router.navigate(['login']);
  }
  showStockmenu(){
    this.Stockmenu = true;
  }
  toggleWithGreeting(popover, greeting: string, language: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({greeting, language});
    }
  }

  toggleout(popover){
    popover.close();
  }
}
