import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  private storageSub= new Subject<string>();
  private graphTab = new Subject<any>();
  private ecoTab = new Subject<any>();
  private stockTab = new Subject<any>();
  constructor() { }
  isLogin(){
    const token = localStorage.getItem('accessToken');
    if(token){
      return true;
    } else {
      return false;
    }
  }
  setItem(key: string, data: any) {
      localStorage.setItem(key, data);
      this.storageSub.next('added');
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setTab(data){
    this.graphTab.next(data)
  }

  dashboardGraph():Observable<any>{
    return this.graphTab.asObservable();
  }

  setEcotab(data){
    this.ecoTab.next(data)
  }

  economicGraph():Observable<any>{
    return this.ecoTab.asObservable();
  }

  setStocktab(data){
    this.stockTab.next(data)
  }
  stockGraph():Observable<any>{
    return this.stockTab.asObservable();
  }
}
