import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-valotility-index',
  templateUrl: './valotility-index.component.html',
  styleUrls: ['./valotility-index.component.css']
})
export class ValotilityIndexComponent implements OnInit {
  volatility: any
  chart: any;
  line1: any
  activeCls: any;


  constructor( private apiService: ApiService,private loadservice:LoadService) { }

  ngOnInit() {
    this.loadservice.dashboardGraph().subscribe(data =>{
      if(data == 'volatility'){
        this.forOneYear()
      }
    })
  }

  loadVolatilityChart(range) {
    this.showloader('viloader'); 
    d3.selectAll("#chart3 svg > *").remove();
    d3.selectAll('.nvtooltip').remove();
    this.apiService.get(apiUrls.volatility + range).subscribe((response: any) => {
      this.hideloader('viloader'); 
      if (response.error == false) {
        this.volatility = response;
        var voltalityindex = response.data[0].values
       
      var width = document.getElementById('chartDetails').clientWidth;
      nv.addGraph(function() {
        this.chart = nv.models.cumulativeLineChart()
            .useInteractiveGuideline(true)
            .x(function(d) { return d[0] })
            .y(function(d) { return d[1]/100 })
            .color(["#dd2c00","#2ba02b"])
            .average(function(d) { return d.mean/100; })
            .duration(300)
            .clipVoronoi(false)
            .height(500)
            .width(width);
        this.chart.dispatch.on('renderEnd', function() {
        });
  
        this.chart.xAxis.tickFormat(function(d) {
            return d3.time.format('%m/%d/%y')(new Date(d))
        });
  
        this.chart.yAxis.tickFormat(d3.format(',.1%'));
        var datum = response.data
        d3.select('#chart3 svg')
            .datum(datum)
            .call(this.chart);
            $(window).on("scroll", function(){
              d3.selectAll('.nvtooltip').style('opacity', '0');
            })
        //TODO: Figure out a good way to do this automatically
        nv.utils.windowResize(this.chart.update);
        
  
        this.chart.dispatch.on('stateChange', function(e) { nv.log('New State:', JSON.stringify(e)); });
        this.chart.state.dispatch.on('change', function(state){
            nv.log('state', JSON.stringify(state));
        });
  
        return this.chart;
      });
    } else {
      return
    }
    })
  }


  hideloader(val) { 
    document.getElementById(val) 
        .style.display = 'none'; 
  }
  showloader(val) { 
    document.getElementById(val) 
        .style.display = 'block'; 
  }
  forOneDay(){
    this.activeCls = '1d';
    this.loadVolatilityChart (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.loadVolatilityChart (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.loadVolatilityChart (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.loadVolatilityChart (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.loadVolatilityChart (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.loadVolatilityChart (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.loadVolatilityChart (this.activeCls)
  }
  forAllYear(){
    this.activeCls = 'all';
    this.loadVolatilityChart (this.activeCls)
  }

}
