import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "../services/auth.gaurd.services";
import { UserguardService } from "../services/userguard.service";
import { DataResoverService } from "../services/dataresover.service";

import { AppComponent } from "./app.component";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { StockdetailsComponent } from "./stockdetails/stockdetails.component";
import { HomeComponent } from "./home/home.component";
import { ProfileComponent } from "./profile/profile.component";

import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { from } from 'rxjs';
import { InviteComponent } from './invite/invite.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LoginComponent } from './Authentication/login/login.component';
import { ForgotpasswordComponent } from './Authentication/forgotpassword/forgotpassword.component';
import { OtpVerificationComponent } from './Authentication/otp-verification/otp-verification.component';
import { SignupComponent } from './Authentication/signup/signup.component';
import { SidenaveComponent } from './sidenave/sidenave.component';
import { CommonHeaderComponent } from './common-header/common-header.component';

import { NewspopupComponent } from './dashboard/newspopup/newspopup.component';
import { FullAnalysisComponent } from './stockdetails/full-analysis/full-analysis.component';
import { StocklistComponent } from './stocklist/stocklist.component';
import { PaymentComponent } from "./payment/payment.component";
import { PlandetailsComponent } from "./payment/plandetails/plandetails.component";
import { CommonHeaderTwoComponent } from "./common-header/common-header-two/common-header-two.component";
import { DashboardTwoComponent } from "./dashboard/dashboard-two/dashboard-two.component";
import { PortfolioComponent } from "./portfolio/portfolio.component";
import { PortfolioanalystComponent } from "./portfolioanalyst/portfolioanalyst.component";
// import { ModalContainerComponent } from './dashboard/modelcontainer.component';

const routes: Routes = [

  {
    path: "",
    component: AppComponent,
    children: [

      {
        path: "",
        canActivate: [UserguardService],
        component: HomeComponent,
      },
      {
        path: "signup",
        canActivate: [UserguardService],
        component: HomeComponent,
      },
      {
        path: "otp-verify",
        canActivate: [UserguardService],
        component: HomeComponent,
      },
      {
        path: 'login',
        canActivate: [UserguardService],
        component: HomeComponent,
      },
      // {
      //   path: "dashboard",
      //   canActivate: [AuthGuardService],
      //   component: CommonHeaderTwoComponent,
      //   children: [
      //     {
      //       path: '',
      //       component: DashboardComponent,
      //       children: [
      //         { path: 'newspopup', component: NewspopupComponent },
      //       ]
      //     }
      //   ]
      // },
      {
        path: "dashboard",
        canActivate: [AuthGuardService],
        component: CommonHeaderTwoComponent,
        children: [
          {
            path: '',
            component: DashboardTwoComponent,
            children: [
              { path: 'newspopup', component: NewspopupComponent },
            ]
          }
        ]
      },
      {
        path: "stockdetail",
        canActivate: [AuthGuardService],
        loadChildren: () => import('./stockdetails/stockdetails.module').then(m => m.StockdetailsModule)
        // component: CommonHeaderComponent
        // children: [{ path: "", component: StockdetailsComponent }],
      },
      {
        path: "payment",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: PaymentComponent }],
      },
      {
        path: "subscription",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: PlandetailsComponent }],
      },
      {
        path: "fullanalysis",
        component: FullAnalysisComponent,
      },
      {
        path: "profile",
        canActivate: [AuthGuardService],
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: ProfileComponent }],
      },
      {
        path: "forgotpassword",
        canActivate: [UserguardService],
        component: HomeComponent,
      },
      {
        path: "coming-soon",
        component: ComingSoonComponent,
      },
      {
        path: "invite",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: InviteComponent }],
      },
      {
        path: "dash",
        component: SidenaveComponent,
      },
      {
        path: "feedback",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: FeedbackComponent }],
      },
      {
        path: "create-portfolio",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: PortfolioComponent }],
      },
      {
        path: "portfolio",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: PortfolioanalystComponent }],
      },
      {
        path: "stocklist",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: StocklistComponent }],
        // loadChildren: () => import('./stocklist/stocklist.module').then(m => m.StocklistModule)

      },
      {
        path: "stocklist/:id",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: StocklistComponent }],
        // loadChildren: () => import('./stocklist/stocklist.module').then(m => m.StocklistModule)

      },
      {
        path: "portfolio/:id",
        component: CommonHeaderTwoComponent,
        children: [{ path: "", component: PortfolioanalystComponent }],
      },


    ],
  },
  {
    path: "**",
    redirectTo: '/'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
