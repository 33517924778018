import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StocklistService {
  baseurl = environment.baseurl;
  accessToken: string;
  constructor(private http: HttpClient) { }

  myStockList() {
    this.accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.get(this.baseurl + '/users/stock/my-stock-list', {
      headers: httpHeaders,
    })
  }

  addNewStock(stocklist) {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/stock/new-stock-list', stocklist, {
      headers: httpHeaders,
    })
  }

  deleteStocks(Id: string) {
    var obj = { Id: Id }
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/stock/delete-my-stock-list', obj, {
      headers: httpHeaders,
    })
  }

  deletePortfolio(Id: string) {
    var obj = { Id: Id }
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/portfolio/delete-my-portfolio', obj, {
      headers: httpHeaders,
    })
  }

  updateStock(stock) {

    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/stock/update-my-stock-list', stock, {
      headers: httpHeaders,
    })
  }

  bulkQuote(symbol) {

    var obj = { symbol: JSON.stringify(symbol) }
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/stock/bulk-quote', obj, {
      headers: httpHeaders,
    })
  }

  bulkEvents(Symbol) {
    var currentDate = new Date()

    var obj = {
      symbol: JSON.stringify(Symbol),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear()
    }
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/fetchBulkEvents', obj, {
      headers: httpHeaders,
    })
  }

  doughNutchart(obj) {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + '/users/stock/industry-sector', obj, {
      headers: httpHeaders,
    })
  }
}
