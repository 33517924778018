import { parseFullSymbol } from './helpers.js';

// const socket = io('wss://socket.polygon.io/stocks');
const ws = new WebSocket('wss://delayed.polygon.io/stocks')
const channelToSubscription = new Map();
// const apikey = 'FIbp4PZB9rrWFY93H1u65_rupyIXYvZw'
var getsymbol = localStorage.getItem('symbol')

ws.onopen = function (e) {
	ws.send(`{"action":"auth","params":"eXrcxIhjF1pr1yWMk4kbQ62AI5WQo_S6"}`)
	// ws.send(`{"action":"subscribe","params":"Q."+${getsymbol}}`)
	// ws.send(`{"action":"subscribe","params":"T.LPL,Q.MSFT"`)
	ws.send(`{"action":"subscribe","params":"Q.AAPL"`)
};

// ws.onopen = function (e) {
	
// };

ws.onmessage = function (event) {
	// alert(`[message] Data received from server: ${event.data}`)
	// console.log(`[message] Data received from server: ${event.data}`)
};

// ws.onDisconnect = function (e) {
// 	setTimeout(ws.onopen = function (e) {
// 		ws.send(`{"action":"auth","params":"eXrcxIhjF1pr1yWMk4kbQ62AI5WQo_S6"}`)
// 	}, 2000)
// }
// ws.send(`{"action":"auth","params":"FIbp4PZB9rrWFY93H1u65_rupyIXYvZw"}`)

// onDisconnect(){
// 	setTimeout( this.connect.bind( this ), 2000 )
// }

// this.ws.onopen = this.onOpen.bind(this)
// this.ws.onclose = this.onDisconnect.bind(this)
// this.ws.onerror = this.onError.bind(this)
// this.ws.onmessage = this.onMessage.bind(this)
// socket.on('connect', () => {
// 	console.log('[socket] Connected');
// });

// socket.on('disconnect', (reason) => {
// 	console.log('[socket] Disconnected:', reason);
// });

// socket.on('error', (error) => {
// 	console.log('[socket] Error:', error);
// });

// socket.on('m', data => {
// 	console.log('[socket] Message:', data);
// 	const [
// 		eventTypeStr,
// 		exchange,
// 		fromSymbol,
// 		toSymbol,
// 		,
// 		,
// 		tradeTimeStr,
// 		,
// 		tradePriceStr,
// 	] = data.split('~');

// 	if (parseInt(eventTypeStr) !== 0) {
// 		// skip all non-TRADE events
// 		return;
// 	}
// 	const tradePrice = parseFloat(tradePriceStr);
// 	const tradeTime = parseInt(tradeTimeStr);
// 	const channelString = `0~${exchange}~${fromSymbol}~${toSymbol}`;
// 	const subscriptionItem = channelToSubscription.get(channelString);
// 	if (subscriptionItem === undefined) {
// 		return;
// 	}
// 	const lastDailyBar = subscriptionItem.lastDailyBar;
// 	const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

// 	let bar;
// 	if (tradeTime >= nextDailyBarTime) {
// 		bar = {
// 			time: nextDailyBarTime,
// 			open: tradePrice,
// 			high: tradePrice,
// 			low: tradePrice,
// 			close: tradePrice,
// 		};
// 		console.log('[socket] Generate new bar', bar);
// 	} else {
// 		bar = {
// 			...lastDailyBar,
// 			high: Math.max(lastDailyBar.high, tradePrice),
// 			low: Math.min(lastDailyBar.low, tradePrice),
// 			close: tradePrice,
// 		};
// 		console.log('[socket] Update the latest bar by price', tradePrice);
// 	}
// 	subscriptionItem.lastDailyBar = bar;

// 	// send data to every subscriber of that symbol
// 	subscriptionItem.handlers.forEach(handler => handler.callback(bar));
// });

function getNextDailyBarTime(barTime) {
	const date = new Date(barTime * 1000);
	date.setDate(date.getDate() + 1);
	return date.getTime() / 1000;
}

export function subscribeOnStream(
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastDailyBar,
) {
	// alert(symbolInfo)
	// console.log("symbols", symbolInfo.description)
	// const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
	// const channelString = `0~${symbolInfo.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
	// const handler = {
	// 	id: subscribeUID,
	// 	callback: onRealtimeCallback,
	// };
	// let subscriptionItem = channelToSubscription.get(channelString);
	// if (subscriptionItem) {
	// 	// already subscribed to the channel, use the existing subscription
	// 	subscriptionItem.handlers.push(handler);
	// 	return;
	// }
	// subscriptionItem = {
	// 	subscribeUID,
	// 	resolution,
	// 	lastDailyBar,
	// 	handlers: [handler],
	// };
	// channelToSubscription.set(channelString, subscriptionItem);
	// console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	// socket.emit('SubAdd', { subs: [channelString] });

	var symbol_Cat = symbolInfo.description.split("/");



	// ws.onopen = function (e) {
	// 	// ws.send(`{"action":"auth","params":"eXrcxIhjF1pr1yWMk4kbQ62AI5WQo_S6"}`)
	// 	ws.send(`{"action":"subscribe","params":"Q."+${symbol_Cat[0]}}`)
	// };

	// ws.onmessage = function (event) {
	// 	// alert(`subscription: ${event.data}`)
	// 	console.log(`subscription: ${event.data}`)
	// };
	
	ws.onmessage = function (event) {
		// alert(`[message] Data received from server: ${event.data}`)
		// console.log(`[message] Data received from server: ${event.data}`)
	};

}

export function unsubscribeFromStream(subscriberUID) {

	// console.log("Unsubscribed ======>", subscriberUID)

	ws.onclose = function (event) {
		if (event.wasClean) {
			// alert(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);

			// console.log('[close] Connection closed cleanly, code=${event.code} reason=${event.reason}')
		} else {
			// e.g. server process killed or network down
			// event.code is usually 1006 in this case
			// alert('[close] Connection died');
			// console.log('[close] Connection died')

		}
	};
	// find a subscription with id === subscriberUID
	// for (const channelString of channelToSubscription.keys()) {
	// 	const subscriptionItem = channelToSubscription.get(channelString);
	// 	const handlerIndex = subscriptionItem.handlers
	// 		.findIndex(handler => handler.id === subscriberUID);

	// 	if (handlerIndex !== -1) {
	// 		// remove from handlers
	// 		subscriptionItem.handlers.splice(handlerIndex, 1);

	// 		if (subscriptionItem.handlers.length === 0) {
	// 			// unsubscribe from the channel, if it was the last handler
	// 			console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
	// 			socket.emit('SubRemove', { subs: [channelString] });
	// 			channelToSubscription.delete(channelString);
	// 			break;
	// 		}
	// 	}
	// }

}
