import { Component, OnInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.services';
declare let nv: any;
declare let d3: any;
@Component({
  selector: 'app-ownership',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership.component.css']
})
export class OwnershipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadOwnership();
  }
  loadOwnership(){
    // var width = document.getElementById('financial').clientWidth;
    nv.addGraph(function() {
      var chart = nv.models.discreteBarChart()
          .x(function(d) { return d.label })  
          .y(function(d) { return d.value })
          .showValues(true)
          .height(300)  
          .width(1500)
          ;
    
      d3.select('#ownershipchart svg')
          .datum(exampleData())
          .call(chart);
    
      nv.utils.windowResize(chart.update);
    
      return chart;
    });
    
    //Each bar represents a single discrete quantity.
    function exampleData() {
     return  [ 
        {
          key: "Cumulative Return",
          values: [
            { 
              "label" : "A Label" ,
              "value" : -29.765957771107
            } , 
            { 
              "label" : "B Label" , 
              "value" : 0
            } , 
            { 
              "label" : "C Label" , 
              "value" : 32.807804682612
            } , 
            { 
              "label" : "D Label" , 
              "value" : 196.45946739256
            } , 
            { 
              "label" : "E Label" ,
              "value" : 0.19434030906893
            } , 
            { 
              "label" : "F Label" , 
              "value" : -98.079782601442
            } , 
            { 
              "label" : "G Label" , 
              "value" : -13.925743130903
            } , 
            { 
              "label" : "H Label" , 
              "value" : -5.1387322875705
            }
          ]
        }
      ]
    
    }
    
  }
}
