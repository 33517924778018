import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonHeaderTwoComponent } from '../common-header/common-header-two/common-header-two.component';
import { CommonHeaderComponent } from '../common-header/common-header.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { FinancialsComponent } from './financials/financials.component';
import { KeymetricsComponent } from './keymetrics/keymetrics.component';
import { OverviewComponent } from './overview/overview.component';
import { SimilarCompaniesComponent } from './similar-companies/similar-companies.component';
import { StockdetailsComponent } from './stockdetails.component';


// import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  { 
    path: '', 
    component: CommonHeaderTwoComponent,
    children: [
      { 
        path: "", 
        component: StockdetailsComponent,
        children: [
          { 
            path: "overview", 
            component: OverviewComponent 
          },
          { 
            path: "keymetrics", 
            component: KeymetricsComponent 
          },
          { 
            path: "financilal", 
            component: FinancialsComponent 
          },
          { 
            path: "similarcompanies", 
            component: SimilarCompaniesComponent 
          },
          { 
            path: "analysis", 
            component: AnalysisComponent
          }
          ],
      }
      ],
  },
  { 
    path: 'new', 
    component: CommonHeaderTwoComponent,
    children: [
      { 
        path: "", 
        component: StockdetailsComponent,
        children: [
          { 
            path: "overview", 
            component: OverviewComponent 
          },
          { 
            path: "keymetrics", 
            component: KeymetricsComponent 
          },
          { 
            path: "financilal", 
            component: FinancialsComponent 
          },
          { 
            path: "similarcompanies", 
            component: SimilarCompaniesComponent 
          },
          { 
            path: "analysis", 
            component: AnalysisComponent
          }
          ],
      }
      ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StockdetailsRoutingModule { }
