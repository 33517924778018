import { Component, OnDestroy, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import * as CanvasJS from '../canvasjs.min';
import { ApiService } from '../../services/api.services';
import { apiUrls } from '../../environments/apiUrls';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { LoadService } from 'src/services/load.service';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;

@Component({
  selector: 'app-stockdetails',
  templateUrl: './stockdetails.component.html',
  styleUrls: ['./stockdetails.component.css'],
  encapsulation: ViewEncapsulation.None,
  host: {
    "(window:resize)": "onWindowResize($event)"
  },
})
export class StockdetailsComponent implements OnInit {
  usmarket: any;
  bond: any;
  stockDetails: any = [];
  dropdownList: any = [];
  secoundDropdown: any = [];
  cities = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys', disabled: true },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' }
  ];
  stockdrop: any;
  stockpro: any;
  public keyword = 'name';
  searchResult: any;
  symbol: any;
  changePer: number;
  openTime: any;
  screenWidth: number;
  screenHeight: number;
  isMobile: boolean;
  mobileWidth: number = 768;
  width: any;
  height: any;
  overView: boolean;
  performance: boolean;
  metrics: boolean;
  financials: boolean;
  peers: boolean;
  ownership: boolean;
  reports: boolean;
  activeTab: any;
  stockDet: boolean = false;
  analysis: boolean;
  usertype: any;
  selected = 'Follow'
  constructor(private apiService: ApiService,
    private toastr: ToastrService,
    private location: Location,
    public router: Router,
    private loadservice: LoadService,
    private modalService: NgbModal,
    private _router: ActivatedRoute,) { }


  ngOnInit() {
    let location = this.router.url.split('?')[0];
    this.activeTab = location;
    if (this.activeTab == '/stockdetail/overview') {
      this.overView = true;
    }
    window.scrollTo(0, 0);
    this._router.queryParams.subscribe(params => {
      this.stockDetails.sym = params.sym
      this.symbol = params.sym
      this.loadservice.setItem('symbol', this.stockDetails.sym);
      this.getStockquote();
      this.getStockfollow();
    });
    this.overView = true;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth <= 768) {
      this.isMobile = true
    }


    this.getusMarket();
    $(function() {
      //       $(".drop-down .selected a").click(function() {
      //         alert('hi');
      //     $(".drop-down .options ul").toggle();
      // });
      $('.dropdown .caption').on('click', function() {

        $(this).parent().toggleClass('open');
      });

      $('.dropdown > .list > .item').on('click', function() {
        $('.dropdown > .list > .item').removeClass('selected');
        $(this).addClass('selected').parent().parent().removeClass('open').children('.caption').text($(this).text());
      });

      $(document).on('keyup', function(evt) {
        if ((evt.keyCode || evt.which) === 27) {
          $('.dropdown').removeClass('open');
        }
      });

      $(document).on('click', function(evt) {
        if ($(evt.target).closest(".dropdown > .caption").length === 0) {
          $('.dropdown').removeClass('open');
        }
      });

    });
    var getusertype = localStorage.getItem('usertype')

    if (getusertype != 'undefined') {
      this.usertype = localStorage.getItem('usertype')
    }
    console.log('usertype', this.usertype)
  }

  callMethod(data) {

    this.selected = data
    this.updateStockfollow(data)
  }
  stockfollow(event) {
    console.log(event);
    $(".drop-down .options ul").toggle();

  }
  stockChange(events) {
    console.log(events);
    // var text = $(this).html();

  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', scrollable: true, size: 'lg', centered: true }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true, size: 'lg', centered: true, windowClass: 'dark-modal' });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth <= 768) {
      this.isMobile = true
    }
    else {
      this.isMobile = false
    }
  }
  hideloader(val) {
    document.getElementById(val)
      .style.display = 'none';
  }
  showloader(val) {
    document.getElementById(val).style.display = 'block';
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.isMobile = this.width < this.mobileWidth;
  }

  selectEvent(item) {
    this.location.replaceState('stockdetail', item.symbol);
    // this.router.navigate(['stockdetail', item.symbol]);
  }

  getStockquote() {
    this.stockDet = false;
    this.showloader('stockloader');
    let stock = this.stockDetails.sym;
    this.apiService.get(apiUrls.stockQuote + stock + '/quote').subscribe((response: any) => {
      this.hideloader('stockloader');
      if (response.error == false) {
        this.stockDetails = response.data;
        localStorage.setItem('volume', this.stockDetails.volume)
        console.log(this.stockDetails.symbol)
        this.stockDet = true;
        this.changePer = (this.stockDetails.changePercent) * 100;
        let a = (response.data.extendedPriceTime) / 1000;
        this.openTime = new Date(a);
      } else {
        return
      }
    })
  }

  getStockfollow() {
    this.stockDet = false;
    this.showloader('stockloader');
    let stock = this.stockDetails.sym;
    this.apiService.get(apiUrls.stockQuote + stock + '/getFollowStatus').subscribe((response: any) => {
      this.hideloader('stockloader');
      if (response.error == false) {
        var type = response.data[0].type ? response.data[0].type : "Follow";
        this.selected = type
        this.stockDet = true;
      } else {
        return
      }
    })
  }

  updateStockfollow(type) {
    var data = { symbol: this.symbol, type: type }
    console.log("data", data)
    this.apiService.post(apiUrls.follow, data).subscribe((response: any) => {
    })
  }

  searchSuggest(event) {
    const x = event.which || event.keyCode;
    if (x !== undefined) {
      const val = event.target.value.toLowerCase();
      if (val.length >= 1) {
        this.search(val);
      }
    }
  }
  search(value) {
    let par = {
      text: value
    }
    this.apiService.post(apiUrls.search, par).subscribe((response: any) => {
      this.searchResult = response.data;
    })
  }


  getusMarket() {
    this.apiService.get(apiUrls.usMarket).toPromise().then((response: any) => {
      if (response.error == false) {
        this.usmarket = response.data.usMarket;
        this.bond = response.data.bond;
      } else {
        return
      }
    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }

  stockTab(val) {
    if (val == 'overview') {
      this.overView = true;
      this.performance = false;
      this.metrics = false;
      this.financials = false;
      this.peers = false;
      this.ownership = false;
      this.reports = false;
    } else if (val == 'keymetrics') {
      this.overView = false;
      this.performance = true;
      this.metrics = false;
      this.financials = false;
      this.peers = false;
      this.ownership = false;
      this.reports = false;
    } else if (val == 'metrics') {
      this.overView = false;
      this.performance = false;
      this.metrics = true;
      this.financials = false;
      this.peers = false;
      this.ownership = false;
      this.reports = false;
    } else if (val == 'financials') {
      this.overView = false;
      this.performance = false;
      this.metrics = false;
      this.financials = true;
      this.peers = false;
      this.ownership = false;
      this.reports = false;
    } else if (val == 'peers') {
      this.overView = false;
      this.performance = false;
      this.metrics = false;
      this.financials = false;
      this.peers = true;
      this.ownership = false;
      this.reports = false;
    } else if (val == 'ownership') {
      this.overView = false;
      this.performance = false;
      this.metrics = false;
      this.financials = false;
      this.peers = false;
      this.ownership = true;
      this.reports = false;
    } else if (val == 'analysis') {
      this.overView = false;
      this.performance = false;
      this.metrics = false;
      this.financials = false;
      this.peers = false;
      this.ownership = false;
      this.reports = false;
      this.analysis = true;
    }
  }
  payment() {
    this.modalService.dismissAll()
    this.router.navigate(['/subscription'])
  }
  stockTabs(data) {
    this.loadservice.setStocktab(data)
  }
  goTotab(page) {
    let symbol = {
      sym: localStorage.getItem('symbol')
    }
    // var sus = localStorage.getItem('searchSym')
    this.router.navigate(['stockdetail/' + page], { queryParams: symbol });
    // localStorage.setItem('activeStocktab', '/'+page)
    // this.activeTab = localStorage.getItem('activeStocktab');
    // let location = this.router.url.split('?')[0];
    // this.activeTab = location;

  }

}