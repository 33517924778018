
import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { widget, IChartingLibraryWidget, ChartingLibraryWidgetOptions, LanguageCode } from './../../../assets/charting_library/charting_library.min';
import { ApiService } from '../../../services/api.services';
import { apiUrls } from '../../../environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarDateFormatter, CalendarView, } from 'angular-calendar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomDateFormatter } from '../../custom-date-formatter.provider';
import { LoadService } from 'src/services/load.service';
import { Location } from '@angular/common'
import Datafeed from './datafeed'
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let nv: any;
declare let d3: any;

declare let LightweightCharts: any
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  }
};
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],

  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  encapsulation: ViewEncapsulation.None

})
export class OverviewComponent implements OnInit, OnDestroy {

  private _symbol: ChartingLibraryWidgetOptions['symbol'] = localStorage.getItem('symbol');
  private _interval: ChartingLibraryWidgetOptions['interval'] = null;
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = 'https://demo_feed.tradingview.com';
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '/assets/charting_library/';
  // private _customurl: ChartingLibraryWidgetOptions['custom_css_url'] = '/assets/charting_library/chart_theme.css';
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
  private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
  private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
  private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;
  earningAxis: any;
  trading: boolean;
  earWidth: number;
  earHeight: number;

  // @Input()
  // set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
  //   this._symbol = symbol || this._symbol;
  // }

  // @Input()
  // set interval(interval: ChartingLibraryWidgetOptions['interval']) {
  //   this._interval = interval || this._interval;
  // }

  // @Input()
  // set datafeedUrl(datafeedUrl: string) {
  //   this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  // }

  // @Input()
  // set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
  //   this._libraryPath = libraryPath || this._libraryPath;
  // }

  // @Input()
  // // set customUrl(customUrl: ChartingLibraryWidgetOptions['custom_css_url']) {
  // //   this._customurl = customUrl || this._customurl;
  // // }


  // @Input()
  // set chartsStorageUrl(chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']) {
  //   this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
  // }

  // @Input()
  // set chartsStorageApiVersion(chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']) {
  //   this._chartsStorageApiVersion = chartsStorageApiVersion || this._chartsStorageApiVersion;
  // }

  // @Input()
  // set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
  //   this._clientId = clientId || this._clientId;
  // }

  // @Input()
  // set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
  //   this._userId = userId || this._userId;
  // }

  // @Input()
  // set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
  //   this._fullscreen = fullscreen || this._fullscreen;
  // }

  // @Input()
  // set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
  //   this._autosize = autosize || this._autosize;
  // }

  // @Input()
  // set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
  //   this._containerId = containerId || this._containerId;
  // }

  @Input() stockSymbol: any;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: any = [];

  activeDayIsOpen: boolean = false;
  overview: any;
  axisX: any = [];
  line1: any;
  activeCls: any;
  news: any;
  viewFullnews: any;
  show: boolean;
  toDay: Date;
  month: number;
  year: () => number;
  thisYear: number;
  currentEvent: any;
  dateEvents: any = [];
  newsTab: any = [];
  urlSafe: SafeResourceUrl;
  eventsList: any = [];
  stockpro: any = [];
  stockDetails: any = [];
  filterList: any = [];
  stockRating: any = [];
  stockEarning: any;
  chart: any;

  name: any = [];
  bubbleChartOptions: ChartOptions;
  public bubbleChartType: ChartType = 'bubble';
  public bubbleChartLegend = true;
  dataSet: any = [];
  bubbleChartData: ChartDataSets[];
  public bubbleChartColors: Color[] = [
    { backgroundColor: 'rgb(255, 127, 14)' },
    { backgroundColor: 'rgb(31, 119, 180)' },
  ]
  usertype: string;
  constructor(private apiService: ApiService, private router: Router,
    private load: LoadService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private location: Location,
    private route: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    route.queryParams.subscribe(p => {
      // Datafeed.getBars()

    });
  }

  ngOnInit() {
    function getLanguageFromURL(): LanguageCode | null {
      const regex = new RegExp('[\\?&]lang=([^&#]*)');
      const results = regex.exec(location.search);
      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(this._datafeedUrl),
      // Datafeed: new ReadlTimeDataFeedBase(Datafeed),
      datafeed: Datafeed,
      interval: this._interval,
      container_id: this._containerId,
      toolbar_bg: '#151620',
      library_path: this._libraryPath,
      locale: getLanguageFromURL() || 'en',
      // disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'header_saveload', 'header_campare'],
      disabled_features: ['header_symbol_search'],
      enabled_features: ['study_templates'],
      charts_storage_url: this._chartsStorageUrl,
      charts_storage_api_version: this._chartsStorageApiVersion,
      client_id: this._clientId,
      user_id: this._userId,
      fullscreen: this._fullscreen,
      autosize: this._autosize,

      // custom_css_url: this._customurl,
      theme: 'Dark',
      overrides: {
        "paneProperties.background": "#151620",
        "paneProperties.gridProperties.color": "#151620",
        "paneProperties.vertGridProperties.color": "#151620",
        "paneProperties.horzGridProperties.color": "#151620",
        "paneProperties.crossHairProperties.color": "#151620",
        "scalesProperties.lineColor": "#b1b1b5",
        "scalesProperties.textColor": "#b1b1b5",
      }
    };

    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.addEventListener('click', () => tvWidget.showNoticeDialog({
          title: 'Notification',
          body: 'TradingView Charting Library API works correctly',
          callback: () => {
          },
        }));
        button.innerHTML = 'Check API';
      });
    });

    this.getStockcmpy();
    this.getnews();
    this.getEvent();
    this.getStockquote();
    this.getStockearning();
    this.getStockrating();


    var getusertype = localStorage.getItem('usertype')

    if (getusertype != 'undefined') {
      this.usertype = localStorage.getItem('usertype')
    }
  }

  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true, size: 'lg', centered: true, windowClass: 'dark-modal' });
  }


  loadMarketoverView(range) {
    this.apiService.get(apiUrls.stockOverview + range).subscribe((response: any) => {
      if (response.error == false) {
        this.overview = response.data;
        this.overview.forEach(x => {
          this.axisX.push({ time: x.date, value: x.close });
        });
        this.chart.timeScale().fitContent();
        this.line1.setData(this.axisX);
      } else {
        return
      }
    })
  }
  forOneDay() {
    this.activeCls = '1d';
    this.loadMarketoverView(this.activeCls)
  }
  forFiveDay() {
    this.activeCls = '5d'
    this.loadMarketoverView(this.activeCls)
  }
  forOneMonth() {
    this.activeCls = '1m';
    this.loadMarketoverView(this.activeCls)
  }
  forYearToEnd() {
    this.activeCls = 'ytd';
    this.loadMarketoverView(this.activeCls)
  }
  forOneYear() {
    this.activeCls = '1y';
    this.loadMarketoverView(this.activeCls)
  }
  forTwoYear() {
    this.activeCls = '2y';
    this.loadMarketoverView(this.activeCls)
  }
  forFiveYear() {
    this.activeCls = '5y';
    this.loadMarketoverView(this.activeCls)
  }
  getnews() {
    this.showloader('newsloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.news + '/' + stock).toPromise().then((response: any) => {
      this.hideloader('newsloader');
      if (response.error == false) {
        this.news = response.data;
        this.newsTab = [];
        this.news.forEach(x => {
          let a = new Date(x.datetime)
          this.newsTab.push({ headline: x.headline, source: x.source, image: x.image, summary: x.summary, related: x.related, date: a, url: x.url });
        });
      }

    }).catch((err: any) => {
      if (err.status === 401) {
        localStorage.clear();
        this.router.navigate(['login']);
      }
    });
  }

  open(content, news) {
    this.viewFullnews = news
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.viewFullnews.url);
    // this.modalService.open(content, { size: 'lg' });
    let newsDetails = {
      url: this.urlSafe,
      title: news.title,
      image: news.image,
      headline: news.headline,
      source: news.source,
      date: news.date,
      summary: news.summary,
      related: news.related
    }
    this.router.navigate(['/dashboard/newspopup'], { queryParams: newsDetails });
  }

  dayClicked({ date }): void {
    let a = new Date(date)
    let day = a.getDate();

    const eventss = []
    this.dateEvents.filter((n) => {
      if (n.start.getDate() === day) {
        eventss.push({
          title: n.title,
          start: (new Date(n.start)),
          color: colors.red,
          edate: n.edate,
          year: n.year,
          type: n.type,
          date: n.date,
          amount: n.amount,
          frequency: n.frequency,
          symbol: n.symbol
        })
      }
    })
    this.eventsList = eventss;
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  getEvent() {
    this.showloader('eventsloader');
    this.toDay = new Date();
    this.month = this.toDay.getMonth() + 1;
    let stock = localStorage.getItem('symbol');
    this.thisYear = this.toDay.getFullYear();
    let p = {
      year: this.thisYear,
      month: this.month
    }
    this.apiService.post(apiUrls.stockEvents + stock, p).subscribe((response: any) => {
      this.hideloader('eventsloader');
      if (response.error == false) {
        this.currentEvent = response;
        const eventss = []
        if (this.currentEvent.data) {
          this.currentEvent.data.forEach(i => {
            let a = new Date(i.date);
            let eDate = a.toLocaleString('default', { month: 'long' });
            let year = a.getFullYear();
            let date = a.getDate();
            eventss.push({
              title: i.description,
              start: (new Date(i.date)),
              color: colors.red,
              edate: eDate,
              year: year,
              type: i.type,
              amount: i.amount,
              frequency: i.frequency,
              date: date,
              symbol: i.symbol
            })
          });
        }

        this.events = eventss
        this.dateEvents = eventss;
        this.eventsList = eventss;
        this.filterList = eventss;
      } else {
        return
      }
    })
  }
  nextMonth(date: any) {
    this.eventsList = [];
    this.dateEvents = [];
    let stock = localStorage.getItem('symbol');
    let a = new Date(date)
    let p = {
      year: a.getFullYear(),
      month: a.getMonth() + 1
    }
    this.apiService.post(apiUrls.stockEvents + stock, p).subscribe((response: any) => {
      this.hideloader('eventsloader');
      if (response.error == false) {
        this.currentEvent = response;
        const eventss = []
        this.currentEvent.data.forEach(i => {
          let a = new Date(i.date);
          let eDate = a.toLocaleString('default', { month: 'long' });
          let year = a.getFullYear();
          let date = a.getDate();
          eventss.push({
            title: i.description,
            start: (new Date(i.date)),
            color: colors.red,
            edate: eDate,
            year: year,
            date: date,
            type: i.type,
            amount: i.amount,
            frequency: i.frequency,
            symbol: i.symbol
          })
        });
        this.events = eventss
        this.eventsList = eventss;
        this.dateEvents = eventss;
        this.filterList = eventss;
      } else {
        return
      }
    })
  }
  preMonth(date: any) {
    this.showloader('eventsloader');
    this.eventsList = [];
    this.dateEvents = [];
    let stock = localStorage.getItem('symbol');
    let a = new Date(date)
    let p = {
      year: a.getFullYear(),
      month: a.getMonth() + 1
    }
    this.apiService.post(apiUrls.stockEvents + stock, p).subscribe((response: any) => {
      this.hideloader('eventsloader');
      if (response.error == false) {
        this.currentEvent = response;
        const eventss = []
        this.currentEvent.data.forEach(i => {
          let a = new Date(i.date);
          let eDate = a.toLocaleString('default', { month: 'long' });
          let year = a.getFullYear();
          let date = a.getDate();
          eventss.push({
            title: i.description,
            start: (new Date(i.date)),
            color: colors.red,
            edate: eDate,
            year: year,
            type: i.type,
            date: date,
            amount: i.amount,
            frequency: i.frequency,
            symbol: i.symbol
          })
        });
        this.events = eventss
        this.eventsList = eventss;
        this.filterList = eventss;
        this.dateEvents = eventss;
      } else {
        return
      }
    })
  }
  eventFilter(events) {
    if (events == 'all') {
      this.eventsList = this.filterList;
    } else {
      let a = events;
      const eventss = []
      this.filterList.filter((n) => {
        if (n.type === a) {
          eventss.push({
            title: n.title,
            start: (new Date(n.start)),
            color: colors.red,
            edate: n.edate,
            year: n.year,
            type: n.type,
            date: n.date,
            amount: n.amount,
            frequency: n.frequency,
            symbol: n.symbol
          })
        }
      })
      this.eventsList = eventss;
    }
  }
  showloader(val) {
    document.getElementById(val).style.display = 'block';
  }
  getStockcmpy() {
    this.trading = false;
    this.showloader('tradingloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.stockQuote + stock + '/company').subscribe((response: any) => {
      this.hideloader('tradingloader');
      if (response.error == false) {
        this.stockpro = response.data;
        this.trading = true;
      } else {
        return
      }
      // this.stockpro = response.data.tags;
    })
  }


  getStockquote() {
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.stockQuote + stock + '/quote').subscribe((response: any) => {
      if (response.error == false) {
        this.stockDetails = response.data;
        let openTime = response.data.openTime;
        this.stockDetails.openTime = new Date(openTime)
        this.stockDetails.dividend = (response.data.dividendYield) * 100
        this.stockDetails.lastYearEPS = (response.data.lastYearEPS) * 100

        console.log("stockdetails", this.stockDetails)
      } else {
        return
      }
    })
  }
  getStockrating() {

    this.showloader('analystloader');
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.stockQuote + stock + '/analyst-rating').subscribe((response: any) => {
      this.hideloader('analystloader');
      if (response.error == false) {
        this.stockRating = response.data;
        var width = document.getElementById('overview').clientWidth;
        var height = document.getElementById('earnings').clientHeight;
        nv.addGraph({
          generate: function() {
            var chart = nv.models.multiBarChart()
              .height(300)
              .width(width)
            // .stacked(true)
            chart.tooltip.enabled(true)
            var data = response.data;
            chart.color(["#DD2A2A", "#b1b1b5", "#0ED22F"])
            chart.xAxis.tickFormat(function(d) {
              return d3.time.format('%m/%d/%y')(new Date(d))
            });
            d3.select('#analyst svg')
              .datum(data)
              .call(chart);
            return chart;
          },

        })
      }

    })
  }


  getStockearning() {
    this.showloader('earningsloader');
    let stock = localStorage.getItem('symbol');
    this.earWidth = document.getElementById('overview').clientWidth;
    // this.earHeight = document.getElementById('earnings').clientHeight;
    this.apiService.get(apiUrls.stockQuote + stock + '/earning').subscribe((response: any) => {
      this.hideloader('earningsloader');
      if (response.error == false) {
        this.name = response.data[1]
        var chartData = response.data[0]
        this.bubbleChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                var index = tooltipItem[0].datasetIndex
                return chartData[index].label;
              },
              label: function(tooltipItem, data) {
                return tooltipItem.value;
              }
            },
          },
          scales: {
            xAxes: [{
              ticks: {
                min: 0,
                callback: value => this.name[value]
              }
            }],
            yAxes: [{
              ticks: {
                // min: 0,
              }
            }]
          }

        };
        this.bubbleChartData = response.data[0]

        this.cd.detectChanges();
      }

    })

  }
  hideloader(val) {
    document.getElementById(val).style.display = 'none';
  }
  reload() {
    window.location.reload();
  }

  payment() {
    this.modalService.dismissAll()
    this.router.navigate(['/subscription'])
  }
  ngOnDestroy() {
    if (this._tvWidget !== null) {
      this._tvWidget.remove();
      this._tvWidget = null;
    }
  }
}
