import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.services';

declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-market-overview',
  templateUrl: './market-overview.component.html',
  styleUrls: ['./market-overview.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class MarketOverviewComponent implements OnInit {

  // marketOverview: any;
  // nasdaq: any
  // dow: any
  // spx: any
  activeCls: any;
  chart: any;
  line1: any;
  line2: any;
  line3: any;
  nasdaqChecked: boolean;
  range: any;
  dowChecked: boolean;
  spChecked: boolean;
  overview: boolean;
  nasVal: any = [];
  dowVal: any = [];
  spxVal: any = [];
  axisVal: any = [];
  lineChartData: any;
  lineChart: any = [];
  datum: any = [];



  constructor(private apiService: ApiService, private _router: ActivatedRoute) {
    // this.activeCls = this._router.snapshot.data['list']
  }
  // @HostListener('window:resize', ['$event'])
  ngOnInit() {
    this.nasdaqChecked = true;
    this.dowChecked = true;
    this.spChecked = true;
    this.forOneYear()

  }

  loadMarketoverView(range) {
    this.showloader('moloader');
    d3.selectAll("#chart1 svg > *").remove();
    d3.selectAll('.nvtooltip').remove();
    this.range = range;
    // alert('marketoverview')
    this.apiService.get(apiUrls.marketOverview + range).subscribe((response: any) => {
      var width = document.getElementById('chartDetails').clientWidth;
      this.hideloader('moloader');
      if (response.error == false) {
        nv.addGraph(function () {
          this.chart = nv.models.cumulativeLineChart()
            .useInteractiveGuideline(true)
            .x(function (d) { return d[0] })
            .y(function (d) { return d[1] / 100 })
            .color(d3.scale.category10().range())
            .average(function (d) { return d.mean / 100; })
            .duration(300)
            // .showControls(false)
            .clipVoronoi(false)
            .height(500)
            .width(width)
          // this.chart.tooltip.enabled(false)

          // this.chart.interactiveLayer.tooltip.fixedBottom(100);
          this.chart.dispatch.on('renderEnd', function () {
          });

          this.chart.xAxis.tickFormat(function (d) {
            return d3.time.format('%m/%d/%y')(new Date(d))
          });

          this.chart.yAxis.tickFormat(d3.format(',.1%'));
        
          var datum = response.data
          d3.select('#chart1 svg')
            .datum(datum)
            .call(this.chart);
          $(window).on("scroll", function () {
            d3.selectAll('.nvtooltip').style('opacity', '0');
          })
          nv.utils.windowResize(this.chart.update);
          this.chart.dispatch.on('stateChange', function (e) { nv.log('New State:', JSON.stringify(e)); });
          this.chart.state.dispatch.on('change', function (state) {
            nv.log('state', JSON.stringify(state));
          });
          // if(scrWidth <= 579){
          //   this.chart.showControls(false)
          // }
          return this.chart;
        });
      } else {
        return
      }
    })
  }
  hideloader(val) {
    document.getElementById(val)
      .style.display = 'none';
  }
  showloader(val) {
    document.getElementById(val)
      .style.display = 'block';
  }


  businessDayToString(businessDay) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
  }

  forOneDay() {
    this.activeCls = '1d';
    this.loadMarketoverView(this.activeCls)
  }
  forFiveDay() {
    this.activeCls = '5d'
    this.loadMarketoverView(this.activeCls)
  }
  forOneMonth() {
    this.activeCls = '1m';
    this.loadMarketoverView(this.activeCls)
  }
  forYearToEnd() {
    this.activeCls = 'ytd';
    this.loadMarketoverView(this.activeCls)
  }
  forOneYear() {
    this.activeCls = '1y';
    this.loadMarketoverView(this.activeCls)
  }
  forTwoYear() {
    this.activeCls = '2y';
    this.loadMarketoverView(this.activeCls)
  }
  forFiveYear() {
    this.activeCls = '5y';
    this.loadMarketoverView(this.activeCls)
  }
}
