import { Component, OnInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.services';
import { NumericalStdPipe } from 'src/app/numerical-std.pipe';
import { NegativeNumberPipe } from 'src/app/negative-number.pipe';
import { LoadService } from 'src/services/load.service';
declare let nv: any;
declare let d3: any;
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-financials',
  templateUrl: './financials.component.html',
  styleUrls: ['./financials.component.css'],
  providers: [NegativeNumberPipe]
})
export class FinancialsComponent implements OnInit {
  chart: any;
  bSheet: any = [];
  icSheet: any = [];
  cFlow: any;
  symbol: any;
  stockFinancial: any = [];
  constructor(private apiService: ApiService, private _router: ActivatedRoute,
    private load: LoadService,
  ) { }

  ngOnInit() {
    this._router.queryParams.subscribe(params => {
      this.symbol = params.sym
      // this.getStockquote();
    });
    localStorage.setItem('symbol', this.symbol);
    this.loadfinancial('quarter');
    this.getbalanceSheet('quarter');
    this.getincomeSheet('quarter');
    this.getcashFlow('quarter');
    // this.load.watchStorage().subscribe(data => {
    //   this.loadfinancial('quarter');
    //   this.getbalanceSheet('quarter');
    //   this.getincomeSheet('quarter');
    //   this.getcashFlow('quarter');
    // })
  }

  loadfinancial(val) {
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.stockfinancial + stock + '/' + val).subscribe((response: any) => {
      if (response.error == false) {
        this.stockFinancial = response.data[0]
        var label = response.data[1]
        var checkdatum = response.data[0]

        nv.addGraph(function() {
          //  var width = document.getElementById('tv_chart_container').clientWidth;
          this.chart = nv.models.multiChart()
            .height(500)
          //  .width(width)
          var numericalStdPipe = new NumericalStdPipe()
          this.chart.yAxis1
            .tickFormat(function(d) {
              return '$' + numericalStdPipe.transform(d);
            });
          this.chart.yAxis2
            .tickFormat(function(d) {
              return d3.format(',.2f')(d);
            });

          this.chart.xAxis.tickFormat(function(d) {
            // return d3.time.format('%m/%d/%y')(new Date(d))
            return label[d];
          });
          d3.select('#financial svg')
            .datum(checkdatum)
            .call(this.chart);

          nv.utils.windowResize(this.chart.update);

          return this.chart;
        });
      } else {
        return
      }

    })
  }

  getbalanceSheet(scale) {
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.balaceSheet + stock + '/' + scale).subscribe((response: any) => {
      this.bSheet = response.data;
    })
  }

  getincomeSheet(scale) {
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.incomeSheet + stock + '/' + scale).subscribe((response: any) => {
      this.icSheet = response.data;
    })
  }

  getcashFlow(scale) {
    let stock = localStorage.getItem('symbol');
    this.apiService.get(apiUrls.cashflow + stock + '/' + scale).subscribe((response: any) => {
      this.cFlow = response.data;
    })
  }

  getDateformat(date) {



    var dates = formatDate(date, 'MM/dd/yyyy', 'en-US');

    return dates
  }
}
