import { Component, OnInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
import { NumericalStdPipe } from './../../../numerical-std.pipe'
declare let nv: any;
declare let d3: any;
@Component({
  selector: 'app-initial-unemployment',
  templateUrl: './initial-unemployment.component.html',
  styleUrls: ['./initial-unemployment.component.css'],
  providers: [ NumericalStdPipe ]
})
export class InitialUnemploymentComponent implements OnInit {
  intialUne: any;
  chart: any;
  activeCls: any;
  activeStock: string = 'nasdaq';
  value: any =[];
  constructor( private apiService: ApiService, private loadservice:LoadService ) { }

  ngOnInit() {
    this.loadservice.economicGraph().subscribe(data =>{
      if(data == 'unp'){
        this.forOneYear();
      }
    })
  }
  hideloader(val) { 
    document.getElementById(val) 
        .style.display = 'none'; 
  }
  showloader(val) { 
    document.getElementById(val) 
        .style.display = 'block'; 
  }
  initialUnempView(range){
    this.showloader('iuloader'); 
    this.apiService.get(apiUrls.initialUnemployment + range).subscribe((response: any) => {
      this.hideloader('iuloader'); 
    if (response.error == false) {
      var width = document.getElementById('news').clientWidth;
      var datum = response.data
      var numericalStdPipe = new NumericalStdPipe()
      nv.addGraph(function() {
        this.chart = nv.models.linePlusBarChart()
              .margin({top: 30, right: 60, bottom: 50, left: 70})
              .color(d3.scale.category10().range())
              .height(500)
              .width(width);

              // this.chart.xAxis.tickFormat(function(d) {
              //   return d3.time.format('%x')(new Date(d))
              // });

              this.chart.xAxis.tickFormat(function(d) {
                var date = new Date(d)
                var month = date.getMonth()
                var quarter
                if (month < 4) {
                  quarter = 'Q1'
                } else if (month < 7) {
                  quarter = 'Q2'
                } else if (month < 9) {
                  quarter = 'Q3'
                } else if (month < 13) {
                  quarter = 'Q4'
                }
                return quarter + ' ' + date.getFullYear()
              });
              this.chart.x2Axis.tickFormat(function (d) {
                return d3.time.format('%b %Y')(new Date(d))
              });    
        
              this.chart.y1Axis
                  .tickFormat(d3.format(',f'));
        
              this.chart.y2Axis
                  .tickFormat(function(d) { 
                    return numericalStdPipe.transform(d)
                  });
        
              this.chart.bars.forceY([0]);
              this.chart.switchYAxisOrder(true)
        
              d3.select('#initial-unemp-chart svg')
                .datum(datum)
                .transition()
                .duration(0)
                .call(this.chart);
        
              nv.utils.windowResize(this.chart.update);
              
              return this.chart;
        // this.chart = nv.models.discreteBarChart()
        //     .x(function(d) { return d.label })
        //     .y(function(d) { return d.value })
        //     .margin({right: 0, left: 50})
        //     .staggerLabels(true)
        //     .showValues(true)
        //     .valueFormat(function(d) { return d.toFixed(0)})
        //     .rotateLabels(45)
        //     .height(450)
        //     .width(width);

        // this.chart.yAxis
        // .tickFormat(function(d) { return d.toFixed(0) });

        // d3.select('#initial-unemp-chart svg')
        //     .datum(datum)
        //     .call(this.chart);
        // nv.utils.windowResize(this.chart.update);
        //       return this.chart;
      });
    } else{
      return
    }
    })
}
  forOneDay(){
    this.activeCls = '1d';
    this.initialUnempView (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.initialUnempView (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.initialUnempView (this.activeCls)
  }
  forSixMonth(){
    this.activeCls = '6m';
    this.initialUnempView (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.initialUnempView (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.initialUnempView (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.initialUnempView (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.initialUnempView (this.activeCls)
  }
  forAllYear(){
    this.activeCls = 'all';
    this.initialUnempView (this.activeCls)
  }
}
