import { Component, OnInit } from '@angular/core';
import { apiUrls } from 'src/environments/apiUrls';
import { ApiService } from 'src/services/api.services';
import { LoadService } from 'src/services/load.service';
import { NumericalStdPipe } from './../../../numerical-std.pipe'
declare let nv: any;
declare let d3: any;

@Component({
  selector: 'app-gdp',
  templateUrl: './gdp.component.html',
  styleUrls: ['./gdp.component.css'],
  providers: [ NumericalStdPipe ]
})
export class GdpComponent implements OnInit {
  historical: any;
  activeCls: any;
  activeStock: string = 'nasdaq';
  value: any =[];

  constructor( private apiService: ApiService, private loadservice:LoadService) { }

  ngOnInit() {
    this.loadservice.economicGraph().subscribe(data =>{
      if(data == 'gdp'){
        this.forTwoYear()
      }
    })
  }
  loadGDPview(range){
    this.showloader('gdploader'); 
    var numericalStdPipe = new NumericalStdPipe()
    this.apiService.get(apiUrls.gdp + range).subscribe((response: any) => {
      this.hideloader('gdploader'); 
    if (response.error == false) {
      var width = document.getElementById('news').clientWidth;
      var data = response.data;
      nv.addGraph(function() {
        this.chart = nv.models.linePlusBarChart()
              .margin({top: 30, right: 60, bottom: 50, left: 70})
              .color(d3.scale.category10().range())
              .height(500)
              .width(width);
        this.chart.xAxis.tickFormat(function(d) {
          return d3.time.format('%x')(new Date(d))
        });
        this.chart.x2Axis.tickFormat(function (d) {
          return d3.time.format('%b %Y')(new Date(d))
        });
        this.chart.y1Axis
            .tickFormat((d) => {
              return numericalStdPipe.transform(d)
            });
  
        this.chart.y2Axis
            .tickFormat(function(d) { return d });
  
        this.chart.bars.forceY([0]);
        this.chart.switchYAxisOrder(true)
  
        d3.select('#gdp-chart svg')
          .datum(data)
          .transition()
          .duration(0)
          .call(this.chart);
  
        nv.utils.windowResize(this.chart.update);
  
        return this.chart;
      });
    } else {
      return
    }
    })
    
  }
  hideloader(val) { 
    document.getElementById(val) 
        .style.display = 'none'; 
  }
  showloader(val) { 
    document.getElementById(val) 
        .style.display = 'block'; 
  }
  forOneDay(){
    this.activeCls = '1d';
    this.loadGDPview (this.activeCls)
  }
  forFiveDay(){
    this.activeCls = '5d'
    this.loadGDPview (this.activeCls)
  }
  forOneMonth(){
    this.activeCls = '1m';
    this.loadGDPview (this.activeCls)
  }
  forSixMonth(){
    this.activeCls = '6m';
    this.loadGDPview (this.activeCls)
  }
  forYearToEnd(){
    this.activeCls = 'ytd';
    this.loadGDPview (this.activeCls)
  }
  forOneYear(){
    this.activeCls = '1y';
    this.loadGDPview (this.activeCls)
  }
  forTwoYear(){
    this.activeCls = '2y';
    this.loadGDPview (this.activeCls)
  }
  forFiveYear(){
    this.activeCls = '5y';
    this.loadGDPview (this.activeCls)
  }
  forAllYear(){
    this.activeCls = 'all';
    this.loadGDPview (this.activeCls)
  }
}
