import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { map, filter, retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable()
export class ApiinterseptorService implements HttpInterceptor {
    constructor(
    public router: Router,
    ){

    }
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var accessToken = localStorage.getItem('accessToken');
    const httpHeaders ={
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + accessToken
    };

    return next.handle(httpRequest.clone({setHeaders:httpHeaders  })).pipe( retry(2), catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        if(error.status == 401){
          localStorage.clear();
          this.router.navigate(['login']);
        }
        return throwError(errorMessage);
      })
    )
  }
}




