import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { apiUrls } from '../../../environments/apiUrls';
import { environment } from '../../../environments/environment'

import { Component, OnInit, ViewChild } from '@angular/core';

import { Subject, of, merge } from 'rxjs';
import { catchError, filter, tap, switchMap, delay } from 'rxjs/operators';

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/services/api.services';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {
  public model: any;
  searchResult: any;
  baseurl = environment.baseurl
  states
  ratings = 4
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.searchSuggest(term.toLowerCase()))
      // : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
  enterResult: any;
  analysisresult: any;
  analysisreq: any
  perodiclist = [];
  perodiclisttwo = [];
  currentRate = 5;
  stockDetails: any = [];
  changePer: number;
  openTime: Date;
  flag: boolean = false;
  spinner: boolean = false;
  constructor(private http: HttpClient, private apiservice: ApiService, private toastr: ToastrService,) { }

  @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  loadingClients = false;
  searchFailed = false;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  async ngOnInit() {
    this.model = localStorage.getItem('searchSym')
    this.searchedstock()
    this.getStockquote()

  }

  searchedstock() {

    this.predictionHistory().subscribe((res: any) => {
      this.spinner = false
      if (res.error == false) {
        this.perodiclist = []
        const list1 = res.data.map(rest => {
          return rest.result.Results.output1[0];
        });
        this.perodiclisttwo = list1.map(details => {
          var obj = {
            ScoredLabels: details['Scored Labels']
          }
          return obj
        });
        const list3 = res.data.map((res, index1) => {
          this.perodiclisttwo.map((two, index2) => {
            var obj = {
              createdAt: res.updatedAt,
              symbol: res.symbol,
              ScoredLabels: two.ScoredLabels,
              mlmodel: res.mlmodel,
              actual: res.actual
            }
            if (index1 == index2) {
              this.perodiclist.push(obj)
            }
          })
        })
      }
    })

    this.getStockquote()
  }

  calculatePrediction() {
    if (this.analysisresult != null) {
      var calc = this.analysisresult - this.stockDetails.close
      if (Math.sign(calc) == 1) {
        this.flag = true
        var calcpercent = (this.analysisresult - this.stockDetails.close) / this.stockDetails.close
        console.log(calcpercent, 'calccccccccccccc')
        return '+' + calc.toFixed(2) + ' ' + calcpercent.toFixed(2) + ' ' + '%'
      } else {
        this.flag = false
        var calcpercent = (this.analysisresult - this.stockDetails.close) / this.stockDetails.close
        console.log(calcpercent, 'calccccccccccccc')
        return '-' + calc.toFixed(2) + ' ' + calcpercent.toFixed(2) + ' ' + '%'
      }

    } else {
      return '-'
    }
  }

  searchStockhistory() {
    this.spinner = true
    this.getactiveMovers().subscribe((res: any) => {
      
      var result = res.data.activer[0]
      var tendayaverage = result.day50MovingAvg / 5
      var target = {
        'symbol': result.symbol,
        'number': 1,
        'close': 0,
        '10day': result.day10MovingAvg,
        '50day': result.day50MovingAvg,
        '200day': result.day200MovingAvg,
        'volume': localStorage.getItem('volume')
      }

      this.volitality().subscribe((res: any) => {

        var voltalityindex = res[0]
        console.log("voltalityindex", voltalityindex)
        var obj = {
          'vix': voltalityindex.values.slice(-1).pop()[1]
        }
        this.analysisreq = Object.assign(obj, target);

        this.getAnalysis(this.analysisreq).subscribe((res: any) => {
          if (res.error == false) {
            this.analysisresult = res.data.Results.output1[0]['Scored Labels']
            this.searchedstock()
          } else {
            this.toastr.error(res.msg, 'Error');
          }

        })
      })
    })
  }

  volitality() {
    var accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + accessToken
    });

    return this.http.get(this.baseurl + '/users/volatility/' + '1y', {
      headers: httpHeaders,
    }).pipe(
      map((data: any) => {
        return data.data
      }),
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })
    );

  }


  searchSuggest(event) {
    const x = event.which || event.keyCode;
    if (x !== undefined) {
      const val = event.target.value.toLowerCase();

      let par = {
        text: val
      }
      // this.post(apiUrls.search, par).subscribe((response: any) => {
      //   this.enterResult = response.data;
      //   var statics = this.enterResult.map(data => {
      //    return data.symbol
      //   })
      //   return statics
      //   // const symbol = {
      //   //   sym: this.enterResult.symbol,
      //   // }
      // })
    }

  }

  searchClient = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    const hideLoading$ = new Observable(() => () => (this.loadingClients = false));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$, hideLoading$).pipe(
      tap(() => this.loadingClients = true),
      switchMap(
        term => this.getClientsAsObservable(term)
      ),
      delay(500),
      tap(() => this.loadingClients = false)
    );
  }


  formatter = (x: { symbol: string }) => x.symbol;

  getClientsAsObservable(term: any): Observable<any> {

    // return this.apiservice.query(term).pipe(
    //   tap(() => this.searchFailed = false),
    //   catchError(() => {
    //     this.searchFailed = true;
    //     return of([]);
    //   })
    // );

    var accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + accessToken
    });
    let par = {
      text: term
    }
    return this.http.post(this.baseurl + '/users/search', par, {
      headers: httpHeaders,
    }).pipe(
      map((data: any) => {
        return data.data
      }),
      tap(() => this.searchFailed = false),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })
    );

  }



  getactiveMovers(): Observable<any> {
    var accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + accessToken
    });

    return this.http.get(this.baseurl + '/users/movingaverage/' + localStorage.getItem('searchSym'), {
      headers: httpHeaders,
    })
  }


  getStockquote() {

    this.apiservice.get(apiUrls.stockQuote + this.model + '/quote').subscribe((response: any) => {
      if (response.error == false) {
        this.stockDetails = response.data;
        localStorage.setItem('volume', this.stockDetails.volume)

        // this.stockDet = true;
        this.changePer = (this.stockDetails.changePercent) * 100;
        let a = (response.data.extendedPriceTime) / 1000;
        this.openTime = new Date(a);
      } else {
        return
      }
    })
  }

  predictionHistory(): Observable<any> {
    var accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + accessToken
    });

    return this.http.get(this.baseurl + '/users/predictionHistory/' + localStorage.getItem('searchSym'), {
      headers: httpHeaders,
    })
  }


  getAnalysis(data): Observable<any> {

    var accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + accessToken
    });
    // var analysisdata = {

    //   'number': 1,
    //   'volume': 204110000,
    //   'vix': 29.6,
    //   '10day': 303.96,
    //   '50day': 317.2,
    //   '200day': 338.32,
    //   'close': 0,
    //   'symbol': 'aapl',
    // }
    return this.http.post(this.baseurl + '/users/getAIAnalytics/', data, {
      headers: httpHeaders,
    })
  }
  // post(url, data) {
  //   var accessToken = localStorage.getItem('accessToken');
  //   const httpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'accesstoken': 'Bearer' + " " + accessToken
  //   });
  //   return this.http.post(this.baseurl + url, data, {
  //     headers: httpHeaders,
  //   })
  // }

}
