import { Component, OnInit } from '@angular/core';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api.services';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InviteComponent implements OnInit {
  users = [];
  emailObj = []
  selectedUserIds =[];
  inviteForm: FormGroup;
  submitted = false;
  constructor(private apiservice: ApiService, private formBuilder: FormBuilder, private toastr: ToastrService) {

    localStorage.removeItem('searchExc')
    localStorage.removeItem('searchSym')
    localStorage.removeItem('searchname')
  }

  ngOnInit() {
    this.inviteForm = this.formBuilder.group({
      email: ['', [Validators.required]],
    })
  }
  addCustomUser = (term) => ({ id: term, name: term });


  get f() { return this.inviteForm.controls; }
  submitInvite() {
    this.submitted = true;
    if (this.inviteForm.invalid) {
      return
    }
    for (let i = 0; i < this.selectedUserIds.length; i++) {
      var valid = true;
      var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.selectedUserIds[i] == "" || !regex.test(this.selectedUserIds[i])) {
        valid = false;
        this.inviteForm.get('email').setValidators([Validators.email])
        this.inviteForm.get('email').updateValueAndValidity()
        this.emailObj = []
        return
      }
      if (valid == true) {
        let obj = {
          email: this.selectedUserIds[i]
        }
        this.emailObj.push(obj)
      }
    }

    this.apiservice.sendInvite(this.emailObj).subscribe(data => {
      if (data.error == false) {
        this.emailObj = []
        this.inviteForm.reset()
        this.toastr.success(data.msg, 'Success');
      } else {
        Swal.fire({
          text: data.msg,
          icon: 'warning',
          showCancelButton: false,
        })
      }

    })
  }
  onFocusOutEvent(event) {
    this.users.push('User')
    this.selectedUserIds.push('User')
  }

  removeEmailvalidation() {
    this.inviteForm.get('email').clearValidators()
    this.inviteForm.get('email').updateValueAndValidity()
  }
  onItemDeSelect($event) {
    this.inviteForm.get('email').clearValidators();
    this.inviteForm.get('email').updateValueAndValidity();
  }
}
