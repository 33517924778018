import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { apiUrls } from '../environments/apiUrls';
import { forkJoin, Observable } from 'rxjs';

import { retry, delay } from 'rxjs/operators';

const requestOptions: Object = {
  headers: new HttpHeaders({
    'Accept': 'text/plain',
    'Content-Type': 'application/json;charset=utf-8'
  }),
  params: new HttpParams({

  }),
  responseType: 'text',
}

@Injectable({
  providedIn: 'root'
})


export class ApiService {
  baseurl: string;
  accessToken: string;
  stockMover: any;
  constructor(
    private http: HttpClient,
    
  ) {
    setTimeout(() => {
      this.setBaseUrl();
    });
  }
  setBaseUrl() {
    this.baseurl = environment.baseurl;
  }

  post(url, data) {
    this.accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + url, data, {
      headers: httpHeaders,
    })
  }

  get(url) {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.get(this.baseurl + url, {
      headers: httpHeaders,
    })
  }

  

  getKeymetrics(): Observable<any> {
    this.baseurl = environment.baseurl;
    var symbol = localStorage.getItem('symbol')
    const response1 = this.http.get(`${this.baseurl}/users/stock/${symbol}/historical/10y`)
    // const response2 = this.http.get(`${this.baseurl}/users/stock/${symbol}/growth`);
    // const response3 = this.http.get(`${this.baseurl}/users/stock/${symbol}/total-rate`);
    const response4 = this.http.get(`${this.baseurl}/users/stock/${symbol}/revenue/quarter`);
    const response5 = this.http.get(`${this.baseurl}/users/google-trends/${symbol}`);
    const response6 = this.http.get(`${this.baseurl}/users/fundamental-metrics/${symbol}`);
    const response8 = this.http.get(`${this.baseurl}/users/stock/${symbol}/eps/quarter`);

    return forkJoin([response1, response4, response5, response6, response8]);
  }

  getGrowthandTotal(): Observable<any> {
    this.baseurl = environment.baseurl;
    var symbol = localStorage.getItem('symbol')
    const response1 = this.http.get(`${this.baseurl}/users/stock/${symbol}/growth`);
    const response2 = this.http.get(`${this.baseurl}/users/stock/${symbol}/total-rate`);

    return forkJoin([response1, response2]);
  }

  stockMovers() {
    this.accessToken = localStorage.getItem('accessToken');
    this.baseurl = environment.baseurl;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.get(this.baseurl + apiUrls.stockMover, {
      headers: httpHeaders,
    })
  }

  getWparams(url) {
    this.baseurl = environment.baseurl;
    this.accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.get<any>(this.baseurl + url + 'token=' + this.accessToken, {
      headers: httpHeaders,
    });
  }

  sendInvite(emails) {
    let obj = {
      email: JSON.stringify(emails)
    }
    this.baseurl = environment.baseurl;
    this.accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post<any>(this.baseurl + '/users/invite', obj, {
      headers: httpHeaders,
    })
  }

  sendFeedback(feedback) {
    this.baseurl = environment.baseurl;
    this.accessToken = localStorage.getItem('accessToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post<any>(this.baseurl + '/users/feedback', feedback, {
      headers: httpHeaders,
    })
  }
  checkData() {
    return this.http.get("assets/mockdata.json")
  }


  query(term: any): Observable<any> {
    return this.http.get(`https://www.json-generator.com/api/json/get/caldfjFOWa`)
      .pipe(
        delay(2000),
        retry(3));
  }

  StockNewdashboard(type): Observable<any> {

    // var stock = JSON.stringify([{ "symbol": "AAPL" }, { "symbol": "TSLA" }, { "symbol": "ACN" }, { "symbol": "MO" }, { "symbol": "BAX" }, { "symbol": "AIG" }, { "symbol": "CAT" }, { "symbol": "IBM" }, { "symbol": "HON" }, { "symbol": "MCD" }])
    // var stocklist = { symbol: stock }
       
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'accesstoken': 'Bearer' + " " + this.accessToken
    });
    return this.http.post(this.baseurl + 'users/v1/stock/dashboard-quote', type ,{
      headers: httpHeaders,
    })
  }


  
  Init() {
 
    return new Promise<void>((resolve, reject) => {
        console.log("AppInitService.init() called");
        ////do your initialisation stuff here  
        setTimeout(() => {
            console.log('AppInitService Finished');
            resolve();
        }, 6000);

    });
}


}

