import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StocklistService } from './stocklist.service';
import { jsPDF } from "jspdf";
import Swal from 'sweetalert2'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExportService } from './export.service';
import { ToastrService } from 'ngx-toastr';
import { DoghnutchartComponent } from './doghnutchart/doghnutchart.component';
import { ApiService } from 'src/services/api.services';
import { Router, ActivatedRoute } from '@angular/router';
import { apiUrls } from 'src/environments/apiUrls';
@Component({
  selector: 'app-stocklist',
  templateUrl: './stocklist.component.html',
  styleUrls: ['./stocklist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StocklistComponent implements OnInit {
  stockList: any;
  stockListDetails = [];
  selectedStock: any;
  stockListName: any;
  selectedUserIds = [];
  stockForm: FormGroup;
  isEdit: boolean = false
  submitted = false;
  users = [];
  stockName: any;
  stocksymbol = []
  selected: any;
  storedIndex: any;
  stockevents: any;
  sortDir = 1;
  names: any
  @ViewChild(DoghnutchartComponent, { static: true }) doughtchart: DoghnutchartComponent

  constructor(private apiService: ApiService,
    public router: Router, private stockservice: StocklistService,
    private modalService: NgbModal, private toastr: ToastrService,
    private formBuilder: FormBuilder, private exportservice: ExportService,
    private activtedroute: ActivatedRoute) { }

  ngOnInit() {

    this.stockForm = this.formBuilder.group({
      Id: ['', Validators.required],
      stockName: ['', Validators.required],
      symbol: ['', Validators.required],
      isPremium: ['', [Validators.required, Validators.email]],
      isShare: ['', [Validators.required, Validators.minLength(6)]],
    });


    if (this.activtedroute.snapshot.params.id) {
      this.myStockList(this.activtedroute.snapshot.params.id)
    } else {
      this.myStockList(0)
    }
    this.myStockList(0)


  }

  stockDetail(stock) {
    let symbol = {
      sym: stock
    }
    let symbol1 = {
      text: stock
    }
    console.log(localStorage.getItem('searchSym'))
    this.apiService.post(apiUrls.search, symbol1).subscribe((response: any) => {
      var searchResult = response.data[0];
      localStorage.setItem('searchSym', searchResult.symbol);
      localStorage.setItem('searchname', searchResult.name);
      localStorage.setItem('searchExc', searchResult.exchange);
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate(['stockdetail/overview'], { queryParams: symbol });
      });
    })
  }


  myStockList(index) {
    this.stockservice.myStockList().subscribe((res: any) => {
      if (res.error == false) {
        this.stockList = res.data
        if (this.stockList.length > 0) {
          this.getBulkQuote(this.stockList[index])
          this.select(this.stockList[index]._id)
          this.isActive(this.stockList[index]._id)
          this.storeindex(index)
          this.modalService.dismissAll()
          this.stockName = ''
        } else {
          this.selectedStock = {}
          this.stockListDetails = []
        }

      }
    })
  }

  addStock(content) {
    this.stocksymbol = []
    console.log("this.stocksymbol", this.stocksymbol)
    var obj = { "symbol": this.stockName }
    console.log("obj", obj)
    this.stocksymbol.push(obj)
    console.log("this.stocksymbol", this.stocksymbol)
    var req = {
      stockName: this.stockListName,
      symbol: this.stocksymbol
    }
    this.stockservice.addNewStock(req).subscribe((res: any) => {
      if (res.error == false) {
        this.myStockList(0);
        this.stockListName = ''
      }

    })

  }

  addStockModel(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  monthConerter(date) {
    const dates = new Date(date);  // 2009-11-10
    const month = dates.toLocaleString('default', { month: 'short' });
    return month
  }
  dayconterter(date) {
    const dates = new Date(date)
    const day = dates.toLocaleString('default', { day: "numeric" });
    return day
  }

  editStock(stock) {
    this.isEdit = true;
    this.selectedStock = stock
    // this.stockForm.patchValue({
    //   Id: stock.id
    // })
    // this.stockForm.patchValue(stock)

  }
  download() {
    var stocklist = this.stockListDetails.map((data) => {
      // ['Symbol','Company_Name', 'Price', 'Change_%', 'YTD_%','50_MA_Δ_%','200_MA_Δ_%','PE_Ratio','Market_Cap','Next_Earning_Date']
      var obj = {
        'Symbol': data.symbol,
        'Company_Name': data.companyName,
        'Price': data.close,
        'Change_%': data.changePercent,
        'YTD_%': data.ytdChange,
        '50_MA_Δ_%': data.day50MovingAvg,
        '200_MA_Δ_%': data.day200MovingAvg,
        'PE_Ratio': data.peRatio,
        'Market_Cap': data.marketCap,
        'Next_Earning_Date': data.nextEarningsDate,
        'priceToSales': data.priceToSales
      }
      return obj
    })
    this.exportservice.downloadFile(stocklist, 'stocklist');
  }

  bulkEvents() {

  }


  onSortClick(event, colName) {
    console.log("event", event, colName)

    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    if ((colName === 'symbol') || (colName === 'companyName')) {
      this.stockListDetails.sort((a, b) => {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
        return a.localeCompare(b) * this.sortDir;
      });
    } else if (colName === 'nextEarningsDate') {
      if (this.sortDir === -1) {
        this.stockListDetails.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate)) * (-1);
        })
      } else {
        this.stockListDetails.sort(function(a, b) {
          return (+new Date(a.nextEarningsDate) - +new Date(b.nextEarningsDate));
        })
      }
    } else {
      this.stockListDetails.sort((a, b) => (a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0) * this.sortDir)
    }

  }

  updateStock() {

    this.stockservice.updateStock(this.stockForm.value).subscribe((res: any) => {
      console.log(res);
      if (res.error == false) {

        this.isEdit = false;
        this.selectedStock = this.stockForm.value
        // this.stockForm.reset()


        this.myStockList(this.storedIndex)

      }

    })
  }

  deleteStock() {
    Swal.fire({
      title: 'Are you sure want to Delete Stocklist?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.stockservice.deleteStocks(this.selectedStock._id).subscribe((res: any) => {
          if (res.error == false) {
            this.myStockList(0);
            Swal.fire(
              'Deleted!',
              'success'
            )
          }
        })

      }
    })

  }

  storeindex(index) {
    this.storedIndex = index

  }

  getBulkQuote(stock) {
    this.stockForm.patchValue({
      Id: stock._id
    })
    this.stockForm.patchValue(stock)
    this.select(stock._id)
    this.selectedStock = stock
    var symbols = stock.symbol
    if (symbols.length > 0) {

      this.stockservice.bulkQuote(symbols).subscribe((res: any) => {
        if (res.error == false) {
          this.stockListDetails = this.stockCoverter(res.data)
          var symbols = this.stockListDetails.map((data: any) => {
            var obj = {
              "symbol": data.symbol
            }
            return obj
          })
          this.doughtchart.getchartData(symbols)
        }
      })
      // var eventsymb = [{"symbol":"WOR"},{"symbol":"EBF"}]
      this.stockservice.bulkEvents(symbols).subscribe((res: any) => {
        this.stockevents = res.data
        console.log(this.stockevents);

      })

    } else {
      this.stockListDetails = []
    }
  }

  stockCoverter(stocks) {
    var stock = stocks.map(res => {
      var day50MovingAvg = ((res.latestPrice / res.day50MovingAvg) - 1) * 100
      var day200MovingAvg = ((res.latestPrice / res.day200MovingAvg) - 1) * 100
      var extendedChange = ((res.extendedChange / (res.close ? res.close : res.previousClose)) * 100)
      var changePercent = (res.changePercent * 100)
      var ytdChange = (res.ytdChange * 100)
      let data = {
        url: res.url ? res.url : "https://storage.googleapis.com/iexcloud-hl37opg/api/logos/" + res.symbol + ".png",
        symbol: res.symbol,
        companyName: res.companyName,
        close: res.close ? res.close : res.previousClose,
        extendedChange: extendedChange,
        changePercent: changePercent,
        ytdChange: ytdChange,
        day50MovingAvg: day50MovingAvg,
        day200MovingAvg: day200MovingAvg,
        peRatio: res.peRatio,
        marketCap: res.marketCap,
        nextEarningsDate: res.nextEarningsDate,
        priceToSales: res.priceToSales
      }
      return data
    })

    return stock
  }

  addNewStockName(content) {
    // this.editStock(stock)
    this.names = 'Watchlist'
    this.modalService.open(content, { size: 'lg' });
  }
  eventStockname(event) {
    this.stockName = event.symbol
  }

  SaveNew() {
    if (this.selectedStock.symbol.length > 0) {
      var findstock = this.stockListDetails.find(obj => obj.symbol == this.stockName)
      if (findstock) {
        //  this.toastr.error('Stock already added in your list', 'Error');
        this.modalService.dismissAll()
        return
      }
      this.stocksymbol = this.selectedStock.symbol
      var obj = { "symbol": this.stockName }
      this.stocksymbol.push(obj)
      this.stockForm.patchValue({
        symbol: this.stocksymbol
      })
      this.updateStock()

    } else {
      this.stocksymbol = []
      console.log("this.stocksymbol", this.stocksymbol)
      var obj = { "symbol": this.stockName }
      this.stocksymbol.push(obj)
      this.stockForm.patchValue({
        symbol: this.stocksymbol
      })
      this.updateStock()

    }

  }

  deleteAstock(symbol) {
    Swal.fire({
      title: `Are you sure want to Delete  ${symbol} Stock?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.stocksymbol = this.selectedStock.symbol
        this.stocksymbol = this.stocksymbol.filter(obj => obj.symbol !== symbol)

        this.stockForm.patchValue({
          symbol: this.stocksymbol
        })
        this.updateStock()


      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
        )
      }
    })

  }

  get f() { return this.stockForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.stockForm.invalid) {
      return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.stockForm.value, null, 4));
  }

  select(item) {
    this.selected = item;
  };
  isActive(item) {
    return this.selected === item;
  };
  onReset() {
    this.submitted = false;
    this.stockForm.reset();
  }

  onFocusOutEvent(event) {
    this.users.push('User')
    this.selectedUserIds.push('User')
  }

  removeEmailvalidation() {
    this.stockForm.get('email').clearValidators()
    this.stockForm.get('email').updateValueAndValidity()
  }
  onItemDeSelect($event) {
    this.stockForm.get('email').clearValidators();
    this.stockForm.get('email').updateValueAndValidity();
  }

}
